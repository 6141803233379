class MenuModel {
  constructor(menuItems = []) {
    this.menuItems = menuItems;
  }

  getItems() {
    return this.menuItems;
  }

  setItems(menuItems) {
    this.menuItems = menuItems;
  }
}

export { MenuModel };
