import { DropzoneManager } from '../ui/dropzone.js';
import stateManager from '../data/state_manager.js';
import { LOG_LEVELS, logMessage } from '../logging.js';

function setupDropzone(headers, dropzone_id, url, message) {
  const dz_manager = new DropzoneManager(dropzone_id, url, message, headers);

  logMessage(LOG_LEVELS.INFO, 'Dropzone created: ', url);

  stateManager.subscribe((newState) => {
    if (newState.reinitialize) {
      logMessage(
        LOG_LEVELS.INFO,
        'Reinitializing dropzone due to state change'
      );
      dz_manager.removeAllFiles();
    }
  });

  return dz_manager;
}

function hideSuccessMark(file) {
  logMessage(LOG_LEVELS.DEBUG, `Hiding success mark for file`, {
    fileName: file.name,
  });
  const successMark = file.previewElement.querySelector('.dz-success-mark');
  if (successMark) {
    successMark.style.display = 'none';
    logMessage(LOG_LEVELS.INFO, 'Success mark hidden', { fileName: file.name });
  } else {
    logMessage(LOG_LEVELS.DEBUG, 'No success mark found to hide', {
      fileName: file.name,
    });
  }
}

function attachDropzoneSuccessListener(dropzone, onSuccess, headers) {
  dropzone.on('success', (file, response) => {
    logMessage(LOG_LEVELS.INFO, `File upload success`, {
      fileName: file.name,
      response,
    });
    hideSuccessMark(file);
    onSuccess(response, file, headers);
  });
}

export { setupDropzone, attachDropzoneSuccessListener };
