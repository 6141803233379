import { TwoImagePanelWithForm } from '../../ui_components/popups/two_image_panels.js';
import { AcceptButton, RejectButton } from '../../ui_components/buttons.js';
import stateManager from '../../data/state_manager.js';
import { LOG_LEVELS, logMessage } from '../../logging.js';
import { ImageModel } from '../../ui_models/two_image_container_model.js';
import { checkChecklist } from '../../data/update_operations.js';
import { RescanButton } from '../../ui_components/buttons.js';
import { PopupLabel } from '../../ui_components/labels.js';
import config from '../../../config/index.js';
import { fetchCardData } from '../../data/update_operations.js';

import {
  selectValueTransformer,
  center_fields,
  left_listing_fields,
  right_listing_fields,
  responseToFormFieldMapping,
  getListingFields,
} from './form_constants.js';

class ListingFormPopupController {
  constructor(card_info, headers) {
    this.leftImageModel = new ImageModel();
    this.rightImageModel = new ImageModel();
    this.closeButton = new RejectButton('Cancel');
    this.acceptButton = new AcceptButton('Next');
    this.card_info = card_info;
    this.headers = headers;
  }

  async showListingPopup(main_popup) {
    const popup = new TwoImagePanelWithForm(
      'listing',
      center_fields,
      left_listing_fields,
      right_listing_fields
    );
    this.leftImageModel.setHeaders(this.headers);
    this.rightImageModel.setHeaders(this.headers);
    const local_url1 = await this.leftImageModel.fetchImage(
      this.card_info.user_card.front_image_url_large
    );
    const local_url2 = await this.rightImageModel.fetchImage(
      this.card_info.user_card.back_image_url_large
    );

    popup.setLeftImage(local_url1);
    popup.setRightImage(local_url2);

    logMessage(LOG_LEVELS.DEBUG, 'Showing listing card popup');

    if (
      this.card_info.reference_card['numbered_out_of'] > 0 &&
      this.card_info.user_card.number != null
    ) {
      this.card_info.reference_card['number'] =
        this.card_info.user_card.number +
        '/' +
        this.card_info.reference_card['numbered_out_of'];
    }

    let form_card = this.card_info.reference_card;
    form_card.condition = this.card_info.user_card.condition;
    popup.updateFormFields(
      form_card,
      responseToFormFieldMapping,
      selectValueTransformer
    );

    popup.addButtonToLeft(this.closeButton, () => this.handleClose(popup));
    popup.addButtonToRight(this.acceptButton, () =>
      this.handleSave(popup, this.headers)
    );

    popup.bindCloseButton(() => this.handleClose(popup));
    popup.bindFormInput(() => this.enableSaveButton());

    this.setupMatchContainer(popup);

    this.popup = popup;
    this.main_popup = main_popup;

    this.disableSaveButton();
    await this.initChecklistLabel(this.headers, this.card_info.reference_card);

    logMessage(LOG_LEVELS.INFO, 'Listing card popup shown');
  }

  handleClose(popup) {
    stateManager.updateState({
      inPhase2: false,
      checklistMatch: false,
      phaseOneData: null,
    });
    popup.remove();
  }

  async handleSave(popup, headers) {
    popup.resetErrors();
    if (popup.isValidInput()) {
      logMessage(LOG_LEVELS.INFO, 'Listing Card popup accept button clicked.');
      const { inPhase2, phaseOneData } = stateManager.getState();

      if (inPhase2) {
        this.handlePhaseTwo(phaseOneData, headers);
      } else {
        this.handlePhaseOne();
      }
    }
  }

  handlePhaseOne() {
    this.popup.resetErrors();

    const { checklistMatch } = stateManager.getState();

    if (!checklistMatch && !this.confirmWithoutChecklist()) {
      logMessage(LOG_LEVELS.INFO, 'User cancelled the continue operation.');
      return false;
    }

    if (this.popup.isValidInput()) {
      const phaseOneData = this.popup.getFormData();

      if (
        phaseOneData.photos.toLowerCase().includes('corners') &&
        !this.card_info.user_card.front_image_url_parent
      ) {
        alert(
          'CardSense does not have corner photos available for you. Please rescan your card.'
        );
        return false;
      }

      this.prepareForPhaseTwo(phaseOneData);
    }
  }

  displayError(message, details = []) {
    let errorText = message;

    if (details.length > 0) {
      errorText += '\n\nDetails:\n';
      details.forEach((detail) => {
        errorText += `- ${detail.ShortMessage}: ${detail.LongMessage}\n`;
      });
    }

    // Display the error message to the user (e.g., using an alert or modal)
    alert(errorText);
  }

  async displayFeesAndConfirm(fees, listing_details, warnings) {
    // Reference to modal elements
    const modal = document.getElementById('confirmationModal');
    const cancelButton = document.getElementById('cancelButton');
    const confirmButton = document.getElementById('confirmButton');
    const listingDetailsDiv = document.getElementById('listingDetails');
    const estimatedFeesDiv = document.getElementById('estimatedFees');
    const warningsSection = document.getElementById('warningsSection');

    // Populate listing details
    listingDetailsDiv.innerHTML = ''; // Clear previous content

    const title = listing_details.title || 'No Title Provided';
    const description =
      listing_details.description || 'No Description Provided';
    const listing_type =
      listing_details.listing_type || 'No Listing Type Provided';
    const price = listing_details.price
      ? parseFloat(listing_details.price).toFixed(2)
      : 'No Price Provided';
    const photos = listing_details.photos || [];

    let listingHtml = `<h3>Listing Details</h3>`;
    listingHtml += `<p style="margin:0px"><strong>Title:</strong> ${title}</p>`;
    listingHtml += `<p style="margin:0px"><strong>Description:</strong> ${description}</p>`;
    listingHtml += `<p style="margin:0px"><strong>Listing Type:</strong> ${listing_type}</p>`;
    listingHtml += `<p style="margin:0px"><strong>Price:</strong> $${price}</p>`;

    // Best Offer Details
    if (listing_details.best_offer_enabled) {
      listingHtml += `<h3>Best Offer Details</h3>`;
      listingHtml += `<p>Best Offer is enabled.</p>`;

      if (listing_details.minimum_best_offer_price) {
        const minOfferPrice = parseFloat(
          listing_details.minimum_best_offer_price
        ).toFixed(2);
        listingHtml += `<p style="margin:0px"><strong>Minimum Offer Price:</strong> ${minOfferPrice}</p>`;
      }

      if (listing_details.best_offer_auto_accept_price) {
        const autoAcceptPrice = parseFloat(
          listing_details.best_offer_auto_accept_price
        ).toFixed(2);
        listingHtml += `<p style="margin:0px"><strong>Auto Accept Price:</strong> ${autoAcceptPrice}</p>`;
      }
    }

    if (photos.length > 0) {
      listingHtml += `<div><strong>Photos:</strong><br>`;
      photos.forEach((photoUrl) => {
        listingHtml += `<img src="${photoUrl}" alt="Listing Photo" style="max-width: 100px; max-height: 100px; margin: 5px;">`;
      });
      listingHtml += `</div>`;
    } else {
      listingHtml += `<p><strong>Photos:</strong> No photos attached</p>`;
    }

    listingDetailsDiv.innerHTML = listingHtml;

    // Populate estimated fees
    estimatedFeesDiv.innerHTML = ''; // Clear previous content
    const nonZeroFees = fees.filter((fee) => parseFloat(fee.Fee) > 0);
    const totalFeeAmount = nonZeroFees.reduce(
      (total, fee) => total + parseFloat(fee.Fee),
      0
    );
    const currencyID = nonZeroFees[0]?.CurrencyID || 'USD';
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyID,
    });

    let feesHtml = `<h3>Estimated Fees</h3>`;
    feesHtml += `<ul>`;
    nonZeroFees.forEach((fee) => {
      const formattedFee = formatter.format(parseFloat(fee.Fee));
      feesHtml += `<li>${fee.Name}: ${formattedFee}</li>`;
    });
    feesHtml += `</ul>`;

    const formattedTotalFee = formatter.format(totalFeeAmount);
    feesHtml += `<p><strong>Total Estimated Fees:</strong> ${formattedTotalFee}</p>`;
    // Add disclaimer about eBay's monthly free listings
    feesHtml += `<p style="font-style: italic; color: gray;">Note: These estimated fees do not account for eBay's monthly free listings. The displayed fees may be higher than the actual fees incurred.</p>`;

    estimatedFeesDiv.innerHTML = feesHtml;

    // Populate Warnings (if any)
    warningsSection.innerHTML = ''; // Clear previous content
    if (warnings && warnings.length > 0) {
      console.log('Warnings from eBay');
      let warningsHtml = '';
      warningsHtml += `<div class="warnings-section" style="display: block;">`;
      warnings.forEach((warning) => {
        console.log('Adding warning');

        const linkedLongMessage = warning.LongMessage;
        warningsHtml += `
                    <div class="warning-item">
                        ${linkedLongMessage}
                    </div>
                `;
      });
      warningsHtml += `</div>`;
      warningsSection.innerHTML = warningsHtml;
      warningsSection.style.display = 'flex';
    } else {
      warningsSection.style.display = 'none'; // Hide the section if no warnings
    }

    // Show the modal
    modal.style.display = 'flex';
    modal.style.zIndex = 999999999;

    // Create a Promise that resolves when the user makes a choice
    return new Promise((resolve) => {
      // Cancel button handler
      cancelButton.onclick = () => {
        modal.style.display = 'none';
        resolve(false);
      };

      // Confirm button handler
      confirmButton.onclick = () => {
        modal.style.display = 'none';
        resolve(true);
      };

      // Click outside the modal to close
      window.onclick = (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
          resolve(false);
        }
      };
    });
  }

  showModal(message, url) {
    const modal = document.createElement('div');
    modal.style.position = 'fixed';
    modal.style.top = '0';
    modal.style.left = '0';
    modal.style.width = '100%';
    modal.style.height = '100%';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    modal.style.display = 'flex';
    modal.style.alignItems = 'center';
    modal.style.justifyContent = 'center';
    modal.style.zIndex = '9999999999';

    const modalContent = document.createElement('div');
    modalContent.style.backgroundColor = '#fff';
    modalContent.style.padding = '20px';
    modalContent.style.borderRadius = '8px';
    modalContent.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    modalContent.style.textAlign = 'center';

    const messageText = document.createElement('p');
    messageText.innerText = message;

    modalContent.appendChild(messageText);

    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.innerText = 'View your item on eBay';
      link.style.display = 'block';
      link.style.marginTop = '10px';
      link.style.color = '#0073e6';
      modalContent.appendChild(link);
    }

    const closeButton = document.createElement('button');
    closeButton.innerText = 'Close';
    closeButton.style.marginTop = '20px';
    closeButton.classList.add('subtle-button');
    closeButton.onclick = () => document.body.removeChild(modal);

    modalContent.appendChild(closeButton);
    modal.appendChild(modalContent);
    document.body.appendChild(modal);
  }

  handleNoSubscription() {
    document.getElementById('upgradeModal').style.display = 'block';
    // alert('You have exceeded the allowed number of downloads.')
  }

  async confirmListingOnBackend(requestData, headers) {
    try {
      const response = await fetchCardData(
        `${config.detectionUrl}/list-card`,
        'POST',
        headers,
        requestData
      );

      const responseData = response.body;

      if (!response.ok || responseData.error) {
        if (response.status == 402) {
          this.handleNoSubscription();
        } else {
          const errorMessage = responseData.error || 'Failed to list the item.';
          const errorDetails = responseData.details || [];

          // Log the error
          logMessage(
            LOG_LEVELS.ERROR,
            `Error during item listing: ${errorMessage}`
          );

          // Display the error to the user
          this.displayError(errorMessage, errorDetails);
          return;
        }
      }

      // Handle success response
      else if (responseData.success) {
        logMessage(LOG_LEVELS.INFO, 'Item successfully listed.');

        const message = 'Your item has been successfully listed on eBay!';
        const url = responseData.listing_url;

        // Show modal with the message and clickable link if URL is available
        this.showModal(message, url);

        // Update state and close the popup
        stateManager.updateState({
          inPhase2: false,
          checklistMatch: false,
          phaseOneData: null,
        });
        this.popup.remove();
      } else {
        // Unexpected response
        throw new Error('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error during item listing:', error);
      logMessage(
        LOG_LEVELS.ERROR,
        'Error during item listing: ' + error.message
      );

      // Display a generic error message to the user
      this.displayError('An unexpected error occurred during item listing.');
    }
  }

  async handlePhaseTwo(phaseOneData, headers) {
    const newHeaders = { ...headers, 'Content-Type': 'application/json' };

    if (!this.confirmListing()) {
      logMessage(LOG_LEVELS.INFO, 'User cancelled the list operation.');
      return false;
    }

    if (phaseOneData) {
      const { currentTeam } = stateManager.getState();

      const phaseTwoData = this.popup.getFormData();
      const requestData = {
        user_card_id: this.card_info.user_card.user_card_id,
        ...phaseOneData,
        ...phaseTwoData,
      };

      if (currentTeam) {
        requestData['team'] = currentTeam;
      }
      try {
        const response = await fetchCardData(
          `${config.detectionUrl}/verify-list-card`,
          'POST',
          newHeaders,
          requestData
        );

        const responseData = response.body;

        if (!response.ok || responseData.error) {
          if (response.status == 402) {
            this.handleNoSubscription();
          } else {
            const errorMessage =
              responseData.error || 'Failed to list the item.';
            const errorDetails = responseData.details || [];

            // Log the error
            logMessage(
              LOG_LEVELS.ERROR,
              `Error during item listing: ${errorMessage}`
            );

            // Display the error to the user
            this.displayError(errorMessage, errorDetails);
            return;
          }
        }

        // Handle success response
        else if (responseData.success) {
          const fees = responseData.fees || [];
          const listingDetails = responseData.listing_details || {};
          const warnings = responseData.warnings || [];

          // Display the fees to the user and ask for confirmation
          const userConfirmed = await this.displayFeesAndConfirm(
            fees,
            listingDetails,
            warnings
          );

          if (userConfirmed) {
            // User confirmed, proceed to call AddItem
            await this.confirmListingOnBackend(requestData, newHeaders);
          } else {
            // User canceled the listing
            logMessage(
              LOG_LEVELS.INFO,
              'User canceled the listing after fee confirmation.'
            );
            return;
          }
        } else {
          // Unexpected response
          throw new Error('Unexpected response from the server.');
        }
      } catch (error) {
        console.error('Error during item listing:', error);
        logMessage(
          LOG_LEVELS.ERROR,
          'Error during item listing: ' + error.message
        );

        // Display a generic error message to the user
        this.displayError('An unexpected error occurred during item listing.');
      }
    }
  }

  prepareForPhaseTwo(phaseOneData) {
    stateManager.updateState({ phaseOneData: phaseOneData, inPhase2: true });

    if (this.matchContainer) {
      this.matchContainer.remove();
    }

    this.popup.clearForm();
    this.acceptButton.updateText('List');

    const listingFields = getListingFields({
      onListingTypeChange: this.handleListingTypeChange.bind(this),
    });

    this.popup.addSingleColumnFormToPopup(listingFields);

    this.hideRowsByGroup('Auction');
    this.hideRowsByGroup('BuyItNow');
    this.hideRowsByGroup('Offer');

    const defaultTitle = this.formatTitle(phaseOneData);
    this.updateTitleField(defaultTitle);
    this.updateDescriptionField(defaultTitle);
  }

  handleAccept(headers) {
    const { checklistMatch, sets_and_parallels, phaseOneData, inPhase2 } =
      stateManager.getState();

    if (inPhase2) {
      this.handlePhaseTwo(phaseOneData, headers);
    } else {
      this.handlePhaseOne(headers, checklistMatch, sets_and_parallels);
    }
  }

  hideRowsByGroup(groupName) {
    // Select all elements with data-group="Auction"
    const rows = document.querySelectorAll(`[data-group="${groupName}"]`);

    // Iterate through each element and hide it by setting display to 'none'
    rows.forEach((row) => {
      row.style.display = 'none';

      // Disable all form inputs inside the hidden row
      const inputs = row.querySelectorAll('input, select, textarea, text');
      inputs.forEach((input) => {
        input.disabled = true;
      });
    });
  }

  enableRowsByGroup(groupName) {
    // Select all elements with data-group="Auction"
    const rows = document.querySelectorAll(`[data-group="${groupName}"]`);

    // Iterate through each element and hide it by setting display to 'none'
    rows.forEach((row) => {
      row.style.display = 'flex';

      // Disable all form inputs inside the hidden row
      const inputs = row.querySelectorAll('input, select, textarea, text');
      inputs.forEach((input) => {
        input.disabled = false;
      });
    });
  }

  handleListingTypeChange() {
    const selectedOptions = Array.from(
      document.querySelectorAll('input[name="listing_options"]:checked')
    ).map((input) => input.value);

    if (selectedOptions.includes('auction')) {
      this.enableRowsByGroup('Auction');
    } else {
      this.hideRowsByGroup('Auction');
    }

    if (selectedOptions.includes('buy it now')) {
      this.enableRowsByGroup('BuyItNow');
    } else {
      this.hideRowsByGroup('BuyItNow');
    }

    if (selectedOptions.includes('allow offers')) {
      this.enableRowsByGroup('Offer');
    } else {
      this.hideRowsByGroup('Offer');
    }
  }

  formatPlayers(players) {
    // Split players by common delimiters
    const playerList = players
      .split(/[,\/&\|;\n]/)
      .map((player) => player.trim());

    let playerNames;
    if (playerList.length >= 3) {
      playerNames = playerList
        .map((player) => this.extractLastNameWithSuffix(player))
        .join('/');
    } else {
      // Include full names with '/' as a delimiter if there are fewer than 3 players
      playerNames = playerList.join('/');
    }

    // Combine the formatted player names with the original title
    return `${playerNames}`.trim();
  }

  extractLastNameWithSuffix(player) {
    const nameParts = player.split(' ');
    const lastName = nameParts[nameParts.length - 1];
    const suffixes = ['Jr.', 'Sr.', 'II', 'III', 'IV'];
    // Check if the last part is a suffix
    if (suffixes.includes(lastName)) {
      return `${nameParts[nameParts.length - 2]} ${lastName}`;
    } else {
      return lastName;
    }
  }

  formatTitle(card_info) {
    let title_text =
      card_info.year +
      ' ' +
      card_info.manufacturer +
      ' ' +
      card_info.program +
      ' ' +
      '#' +
      card_info.card_identifier +
      ' ' +
      this.formatPlayers(card_info['player_name(s)']);
    if (
      card_info.release_set &&
      !card_info.release_set.toLowerCase().startsWith('base')
    ) {
      title_text += ' ' + card_info.release_set;
    }

    if (card_info.parallel) {
      title_text += ' ' + card_info.parallel;
    }

    if (card_info.auto && card_info.patch) {
      title_text += ' RPA';
    } else if (card_info.auto) {
      title_text += ' Autograph';
    }

    if (card_info.number) {
      const cardSequence =
        card_info.number === ''
          ? 0
          : parseInt(card_info.number.split('/').pop().trim(), 10);

      title_text += ' /' + cardSequence;
    }

    if (card_info.grading_company && card_info.grade) {
      title_text += ' ' + card_info.grading_company + ' ' + card_info.grade;
    }

    return title_text;
  }

  updateTitleField(text) {
    const title = document.querySelector('input[name="title"]');
    title.value = text;
  }

  updateDescriptionField(text) {
    const description = document.querySelector('textarea[name="description"]');
    description.value = text;
  }

  updateItemLocation(text) {
    const description = document.querySelector('input[name="item_location"]');
    description.value = text;
  }

  addMatchToUI(reference_card) {
    const refresh = new RescanButton('');
    const matchLabel = new PopupLabel(
      'Checklist Match',
      'match-label',
      'fas fa-check'
    );
    this.matchContainer.append(matchLabel.instance(), refresh.instance());
    refresh
      .instance()
      .addEventListener('click', () =>
        this.handleRefresh(this.headers, reference_card)
      );
  }

  addNoMatchToUI(reference_card, message) {
    const refresh = new RescanButton('');
    const matchLabel = new PopupLabel(
      message,
      'no-match-label',
      'fas fa-times'
    );
    this.matchContainer.append(matchLabel.instance(), refresh.instance());
    refresh
      .instance()
      .addEventListener('click', () =>
        this.handleRefresh(this.headers, reference_card)
      );
  }

  async handleRefresh(headers, reference_card) {
    this.popup.resetErrors();
    if (!this.popup.isValidInput()) {
      return;
    }

    try {
      const data = this.popup.getFormData();
      const payload = {
        card_data: data,
      };

      // Await the response from checkChecklist
      const resp = await checkChecklist(headers, payload);

      if (resp.checklist_match) {
        // Remove the old label and refresh button
        const player = reference_card.players[0];

        const currentTeam = resp.team_infos[player].team;

        // Remove the old label and refresh button
        stateManager.updateState({
          checklistMatch: true,
          sets_and_parallels: resp.sets_and_parallels,
          currentTeam: currentTeam,
        });

        this.setupMatchContainer(this.popup);
        this.addMatchToUI(reference_card);
      }
    } catch (error) {
      console.error('Error during checklist check:', error);
      // Handle the error, maybe display an error message to the user
    }
  }

  setupMatchContainer(popup) {
    if (this.matchContainer) {
      this.matchContainer.remove();
    }

    this.matchContainer = popup.createElementWithAttributes('div', {
      className: 'match-container',
      id: 'listing_match_container',
    });
    popup.insertElement(this.matchContainer, 'beforeButtonContainer');
  }

  async initChecklistLabel(headers, reference_card) {
    try {
      const data = this.popup.getFormData();
      const payload = {
        card_data: data,
      };

      // Await the response from checkChecklist
      const resp = await checkChecklist(headers, payload);

      this.enableSaveButton();

      if (resp.checklist_match) {
        // Remove the old label and refresh button
        const player = reference_card.players[0];

        const currentTeam = resp.team_infos[player].team;
        stateManager.updateState({
          checklistMatch: true,
          sets_and_parallels: resp.sets_and_parallels,
          currentTeam: currentTeam,
        });

        this.setupMatchContainer(this.popup);
        this.addMatchToUI(reference_card);
      } else {
        // Remove the old label and refresh button
        stateManager.updateState({
          checklistMatch: false,
          sets_and_parallels: null,
          currentTeam: null,
        });

        this.setupMatchContainer(this.popup);

        if (resp.not_enough_info) {
          this.addNoMatchToUI(
            reference_card,
            'Not enough info to determine checklist match'
          );
        }
        if (resp.checklist_supported) {
          this.addNoMatchToUI(reference_card, 'No checklist match');
        } else {
          this.addNoMatchToUI(
            reference_card,
            `No Checklist available for ${reference_card['year']} ${reference_card['manufacturer']} ${reference_card['program']}`
          );
        }
      }
    } catch (error) {
      this.enableSaveButton();
      console.error('Error during checklist check:', error);
      // Handle the error, maybe display an error message to the user
    }
  }

  confirmWithoutChecklist() {
    return window.confirm(
      'Are you sure you want to proceed without a checklist match?'
    );
  }

  confirmListing() {
    return window.confirm('Proceed to review your listing?');
  }

  enableSaveButton() {
    this.acceptButton.enable();
  }

  disableSaveButton() {
    this.acceptButton.disable();
  }
}

export { ListingFormPopupController };
