import { LOG_LEVELS, logMessage } from './logging.js';
import { CardDisplayPopupController } from './controllers/popups/card_display_popup_controller.js';
import stateManager from './data/state_manager.js';
import config from '../config/index.js';
import { getUserToken } from './auth_utils.js';
import { getUser } from './data/update_operations.js';
import { setupAuthentication } from './auth.js'; // Adjust the import path as needed
import { fetchCardData } from './data/update_operations.js';
import './navbar.js';

document.addEventListener('DOMContentLoaded', () => {
  // Initialize authentication
  setupAuthentication(handleAuthenticatedUser, handleUnauthenticatedUser);

  // Listen for changes in the URL hash.
  // Every time the hash changes, get the current token (if any) and reload the view.
  window.addEventListener('hashchange', () => {
    const token = getUserToken();
    const is_logged_in = !!token;
    doLoad(token, is_logged_in);
  });
});

async function handleAuthenticatedUser(user, token) {
  doLoad(token, true);
}

async function handleUnauthenticatedUser() {
  doLoad(null, false);
}

function showProfileSetupWizardAndWait(token) {
  return new Promise((resolve) => {
    const modal = document.getElementById('profile-setup-modal');

    // STEP 1 elements
    const step1 = document.getElementById('profile-setup-step1');
    const step1NextBtn = document.getElementById('step1-next-btn');

    const placeholderIcon = document.getElementById(
      'profile-setup-placeholder-icon'
    );
    const previewImg = document.getElementById('profile-setup-preview');
    const fileInput = document.getElementById('profile-setup-file-input');
    const picError = document.getElementById('profile-setup-pic-error');

    const cropperWrapper = document.getElementById(
      'profile-setup-cropper-wrapper'
    );
    const cropperImage = document.getElementById('profile-setup-cropper-image');
    const cropBtn = document.getElementById('profile-setup-crop-btn');

    // STEP 2 elements
    const step2 = document.getElementById('profile-setup-step2');
    const step2BackBtn = document.getElementById('step2-back-btn');
    const step2SaveBtn = document.getElementById('step2-save-btn');

    const usernameInput = document.getElementById('profile-setup-username');
    const usernameError = document.getElementById(
      'profile-setup-username-error'
    );

    let cropper = null;
    let croppedBlob = null;

    // Show the modal at Step 1
    modal.style.display = 'flex';
    step1.style.display = 'block';
    step2.style.display = 'none';

    /********************************************************
     * STEP 1: Upload + Crop
     ********************************************************/
    // Click placeholder or preview to open file input
    placeholderIcon.onclick = () => fileInput.click();
    previewImg.onclick = () => fileInput.click();

    // When user picks a file
    fileInput.addEventListener('change', () => {
      const file = fileInput.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        // Show the cropper
        cropperWrapper.style.display = 'block';
        cropBtn.style.display = 'inline-block'; // or 'block'
        cropperImage.src = e.target.result;

        // Destroy old cropper if any
        if (cropper) cropper.destroy();

        // Initialize cropper
        cropperImage.onload = () => {
          cropper = new Cropper(cropperImage, {
            aspectRatio: 1,
            viewMode: 1,
            background: false,
            minContainerHeight: 300,
            minContainerWidth: 300,
            guides: false,
            center: false,
          });
        };
      };
      reader.readAsDataURL(file);
    });

    // "Crop" button
    cropBtn.addEventListener('click', () => {
      if (!cropper) return;
      const canvas = cropper.getCroppedCanvas({ width: 300, height: 300 });
      canvas.toBlob((blob) => {
        croppedBlob = blob;
        // Show a circle preview
        const croppedUrl = URL.createObjectURL(blob);
        previewImg.src = croppedUrl;
        previewImg.style.display = 'inline-block';
        placeholderIcon.style.display = 'none';

        // Hide the cropper area
        cropperWrapper.style.display = 'none';
        cropBtn.style.display = 'none'; // or 'block'
      }, 'image/png');
    });

    // Step 1 "Next"
    step1NextBtn.addEventListener('click', () => {
      // If you want to require an image, ensure croppedBlob is not null
      // if (!croppedBlob) {
      //   picError.innerText = 'Please select and crop a picture before proceeding.';
      //   picError.style.display = 'block';
      //   return;
      // }

      picError.style.display = 'none';
      step1.style.display = 'none';
      step2.style.display = 'block';
    });

    /********************************************************
     * STEP 2: Username & Save
     ********************************************************/
    step2BackBtn.addEventListener('click', () => {
      // Go back to Step 1 if user wants to recrop
      step2.style.display = 'none';
      step1.style.display = 'block';
    });

    step2SaveBtn.addEventListener('click', async () => {
      const desiredUsername = usernameInput.value.trim();

      // Simple front-end validation
      const usernameRegex = /^[a-zA-Z0-9_]{3,15}$/;
      if (!usernameRegex.test(desiredUsername)) {
        usernameError.innerText =
          'Username must be 3-15 characters and contain only letters, numbers, and underscores.';
        usernameError.style.display = 'block';
        return;
      } else {
        usernameError.style.display = 'none';
      }

      // 1) Send username to server
      try {
        const res = await fetch(`${config.detectionUrl}/set-username`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username: desiredUsername }),
        });
        const data = await res.json();
        if (!res.ok || !data.username) {
          usernameError.innerText = data.error || 'Failed to set username.';
          usernameError.style.display = 'block';
          return;
        }
      } catch (error) {
        console.error('Error setting username:', error);
        usernameError.innerText = 'An error occurred. Please try again.';
        usernameError.style.display = 'block';
        return;
      }

      // 2) Upload the profile picture (if any cropped blob)
      if (croppedBlob) {
        try {
          const formData = new FormData();
          formData.append('profile-picture', croppedBlob, 'cropped.png');

          const picRes = await fetch(
            `${config.detectionUrl}/save-profile-pic`,
            {
              method: 'POST',
              headers: { Authorization: `Bearer ${token}` },
              body: formData,
            }
          );
          if (!picRes.ok) {
            const errData = await picRes.json();
            picError.innerText =
              errData.error || 'Failed to set profile picture.';
            picError.style.display = 'block';
            return;
          }
        } catch (error) {
          console.error('Error uploading pic:', error);
          picError.innerText = 'An error occurred. Please try again.';
          picError.style.display = 'block';
          return;
        }
      }

      // All good!
      closeModal({ cancelled: false });
    });

    function closeModal(result) {
      // Hide the modal
      modal.style.display = 'none';

      // Cleanup
      step1.style.display = 'block';
      step2.style.display = 'none';
      fileInput.value = '';
      previewImg.style.display = 'none';
      placeholderIcon.style.display = 'inline-block';
      picError.style.display = 'none';
      usernameInput.value = '';
      usernameError.style.display = 'none';
      cropperWrapper.style.display = 'none';
      if (cropper) {
        cropper.destroy();
        cropper = null;
      }
      croppedBlob = null;

      resolve(result);
    }
  });
}

async function doLoad(token, is_logged_in) {
  if (isMobileDevice()) stateManager.updateState({ isMobileDevice: true });

  const hash = window.location.hash;
  const collectionPattern = /^#\/collections\/(\d+)$/;
  const match = hash.match(collectionPattern);
  const app = document.getElementById('app');
  const homepageContent = document.getElementById('homepage-content');

  // Already checks for #/collections/:id
  // We'll add a new check for #/profile/:username
  const userProfilePattern = /^#\/profile\/([A-Za-z0-9_\-]+)$/;
  const matchProfile = hash.match(userProfilePattern);

  if (matchProfile) {
    const username = matchProfile[1];
    showPublicProfile(username);
    return;
  }

  if (match) {
    // Hide homepage content
    homepageContent.style.display = 'none';
    app.style.display = 'block';
    app.style.visibility = 'visible';

    const collectionId = match[1];
    try {
      const user_info = await fetchUserInfoForCollection(collectionId);
      const profile_pic_id = user_info.profile_pic_id;
      const username = user_info.username;

      const response = await fetch(
        `${config.detectionUrl}/collections/${collectionId}`
      );
      const data = await response.json();

      if (data.error) {
        app.innerHTML = `<h1>${data.error}</h1>`;
      } else {
        let html = `<div class="content">`;
        html += `<div class="grid-container">`;
        html += `  <div class="collection-title-container">`;
        html += `    <div class="collection-user-info">`;
        html += `      <div class="collection-user-photo" id="collection-user-photo"></div>`;
        html += `      <div class="collection-username">`;
        html += `         <span id="collection_username">${user_info.username}</span>`;
        if (user_info.subscription_level === 'pro') {
          html += `         <span class="account-tag pro">Pro User</span>`;
        } else if (user_info.subscription_level === 'lifetime') {
          html += `         <span class="account-tag lifetime">Founding Member</span>`;
        }
        html += `      </div>`;
        html += `    </div>`;
        html += `  </div>`;
        html += `    <h2 id="collection_name" class="grid-title">${data.name}</h2>`;
        html += `  <div class="line"></div>`;
        html += `  <div class="card-grid"></div>`;
        html += `</div>`;

        app.innerHTML = html;

        if (profile_pic_id) {
          displayProfilePic(profile_pic_id, username);
        }

        let is_own_collection = false;

        if (is_logged_in) {
          const headers = makeHeader();
          const serverside_user = await getUser(headers);
          if (!serverside_user.username || !serverside_user.username.trim()) {
            await showProfileSetupWizardAndWait(token);
            const updatedUser = await getUser(headers);
            stateManager.updateState({ serverside_user: updatedUser });
          }
          is_own_collection = collectionId == serverside_user.collection_id;
        }

        loadCards(data, is_logged_in, is_own_collection, collectionId);
      }
    } catch (error) {
      console.error(error);
      app.innerHTML = `<h1>Error loading collection</h1>`;
    }
  } else {
    // Not a collection route: show homepage
    homepageContent.style.display = 'block';
    homepageContent.style.visibility = 'visible';
    app.style.display = 'none';
  }
}

async function fetchUserInfoForCollection(collectionId) {
  const response = await fetch(
    `${config.detectionUrl}/get-collection-user-info/${collectionId}`,
    {
      method: 'GET',
    }
  );
  const data = await response.json();

  if (response.ok) {
    console.log('Collection user info:', data);
    return data;
  } else {
    console.error('Error fetching collection user info:', data.error);
    return null;
  }
}

async function displayProfilePic(profile_pic_id, ownerUsername) {
  try {
    // Construct the download URL (adjust the extension as needed)
    const downloadUrl = `${config.detectionUrl}/uploads/${profile_pic_id}.jpg`;

    // Fetch the image
    const picRes = await fetch(downloadUrl);
    if (!picRes.ok) {
      console.error('Failed to download existing profile picture.');
      return;
    }

    // Convert response to a Blob and create an object URL
    const picBlob = await picRes.blob();
    const picURL = URL.createObjectURL(picBlob);

    // Get the photo container (this container is in your collections view)
    const photoContainer = document.getElementById('collection-user-photo');
    if (!photoContainer) return;

    // Only add the image if the container is empty
    if (photoContainer.children.length === 0) {
      // Create the image element
      const img = document.createElement('img');
      img.src = picURL;
      img.alt = 'Collection Owner Profile Picture';

      // If an ownerUsername is provided, wrap the image in a link to the profile page
      if (ownerUsername) {
        const link = document.createElement('a');
        link.href = `#/profile/${ownerUsername}`;
        link.appendChild(img);
        photoContainer.appendChild(link);
      } else {
        photoContainer.appendChild(img);
      }

      // Optionally adjust the container style (e.g., gap) if needed
      const title_container = document.querySelector(
        '.collection-title-container'
      );
      if (title_container) {
        title_container.style.gap = '15px';
      }
    }
  } catch (error) {
    console.error('Error downloading existing profile picture:', error);
  }
}

async function loadCards(data, is_logged_in, is_own_collection, collectionId) {
  try {
    const { isMobileDevice } = stateManager.getState();

    const cards = data.cards;
    const matched_items = data.matched_items;
    const priced_items = data.priced_items;
    const checklist_cards = data.checklist_cards;

    const grid = document.querySelector('.card-grid');
    grid.innerHTML = ''; // Clear the grid

    if (!isMobileDevice) {
      const title_container = document.querySelector(
        '.collection-title-container'
      );
      title_container.style.margin = '5px 0px 5px 0px';
    }

    if (cards.length === 0) {
      const defaultMessage = document.createElement('div');
      defaultMessage.className = 'default-text';
      defaultMessage.textContent = 'This collection is empty.';
      grid.appendChild(defaultMessage);
    } else {
      cards.forEach((card) => {
        const index = card.reference_card.id;
        const card_matched_items = matched_items?.[index] ?? [];
        const card_priced_items = priced_items?.[index] ?? [];
        const card_checklist_info = checklist_cards?.[index];

        // Adjust the createCardElement function to accept card_best_price as an argument
        const cardElement = createCardElement(
          card,
          card_matched_items,
          card_priced_items,
          card_checklist_info,
          is_logged_in,
          is_own_collection,
          collectionId
        );
        grid.appendChild(cardElement);
      });
    }
    logMessage(LOG_LEVELS.INFO, 'Cards loaded and displayed');
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error loading cards', error.toString());
  }
}

function getImage(img_element, imageUrl, alt_text) {
  logMessage(LOG_LEVELS.DEBUG, 'Fetching and showing image', { imageUrl });

  fetch(imageUrl, {
    method: 'GET',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob(); // Call the blob() method to get the blob
    })
    .then((blob) => {
      const localUrl = URL.createObjectURL(blob); // Use the blob to create a local URL
      img_element.src = localUrl;
      img_element.alt = alt_text;
    })
    .catch((error) =>
      logMessage(
        LOG_LEVELS.ERROR,
        'Error fetching the image:',
        error.toString()
      )
    );
}

function createImageElement(card_info) {
  logMessage(LOG_LEVELS.DEBUG, 'Creating image element for card', {
    cardId: card_info.user_card.id,
  });

  const img = document.createElement('img');
  getImage(
    img,
    card_info.user_card.front_image_url_medium,
    card_info.reference_card.identifier
  );

  logMessage(LOG_LEVELS.INFO, 'Image element created', {
    cardId: card_info.user_card.id,
  });
  return img;
}

function createTitleElement(title) {
  const statusDiv = document.createElement('div');

  statusDiv.classList.add('card-title');
  statusDiv.textContent = title;

  logMessage(LOG_LEVELS.INFO, 'Title element created');

  return statusDiv;
}

function createSubtitleElement(subtitle) {
  const statusDiv = document.createElement('div');

  statusDiv.classList.add('card-subtitle');
  statusDiv.textContent = subtitle ? subtitle : 'Unknown Team';

  logMessage(LOG_LEVELS.INFO, 'Subtitle element created');

  return statusDiv;
}

function createCardElement(
  card_info,
  matched_items,
  priced_items,
  card_checklist_info,
  is_logged_in,
  is_own_collection,
  collectionId
) {
  const cardDiv = document.createElement('div');
  cardDiv.classList.add('card');

  // If not the owner, apply a class that increases font size, etc.
  if (!is_own_collection) {
    cardDiv.classList.add('card--public-view');
  }

  // Clicking the card => open the card popup
  cardDiv.addEventListener('click', () => {
    const card_display_controller = new CardDisplayPopupController(
      null,
      card_info,
      true
    );
    card_display_controller.showCardDisplayPopup(matched_items, priced_items);
  });

  // ======== Title & Subtitle ========
  const titleContentContainer = document.createElement('div');
  titleContentContainer.classList.add('title-content-container');

  const title_text =
    card_info.reference_card.players[0] +
    ' - ' +
    card_info.reference_card.year +
    ' ' +
    card_info.reference_card.program;
  const title_text2 = card_info.reference_card.release_set;
  const subtitle_text =
    card_checklist_info.length > 0
      ? card_checklist_info[0].team + ' - ' + card_checklist_info[0].position
      : '';

  const title = createTitleElement(title_text);
  const title2 = createTitleElement(title_text2);
  const subtitle = createSubtitleElement(subtitle_text);

  if (title_text2) {
    titleContentContainer.appendChild(title);
    titleContentContainer.appendChild(title2);
  } else {
    titleContentContainer.appendChild(title2);
    titleContentContainer.appendChild(title);
  }
  titleContentContainer.appendChild(subtitle);

  cardDiv.appendChild(titleContentContainer);

  // ======== Main content container (Image + Description/Message) ========
  const contentContainer = document.createElement('div');
  contentContainer.classList.add('card-content-container');

  // -- Card Image --
  const img = createImageElement(card_info);
  contentContainer.appendChild(img);

  // -- Flexible row: description on the left, icon on the right --
  const descMsgContainer = document.createElement('div');
  descMsgContainer.classList.add('desc-msg-container');

  // (A) Description div (left side)
  const descContainer = document.createElement('div');
  descContainer.classList.add('desc-container');

  const userCard = card_info.user_card || {};
  const existingDesc = userCard.description || '';

  if (is_own_collection) {
    // Owner -> "Set Description" toggle
    const descArea = createDescriptionArea(
      existingDesc,
      collectionId,
      userCard.user_card_id
    );
    descContainer.appendChild(descArea);
  } else {
    // Non-owner -> read-only snippet (or nothing if empty)
    if (existingDesc.trim().length > 0) {
      const snippet = createDescriptionSnippet(existingDesc);
      descContainer.appendChild(snippet);
    } else {
      // If you want to show nothing at all, leave it blank
      // Or show a subtle "No Description"
      // descContainer.textContent = "No Description";
    }
  }

  descMsgContainer.appendChild(descContainer);

  // (B) Message icon button (right side). Always present in the DOM,
  // but hidden if the user is the owner
  const messageIconBtn = document.createElement('button');
  messageIconBtn.classList.add('message-icon-btn');
  messageIconBtn.innerHTML = '<i class="fas fa-comment"></i>';

  if (is_own_collection) {
    // Hide if this is the owner’s own card
    messageIconBtn.style.display = 'none';
  }

  // Stop card click from firing
  messageIconBtn.addEventListener('click', (event) => {
    event.stopPropagation();
    if (!is_logged_in) {
      showLoginPrompt();
      return;
    }
    showMessageModal(is_logged_in, card_info, collectionId);
  });

  descMsgContainer.appendChild(messageIconBtn);

  // Add descMsgContainer to contentContainer
  contentContainer.appendChild(descMsgContainer);
  cardDiv.appendChild(contentContainer);

  return cardDiv;
}

async function postMessageToBackend(cardId, collectionId, message) {
  const headers = makeHeader();
  const newHeaders = { ...headers, 'Content-Type': 'application/json' };
  const payload = {
    card_id: cardId,
    collection_id: collectionId,
    message: message,
  };

  const res = await fetchCardData(
    `${config.detectionUrl}/post-message`,
    'POST',
    newHeaders,
    payload
  );

  return res.body;
}

function createDescriptionSnippet(fullDescription) {
  const snippetContainer = document.createElement('div');
  snippetContainer.classList.add('description-snippet');

  const maxChars = 100; // or whatever length you prefer

  if (fullDescription.length <= maxChars) {
    snippetContainer.textContent = fullDescription;
  } else {
    const shortDescription = fullDescription.substring(0, maxChars) + '... ';
    snippetContainer.textContent = shortDescription;

    const readMoreLink = document.createElement('a');
    readMoreLink.href = '#';
    readMoreLink.textContent = 'Read more';
    readMoreLink.addEventListener('click', (event) => {
      event.stopPropagation(); // so we don't trigger the card click
      event.preventDefault();

      // You can open a detail modal or just expand inline:
      // For example, inline expansion:
      snippetContainer.textContent = fullDescription;
    });

    snippetContainer.appendChild(readMoreLink);
  }

  return snippetContainer;
}

function makeHeader() {
  const token = getUserToken();
  return { Authorization: `Bearer ${token}` };
}

function showLoginPrompt() {
  // Create the backdrop
  const backdrop = document.createElement('div');
  backdrop.classList.add('login-prompt-backdrop');

  // Create the modal container
  const modal = document.createElement('div');
  modal.classList.add('login-prompt-modal');

  // Header (with close button)
  const header = document.createElement('div');
  header.classList.add('login-prompt-header');
  const headerText = document.createElement('h2');
  headerText.textContent = 'Login Required';
  header.appendChild(headerText);

  // Close button (the “×”)
  const closeButton = document.createElement('button');
  closeButton.classList.add('login-prompt-close');
  closeButton.innerHTML = '×';
  closeButton.addEventListener('click', () => {
    document.body.removeChild(backdrop);
  });
  header.appendChild(closeButton);

  // Body (with the message)
  const body = document.createElement('div');
  body.classList.add('login-prompt-body');
  const message = document.createElement('p');
  message.textContent =
    'Unlock the full power of CardSense to track, share, and trade your cards. ' +
    'Log in or create a free account now to get started!';
  body.appendChild(message);

  // Footer (with links)
  const footer = document.createElement('div');
  footer.classList.add('login-prompt-footer');

  const currentUrl = window.location.href;

  const loginLink = document.createElement('a');
  loginLink.href = `/login.html?redirect=${encodeURIComponent(currentUrl)}`; // adjust path
  loginLink.textContent = 'Log In';
  loginLink.classList.add('login-btn-link');

  const orSpan = document.createElement('span');
  orSpan.textContent = 'or';

  const signupLink = document.createElement('a');
  signupLink.href = `/signup.html?redirect=${encodeURIComponent(currentUrl)}`; // adjust path
  signupLink.textContent = 'Sign Up';
  signupLink.classList.add('login-btn-link');

  footer.appendChild(loginLink);
  footer.appendChild(orSpan);
  footer.appendChild(signupLink);

  // Assemble modal
  modal.appendChild(header);
  modal.appendChild(body);
  modal.appendChild(footer);

  // Clicking the backdrop closes the modal
  backdrop.addEventListener('click', (event) => {
    if (event.target === backdrop) {
      document.body.removeChild(backdrop);
    }
  });

  // Append modal to backdrop
  backdrop.appendChild(modal);

  // Finally, add to the document
  document.body.appendChild(backdrop);
}

function getCardTitle(reference_card) {
  let title;

  title = `${reference_card.year} ${reference_card.program} ${reference_card.release_set} ${reference_card.players}`;

  if (reference_card.parallel) {
    title += ': ' + reference_card.parallel;
  }

  return title;
}

function showMessageModal(is_logged_in, cardInfo, collectionId) {
  // 1. Create the backdrop
  const modalBackdrop = document.createElement('div');
  modalBackdrop.classList.add('message-modal-backdrop');

  // 2. Create the modal container
  const modalContainer = document.createElement('div');
  modalContainer.classList.add('message-modal-container');

  // ============ HEADER =============
  const modalHeader = document.createElement('div');
  modalHeader.classList.add('message-modal-header');

  const modalTitle = document.createElement('h2');
  modalTitle.textContent = 'Send a Message';
  modalHeader.appendChild(modalTitle);

  // Close button (the "×")
  const closeButton = document.createElement('button');
  closeButton.classList.add('message-modal-close');
  closeButton.innerHTML = '×';
  closeButton.addEventListener('click', () => {
    document.body.removeChild(modalBackdrop);
  });
  modalHeader.appendChild(closeButton);

  // ============ BODY =============
  const modalBody = document.createElement('div');
  modalBody.classList.add('message-modal-body');

  // Optionally show which card the user is messaging about
  const cardInfoDiv = document.createElement('div');
  cardInfoDiv.classList.add('message-modal-card-info');
  cardInfoDiv.innerHTML = `
    Messaging about ${getCardTitle(cardInfo.reference_card)}
    ${cardInfo.reference_card.name ? `(${cardInfo.reference_card.name})` : ''}
  `;
  modalBody.appendChild(cardInfoDiv);

  // A text area for the user to type their message
  const messageTextarea = document.createElement('textarea');
  messageTextarea.name = 'messageBody';
  messageTextarea.placeholder = 'Type your message here...';
  modalBody.appendChild(messageTextarea);

  // ============ FOOTER =============
  const modalFooter = document.createElement('div');
  modalFooter.classList.add('message-modal-footer');

  // "Send" button
  const sendButton = document.createElement('button');
  sendButton.classList.add('message-modal-send-btn');
  sendButton.textContent = 'Send';

  sendButton.addEventListener('click', async () => {
    const messageText = messageTextarea.value.trim();

    if (!messageText) {
      alert('Please enter a message.');
      return;
    }

    // Example: integrate with your back-end or show success
    try {
      // 1) Check user login
      if (!is_logged_in) {
        showLoginPrompt();
        return;
      }

      await postMessageToBackend(
        cardInfo.user_card.user_card_id,
        collectionId,
        messageText
      );

      alert('Message sent successfully!');

      // 3) Close the modal
      document.body.removeChild(modalBackdrop);
    } catch (err) {
      console.error('Error sending message:', err);
      alert('Something went wrong. Please try again.');
    }
  });

  modalFooter.appendChild(sendButton);

  // Append header, body, footer to the container
  modalContainer.appendChild(modalHeader);
  modalContainer.appendChild(modalBody);
  modalContainer.appendChild(modalFooter);

  // Append container to backdrop
  modalBackdrop.appendChild(modalContainer);

  // Clicking on backdrop closes the modal
  modalBackdrop.addEventListener('click', (event) => {
    if (event.target === modalBackdrop) {
      document.body.removeChild(modalBackdrop);
    }
  });

  // Finally, append the backdrop (with the modal inside) to <body>
  document.body.appendChild(modalBackdrop);

  // Optionally focus the textarea
  messageTextarea.focus();
}

function createDescriptionArea(existingDesc, collectionId, cardId) {
  // Main container
  const container = document.createElement('div');
  container.classList.add('description-container');

  // Placeholder text (or actual description)
  const placeholder = document.createElement('div');
  placeholder.classList.add('description-placeholder');
  placeholder.textContent =
    existingDesc && existingDesc.trim().length > 0
      ? existingDesc
      : 'Set Description';

  // The hidden input that becomes visible on click
  const input = document.createElement('input');
  input.type = 'text';
  input.classList.add('description-input');
  input.value = existingDesc || '';
  input.style.display = 'none';

  // Add to container
  container.appendChild(placeholder);
  container.appendChild(input);

  // Click on placeholder => show input, focus
  placeholder.addEventListener('click', (event) => {
    event.stopPropagation(); // to avoid opening the card popup
    placeholder.style.display = 'none';
    input.style.display = 'inline-block';
    input.focus();
  });

  // Handle ENTER key => save and revert to placeholder
  input.addEventListener('keydown', async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await commitDescription();
    }
  });

  // Handle blur => save and revert to placeholder
  input.addEventListener('blur', async () => {
    await commitDescription();
  });

  async function commitDescription() {
    // The user may have typed something
    const newDesc = input.value.trim();

    // Call your backend to save
    await saveCustomDescription(cardId, collectionId, newDesc);

    // Update placeholder text
    placeholder.textContent = newDesc.length > 0 ? newDesc : 'Set Description';

    // Hide input, show placeholder again
    input.style.display = 'none';
    placeholder.style.display = 'inline-block';
  }

  return container;
}

async function saveCustomDescription(cardId, collectionId, newDescription) {
  try {
    const headers = makeHeader(); // includes Authorization
    headers['Content-Type'] = 'application/json';

    const res = await fetch(`${config.detectionUrl}/save-card-description`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        card_id: cardId,
        collection_id: collectionId,
        description: newDescription,
      }),
    });

    if (!res.ok) {
      const errData = await res.json();
      console.error(
        'Error saving description:',
        errData.error || res.statusText
      );
      alert('Failed to save description. Please try again.');
    } else {
      console.log('Description saved successfully');
    }
  } catch (error) {
    console.error('Error in saveCustomDescription:', error);
    alert('An error occurred while saving the description.');
  }
}

async function showPublicProfile(username) {
  const app = document.getElementById('app');
  const homepageContent = document.getElementById('homepage-content');

  // Hide the homepage (if open), show the #app container
  homepageContent.style.display = 'none';
  app.style.display = 'block';
  app.style.visibility = 'visible';

  try {
    // Example endpoint: GET /public-profile/:username
    const response = await fetch(
      `${config.detectionUrl}/public-profile/${username}`,
      { method: 'GET' }
    );
    const data = await response.json();

    if (!response.ok) {
      // e.g. { error: 'User not found' }
      app.innerHTML = `<h2>${data.error || 'Unable to load profile'}</h2>`;
      return;
    }

    let html = `<div class="public-profile">`;

    // Header section: Profile picture, username, account status tag, and lifetime account number (if applicable)
    html += `  <div class="public-profile__header">`;
    html += `    <div id="collection-user-photo" class="public-profile__photo"></div>`;
    html += `    <div class="public-profile__user-info">`;
    html += `      <p class="public-profile__username">${data.username}`;
    // Add an account status tag next to the username
    if (data.subscription_level === 'pro') {
      html += ` <span class="account-tag pro">Pro</span>`;
    } else if (data.subscription_level === 'lifetime') {
      html += ` <span class="account-tag pro">Pro</span>`;
      html += ` <span class="account-tag lifetime">Founding Member</span>`;
    }
    html += `      </p>`;
    // If a lifetime number is present, display it prominently
    if (data.lifetime_number) {
      html += `      <p class="public-profile__lifetime-number">Founding Member Number: <strong>${data.lifetime_number}/149</strong></p>`;
    }
    html += `    </div>`; // end .public-profile__user-info
    html += `  </div>`; // end .public-profile__header

    // Details section: collection button, account number, bio
    html += `  <div class="public-profile__details">`;

    // Collection Button with an explicit title
    if (data.collection_id) {
      html += `    <button 
                    id="view-collection-btn" 
                    class="subtle-button-sharp"
                    onclick="location.href='#/collections/${data.collection_id}'"
                    title="View Collection"
                  >
                    ${data.collection_name}
                  </button>`;
    }

    // Display bio (or a fallback message)
    if (data.bio && data.bio.trim().length > 0) {
      html += `    <p class="public-profile__bio">${data.bio}</p>`;
    } else {
      html += `    <p class="public-profile__bio">No bio provided.</p>`;
    }

    html += `  </div>`; // End of details section
    html += `</div>`; // End of public-profile

    app.innerHTML = html;

    // Load the profile picture (implementation of displayProfilePic() remains unchanged)
    displayProfilePic(data.profile_pic_id, null);
  } catch (error) {
    console.error(error);
    app.innerHTML = `<h2>Error loading profile</h2>`;
  }
}

function isMobileDevice() {
  let check = false;
  // Patterns to match against user agent for common mobile devices
  (function (a) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(a)
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
