import stateManager from './state_manager.js';
import { sendAuthenticatedRequest } from '../auth';
import { LOG_LEVELS, logMessage } from '../logging';

// Fetches streaming data from a given URL.
async function fetchStreamingData(url, headers, successCallback, abortSignal) {
  logMessage(LOG_LEVELS.INFO, 'fetchStreamingData called with', { url });

  try {
    const result = await sendAuthenticatedRequest(url, {
      headers,
      responseType: 'stream',
    });
    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }
    await processStreamingData(result, successCallback, abortSignal);
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Fetch error:', error.toString());
  }
}

// Processes the streaming data.
async function processStreamingData(result, successCallback, abortSignal) {
  const reader = result.stream.getReader();
  const decoder = new TextDecoder();
  let buffer = '';

  try {
    await readStream(reader, decoder, buffer, successCallback, abortSignal);
    processBuffer(buffer, successCallback); // Process any remaining data in the buffer
  } catch (error) {
    handleStreamError(error);
  } finally {
    reader.releaseLock();
  }
}

async function readStream(
  reader,
  decoder,
  buffer,
  successCallback,
  abortSignal
) {
  abortSignal.addEventListener('abort', () => {
    logMessage(LOG_LEVELS.ERROR, 'Stream aborted by user.');
    // You might want to handle the abort signal here if there's any cleanup needed
  });

  while (true) {
    if (abortSignal.aborted) {
      throw new Error('Stream cancelled by user');
    }

    try {
      // Use readWithTimeout for each read operation, passing the abortSignal for direct checks
      const { done, value } = await readWithTimeout(reader, abortSignal);
      if (done) break; // Exit the loop if the stream is finished
      buffer += decoder.decode(value, { stream: true });
      buffer = processChunk(buffer, successCallback, abortSignal);
    } catch (error) {
      logMessage(
        LOG_LEVELS.ERROR,
        `Error in stream operation: ${error.message}`
      );
      throw error; // Rethrow to ensure upstream handling of errors, including timeouts and aborts
    }
  }
}

// Processes any remaining data in the buffer after stream ends.
function processBuffer(buffer, successCallback) {
  if (!buffer) return; // If buffer is empty, do nothing

  try {
    // Attempt to parse any remaining data as JSON
    const data = JSON.parse(buffer);
    handleStreamedData(data, successCallback);
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error parsing JSON after stream ended:',
      error.toString()
    );
  }
}

// Handles individual chunks of data from the stream.
function processChunk(buffer, successCallback, abortSignal) {
  if (abortSignal.aborted) {
    return buffer;
  }

  const lines = buffer.split('\n');
  buffer = lines.pop(); // Retain incomplete line for next chunk

  lines.forEach((line) => {
    if (line) parseAndHandleData(line, successCallback);
  });

  return buffer;
}

// Parses a line of JSON and executes the success callback.
function parseAndHandleData(line, successCallback) {
  try {
    const data = JSON.parse(line);
    handleStreamedData(data, successCallback);
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error parsing JSON:', error.toString());
  }
}

// Adjusted readWithTimeout to handle abort signal more directly
function readWithTimeout(reader, abortSignal) {
  if (abortSignal.aborted) {
    return Promise.reject(new Error('Stream cancelled by user'));
  }

  const readPromise = reader.read();
  const timeoutPromise = new Promise((resolve, reject) =>
    setTimeout(() => reject(new Error('Read timeout')), 120000)
  ); // 120 seconds

  // Return the read promise or timeout, whichever happens first
  return Promise.race([readPromise, timeoutPromise]);
}

// Handles streamed data based on its status.
async function handleStreamedData(data, successCallback) {
  if (data.status && data.status === 'complete') {
    logMessage(LOG_LEVELS.INFO, 'Data processing is complete.');
    updateStreamState();
    return;
  }

  successCallback(data);
}

// Updates the stream state in the global state manager.
function updateStreamState() {
  const { frontStreamDone } = stateManager.getState();
  const newState = frontStreamDone
    ? { backStreamDone: true }
    : { frontStreamDone: true };
  stateManager.updateState(newState);
}

// Handles errors that occur during stream processing.
function handleStreamError(error) {
  logMessage(
    LOG_LEVELS.ERROR,
    'Error processing streaming data:',
    error.toString()
  );

  if (error.message === 'Stream cancelled by user') {
    logMessage(LOG_LEVELS.INFO, 'Stream processing was cancelled by the user.');
  } else {
    alert(
      'An unknown error occurred. Please contact us with this message: ' +
        error.message
    );
    stateManager.updateState({ stopProcessing: true });
  }
}

// Export the function for use in other modules
export { fetchStreamingData };
