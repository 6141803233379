import { ImageModel } from '../../ui_models/two_image_container_model.js';
import {
  TwoImagePanelBase,
  TwoImagePanelEbay,
} from '../../ui_components/popups/two_image_panels.js';
import {
  AcceptButton,
  RejectButton,
  RescanButton,
} from '../../ui_components/buttons.js';
import config from '../../../config/index.js';
import {
  attachDropzoneSuccessListener,
  setupDropzone,
} from '../dropzone_controller.js';
import { fetchStreamingData } from '../../data/streaming.js';
import { PopupWithDropzone } from '../../ui_components/popups/base_popup';
import stateManager from '../../data/state_manager.js';
import { showOverlay, hideOverlay } from '../../ui_components/overlay.js';
import { fetchCardData } from '../../data/update_operations.js';
import { logMessage, LOG_LEVELS } from '../../logging.js';

class MenuController {
  constructor(menuModel, downloadModel, sellModel, menuView) {
    this.model = menuModel;
    this.downloadModel = downloadModel;
    this.sellModel = sellModel;
    this.view = menuView;

    this.view.bindMenu(() => this.handleMenuClick());
    this.view.bindDownload(() => this.handleDownloadClick());
    this.view.bindSell(() => this.handleSellClick());

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleSellClick = this.handleSellClick.bind(this);
  }

  handleMenuClick() {
    this.view.updateMenuItems(this.model.getItems());
    this.view.toggleDropdown();
  }

  handleDownloadClick() {
    this.view.updateMenuItems(this.downloadModel.getItems());
    this.view.toggleDropdown();
  }

  handleSellClick() {
    this.view.updateMenuItems(this.sellModel.getItems());
    this.view.toggleDropdown();
  }

  setMenuItems(menuItems) {
    this.model.setItems(menuItems);
    this.view.updateMenuItems(menuItems);
  }

  toggleMenu() {
    this.view.toggleDropdown();
  }
}

class CompsPopupController {
  async showCompsPopup(
    main_popup,
    card_info,
    matched_items,
    priced_items,
    headers
  ) {
    const defaultTab =
      priced_items.length > 0
        ? 'priced'
        : matched_items.length > 0
          ? 'matched'
          : null;
    const popup = new TwoImagePanelEbay(defaultTab, true);

    const imageModel = new ImageModel();
    imageModel.setHeaders(headers);
    const local_url1 = await imageModel.fetchImage(
      card_info.user_card.front_image_url_large
    );
    const local_url2 = await imageModel.fetchImage(
      card_info.user_card.back_image_url_large
    );

    popup.setLeftImage(local_url1);
    popup.setRightImage(local_url2);

    // popup.populateMonitoredListings(matched_items);
    popup.populateCompletedListings(priced_items);

    const closeButton = new RejectButton('Close');
    popup.addButtonToLeft(closeButton, () => this.handleClose(popup));
    this.setupMatchContainer(popup);
    this.addRefreshToUi(card_info.user_card);

    popup.bindCloseButton(() => this.handleClose(popup));
    this.popup = popup;

    logMessage(LOG_LEVELS.INFO, 'Comps display popup shown');
  }

  handleClose(popup) {
    popup.remove();
  }

  addRefreshToUi(user_card) {
    const refresh = new RescanButton(' Refresh Comps');
    this.matchContainer.append(refresh.instance());
    refresh
      .instance()
      .addEventListener('click', () =>
        this.handleRefresh(this.headers, user_card)
      );
  }

  setupMatchContainer(popup) {
    if (this.matchContainer) {
      this.matchContainer.remove();
    }

    this.matchContainer = popup.createElementWithAttributes('div', {
      className: 'match-container',
    });
    popup.insertElement(this.matchContainer, 'beforeButtonContainer');
  }

  async handleRefresh(headers, user_card) {
    const newHeaders = { ...headers, 'Content-Type': 'application/json' };

    let payload = {};
    payload.user_card_id = user_card.user_card_id;

    try {
      showOverlay();
      const res = await fetchCardData(
        `${config.detectionUrl}/refresh-comps`,
        'POST',
        newHeaders,
        payload
      );
      const priced_items = res.body.priced_items; // Ensure this key exists in the response

      const cardId = Object.keys(priced_items ?? {})[0];
      const card_priced_items = priced_items?.[cardId] ?? [];

      this.popup.populateCompletedListings(card_priced_items);
    } catch (error) {
      console.error('Error fetching card data:', error);
      // Handle the error appropriately (e.g., show a user-friendly message)
    }

    hideOverlay();
  }
}

class RescanDecisionPopupContrller {
  constructor(type, original_card_info, new_card_info) {
    this.closeButton = new RejectButton('Cancel');
    this.submitButton = new AcceptButton('Update Image');
    this.view = new TwoImagePanelBase();
    this.original_card_info = original_card_info;
    this.new_card_info = new_card_info;
    this.type = type;
  }

  async showPopup(
    main_popup,
    rescan_controller,
    headers,
    leftImage,
    rightImage
  ) {
    const imageModel = new ImageModel();
    imageModel.setHeaders(headers);
    const old_local_url = await imageModel.fetchImage(leftImage);
    this.new_local_url = await imageModel.fetchImage(rightImage);

    this.view.setLeftImage(old_local_url);
    this.view.setRightImage(this.new_local_url);

    this.view.setLeftCaption('Original Image');
    this.view.setRightCaption('New Image');

    this.view.addButtonToLeft(this.closeButton, () => this.handleClose());
    this.view.addButtonToRight(this.submitButton, () =>
      this.handleSubmit(
        main_popup,
        rescan_controller,
        this.original_card_info,
        this.new_card_info
      )
    );

    this.view.bindCloseButton(() => this.handleClose());
  }

  handleClose() {
    this.view.remove();
  }

  translateNewCardInfo(original_card_info, new_card_info) {
    let card_info_copy = JSON.parse(JSON.stringify(original_card_info));

    if (this.type == 'front') {
      card_info_copy.user_card.front_image_url_large =
        new_card_info.card_front.large_image_url;
      card_info_copy.user_card.front_image_url_medium =
        new_card_info.card_front.medium_image_url;
      card_info_copy.user_card.front_image_url_parent =
        new_card_info.card_front.parent_image_url;
      card_info_copy.user_card.front_coordinates = JSON.stringify(
        new_card_info.card_front.card_coordinates
      );
    } else {
      card_info_copy.user_card.back_image_url_large =
        new_card_info.card_front.large_image_url;
      card_info_copy.user_card.back_image_url_medium =
        new_card_info.card_front.medium_image_url;
      card_info_copy.user_card.back_image_url_parent =
        new_card_info.card_front.parent_image_url;
      card_info_copy.user_card.back_coordinates = JSON.stringify(
        new_card_info.card_front.card_coordinates
      );
    }

    return card_info_copy;
  }

  async doSubmit(
    main_popup,
    rescan_controller,
    original_card_info,
    new_card_info
  ) {
    const translated_card_info = this.translateNewCardInfo(
      original_card_info,
      new_card_info
    );

    rescan_controller.updateLocalUrl(this.new_local_url);
    if (this.type == 'front') {
      main_popup.replaceFront(this.new_local_url, translated_card_info);
    } else {
      main_popup.replaceBack(this.new_local_url, translated_card_info);
    }
  }

  async handleSubmit(
    main_popup,
    rescan_controller,
    original_card_info,
    new_card_info
  ) {
    await this.doSubmit(
      main_popup,
      rescan_controller,
      original_card_info,
      new_card_info
    );
    this.view.remove();
  }
}

class RescanPopupController {
  constructor(type, localImageUrl) {
    this.closeButton = new RejectButton('Close');
    this.submitButton = new AcceptButton('Submit');
    this.handleStateChangeForDropzoneFiles =
      this.handleStateChangeForDropzoneFiles.bind(this);
    this.handleRescanSuccess = this.handleRescanSuccess.bind(this);
    this.dropzone_id = 'rescan-dropzone';
    this.view = new PopupWithDropzone(this.dropzone_id);
    this.type = type;
    this.localImageUrl = localImageUrl;
  }

  showRescanPopup(main_popup, card_info, headers) {
    logMessage(LOG_LEVELS.DEBUG, 'Showing comps display popup');
    const dropzone_id = 'rescan-dropzone';

    this.dropzone = setupDropzone(
      headers,
      '#' + dropzone_id,
      `${config.detectionUrl}/rescan-image`,
      'Upload Image'
    );

    this.view.addButton(this.closeButton, () => this.handleClose());
    this.view.addButton(this.submitButton, () =>
      this.handleSubmit(this.dropzone)
    );

    this.view.bindCloseButton(() => this.handleClose());

    stateManager.subscribe(this.handleStateChangeForDropzoneFiles);
    stateManager.updateState({ inRescan: true });

    attachDropzoneSuccessListener(
      this.dropzone.instance(),
      this.createDropzoneSuccessHandler(
        this.handleRescanSuccess,
        main_popup,
        card_info,
        headers
      ),
      headers
    );

    logMessage(LOG_LEVELS.INFO, 'Rescan display popup shown');
  }

  handleStateChangeForDropzoneFiles(newState) {
    try {
      if (newState.dropzoneFiles == 1 || newState.dropzoneFiles == 3) {
        this.submitButton.enable();
      } else {
        this.submitButton.disable();
      }
    } catch (error) {
      logMessage(
        LOG_LEVELS.ERROR,
        'Error in state change for Dropzone Files changes',
        { error: error.message }
      );
    }
  }

  handleClose() {
    this.dropzone.removeAllFiles();
    this.view.remove();
  }

  createDropzoneSuccessHandler(
    successCallback,
    main_popup,
    card_info,
    headers
  ) {
    return function (response) {
      try {
        showOverlay();
        logMessage(LOG_LEVELS.INFO, 'Processing initiated.', {
          requestId: response.request_id,
        });
        const requestId = response.request_id;
        const controller = new AbortController();

        // abortControllers.push(controller);
        fetchStreamingData(
          `${config.detectionUrl}/get-data-stream/${requestId}`,
          headers,
          (data) => successCallback(data, card_info, main_popup, headers),
          controller.signal
        );
      } catch (error) {
        logMessage(LOG_LEVELS.ERROR, 'Error in dropzone success handler', {
          error: error.message,
        });
      }
    };
  }

  handleRescanSuccess(data, card_info, main_popup, headers) {
    const { stopProcessing } = stateManager.getState();

    if (data.hasOwnProperty('error_code')) {
      let error_message = 'Unknown error.';

      if (data.error_code == -1) {
        error_message =
          'Too many front facing cards submitted. Please submit no more than ' +
          data.max_card_count +
          ' cards. If you submitted ' +
          data.max_card_count +
          ' cards or less, please try retaking the picture.';
      }

      alert(error_message);
      stateManager.updateState({ reinitialize: true });
    }

    // Check if data has the card_front_count key
    else if (data.hasOwnProperty('card_front_count')) {
      if (data.card_front_count > 1) {
        stateManager.updateState({
          stopProcessing: true,
          alertOnMismatch: true,
        });
      } else {
        stateManager.updateState({
          currentCardFrontCount: data.card_front_count,
        });
      }
    } else {
      if (!stopProcessing) {
        hideOverlay();
        this.dropzone.removeAllFiles();
        this.view.remove();
        const decisionController = new RescanDecisionPopupContrller(
          this.type,
          card_info,
          data
        );
        decisionController.showPopup(
          main_popup,
          this,
          headers,
          this.localImageUrl,
          data.card_front.large_image_url
        );
        logMessage(LOG_LEVELS.INFO, 'Rescan data processed. Queue updated.');
      }
    }
  }

  handleSubmit(dropzone) {
    dropzone.processFiles();
  }

  updateLocalUrl(url) {
    this.localImageUrl = url;
  }
}

export { MenuController, RescanPopupController, CompsPopupController };
