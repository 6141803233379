import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from 'firebase/auth';
import { logMessage, LOG_LEVELS } from './logging.js';

import { initializeApp } from 'firebase/app';
import stateManager from './data/state_manager.js';
import { getAuth, signOut } from 'firebase/auth';
import config from '../config/index.js';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC6YspNVSZdTIaRIpyo2L5Ml35GYprkMBA',
  authDomain: 'cardsense-8cd27.firebaseapp.com',
  projectId: 'cardsense-8cd27',
  storageBucket: 'cardsense-8cd27.appspot.com',
  messagingSenderId: '171934930018',
  appId: '1:171934930018:web:41777cebb332a62ea7ad3b',
  measurementId: 'G-RJMCE6WQ49',
};

async function setupAuthentication(
  handleAuthenticatedUser,
  handleUnauthenticatedUser
) {
  // Initialize Firebase only once
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  // const analytics = getAnalytics(app);

  onAuthStateChanged(auth, async (user) => {
    if (!user) {
      // User is not signed in, so we can handle unauthenticated user scenario
      stateManager.updateState({ userObj: null });
      await handleUnauthenticatedUser();
    } else {
      logMessage(LOG_LEVELS.INFO, 'User is authenticated');
    }
  });

  auth.onIdTokenChanged(async (user) => {
    if (user) {
      // User is signed in, get the token
      stateManager.updateState({ userObj: user });
      const token = await user.getIdToken();

      // Send the ID token to the backend to establish a session
      try {
        const response = await fetch(`${config.detectionUrl}/session-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies in the request
          body: JSON.stringify({ idToken: token }),
        });

        if (!response.ok) {
          throw new Error('Failed to establish session on backend.');
        }

        // Session is established; proceed with your existing flow
        await handleAuthenticatedUser(user, token);
      } catch (error) {
        console.error('Error during session login:', error.toString());
        // Optionally, handle the error (e.g., sign out the user)
      }
    } else {
      // User is signed out
      stateManager.updateState({ userObj: null });
      await handleUnauthenticatedUser();
    }
  });
}

export const loginUserWithEmailAndPassword = async (email, password) => {
  const auth = getAuth();
  const signInResult = await signInWithEmailAndPassword(auth, email, password);
  return signInResult;
};

export const loginWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

export const loginWithApple = async () => {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');
  return signInWithPopup(auth, provider);
};

async function getAuthToken(forceRefresh = false) {
  const { userObj } = stateManager.getState();

  return await userObj.getIdToken(forceRefresh);
}

async function sendAuthenticatedRequest(url, options = {}) {
  const makeRequest = async (forceRefresh) => {
    // Extract isStreaming and responseType from options, providing default values
    const { responseType = 'json', headers, ...fetchOptions } = options;
    const authOptions = {
      ...fetchOptions,
      headers: {
        ...headers,
        Authorization: `Bearer ${await getAuthToken(forceRefresh)}`,
      },
    };

    const response = await fetch(url, authOptions);

    if (responseType == 'stream') {
      // For streaming, directly return the raw response stream or attach it to the result
      return {
        ok: response.ok,
        status: response.status,
        stream: response.body,
        headers: response.headers,
      };
    } else if (responseType === 'blob') {
      // Handle blob responses specifically
      return {
        ok: response.ok,
        status: response.status,
        blob: await response.blob(),
        headers: response.headers,
      };
    }

    // Default JSON handling
    const data = await response.json().catch(() => ({}));
    return {
      ok: response.ok,
      status: response.status,
      body: data,
      headers: response.headers,
    };
  };

  let result = await makeRequest(false); // Try with current token
  if (!result.ok && result.errorCode === 'token_expired') {
    // Token expired, so force refresh and retry the request
    result = await makeRequest(true);
  }
  return result; // Return the structured object instead of the raw response
}

export const verifyUser = async (user) => {
  const verifyUserEndpoint = `${config.detectionUrl}/verifyUser`;
  const email = user.email;

  try {
    const result = await sendAuthenticatedRequest(
      verifyUserEndpoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      },
      user
    );

    if (!result.ok) {
      throw new Error(result.body.error || 'Failed to verify user.');
    }

    return result.body; // Use the already parsed body
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error during user verification:',
      error.toString()
    );
    throw error;
  }
};

async function do_logout() {
  // Initialize Firebase only once
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  onAuthStateChanged(auth, (user) => {
    if (!user) {
      // User is not signed in, so we can handle unauthenticated user scenario
      stateManager.updateState({ userObj: null });
      handleUnauthenticatedUser();
    } else {
      // User is signed in
      stateManager.updateState({ userObj: user });
      logMessage(LOG_LEVELS.INFO, 'User is authenticated');
    }
  });

  auth.onIdTokenChanged(async (user) => {
    if (user) {
      // Send the token to your backend for any cleanup processes
      sendAuthenticatedRequest(`${config.detectionUrl}/logout`, {
        method: 'POST',
        body: JSON.stringify({ action: 'logout' }),
      })
        .catch((error) => {
          logMessage(
            LOG_LEVELS.ERROR,
            'Failed to notify backend about logout:',
            error.toString()
          );
        })
        .finally(() => {
          return signOut(auth);
        })
        .then(() => {
          logMessage(LOG_LEVELS.INFO, 'User signed out.');
          window.location.href = '/login.html';
        })
        .catch((error) => {
          logMessage(
            LOG_LEVELS.ERROR,
            'Error signing out of Firebase:',
            error.toString()
          );
        });
    }
  });
}

// Export the setup and utility functions
export { setupAuthentication, do_logout, sendAuthenticatedRequest };
