import { LOG_LEVELS, logMessage } from './logging.js';
import { CardDisplayPopupController } from './controllers/popups/card_display_popup_controller.js';
import stateManager from './data/state_manager.js';
import config from '../config/index.js';

document.addEventListener('DOMContentLoaded', () => {
  const hash = window.location.hash;
  const collectionPattern = /^#\/collections\/(\d+)$/;
  const match = hash.match(collectionPattern);
  const app = document.getElementById('app');
  const homepageContent = document.getElementById('homepage-content');

  if (match) {
    if (isMobileDevice()) stateManager.updateState({ isMobileDevice: true });

    const collectionId = match[1];
    // Hide homepage content since we're showing a collection
    if (homepageContent) {
      homepageContent.style.display = 'none';
    }

    // Fetch the collection data
    fetch(`${config.detectionUrl}/collections/${collectionId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          app.innerHTML = `<h1>${data.error}</h1>`;
        } else {
          let html = `<div class="content">`;
          html += `<div class="grid-container">`;
          html += `    <div class="collection-title-container">`;
          html += `<h2 id="collection_name" class="grid-title">${data.name}</h2>`;
          html += `    </div>`;
          html += `    <div class="line"></div>`;
          html += `<div class="card-grid"></div>`;
          html += `</div>`;
          html += `</div>`;
          app.innerHTML = html;
          loadCards(data);
        }
      })
      .catch(() => {
        app.innerHTML = `<h1>Error loading collection</h1>`;
      });
  } else {
    // If it's not a collection route, we just show the normal homepage content
    // (The #app can remain empty, or you can put default homepage content in #app if desired)
    if (homepageContent) {
      homepageContent.style.display = 'block';
    }
  }
});

async function loadCards(data) {
  try {
    const { isMobileDevice } = stateManager.getState();

    const cards = data.cards;
    const matched_items = data.matched_items;
    const priced_items = data.priced_items;
    const checklist_cards = data.checklist_cards;

    const grid = document.querySelector('.card-grid');
    grid.innerHTML = ''; // Clear the grid

    if (!isMobileDevice) {
      const title_container = document.querySelector(
        '.collection-title-container'
      );
      title_container.style.margin = '0px 0px 20px 0px';
    }

    if (cards.length === 0) {
      const defaultMessage = document.createElement('div');
      defaultMessage.className = 'default-text';
      defaultMessage.textContent = 'This collection is empty.';
      grid.appendChild(defaultMessage);
    } else {
      cards.forEach((card) => {
        const index = card.reference_card.id;
        const card_matched_items = matched_items?.[index] ?? [];
        const card_priced_items = priced_items?.[index] ?? [];
        const card_checklist_info = checklist_cards?.[index];

        // Adjust the createCardElement function to accept card_best_price as an argument
        const cardElement = createCardElement(
          card,
          card_matched_items,
          card_priced_items,
          card_checklist_info
        );
        grid.appendChild(cardElement);
      });
    }
    logMessage(LOG_LEVELS.INFO, 'Cards loaded and displayed');
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error loading cards', error.toString());
  }
}

function getImage(img_element, imageUrl, alt_text) {
  logMessage(LOG_LEVELS.DEBUG, 'Fetching and showing image', { imageUrl });

  fetch(imageUrl, {
    method: 'GET',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob(); // Call the blob() method to get the blob
    })
    .then((blob) => {
      const localUrl = URL.createObjectURL(blob); // Use the blob to create a local URL
      img_element.src = localUrl;
      img_element.alt = alt_text;
    })
    .catch((error) =>
      logMessage(
        LOG_LEVELS.ERROR,
        'Error fetching the image:',
        error.toString()
      )
    );
}

function createImageElement(card_info) {
  logMessage(LOG_LEVELS.DEBUG, 'Creating image element for card', {
    cardId: card_info.user_card.id,
  });

  const img = document.createElement('img');
  getImage(
    img,
    card_info.user_card.front_image_url_medium,
    card_info.reference_card.identifier
  );

  logMessage(LOG_LEVELS.INFO, 'Image element created', {
    cardId: card_info.user_card.id,
  });
  return img;
}

function createTitleElement(title) {
  const statusDiv = document.createElement('div');

  statusDiv.classList.add('card-title');
  statusDiv.textContent = title;

  logMessage(LOG_LEVELS.INFO, 'Title element created');

  return statusDiv;
}

function createSubtitleElement(subtitle) {
  const statusDiv = document.createElement('div');

  statusDiv.classList.add('card-subtitle');
  statusDiv.textContent = subtitle ? subtitle : 'Unknown Team';

  logMessage(LOG_LEVELS.INFO, 'Subtitle element created');

  return statusDiv;
}

function createCardElement(
  card_info,
  matched_items,
  priced_items,
  card_checklist_info
) {
  logMessage(LOG_LEVELS.DEBUG, 'Creating card element', {
    cardId: card_info.reference_card.id,
  });

  const cardDiv = document.createElement('div');
  cardDiv.classList.add('card');

  cardDiv.addEventListener('click', () => {
    const card_display_controller = new CardDisplayPopupController(
      card_info,
      true
    );
    logMessage(LOG_LEVELS.INFO, 'Card element clicked', {
      cardId: card_info.reference_card.id,
    });
    card_display_controller.showCardDisplayPopup(matched_items, priced_items);
  });

  const titleContentContainer = document.createElement('div');
  titleContentContainer.classList.add('title-content-container');

  const title_text =
    card_info.reference_card.players[0] +
    ' - ' +
    card_info.reference_card.year +
    ' ' +
    card_info.reference_card.program;
  const title_text2 = card_info.reference_card.release_set;
  const subtitle_text =
    card_checklist_info.length > 0
      ? card_checklist_info[0].team + ' - ' + card_checklist_info[0].position
      : '';
  const title = createTitleElement(title_text);
  const title2 = createTitleElement(title_text2);
  const subtitle = createSubtitleElement(subtitle_text);

  if (title_text2) {
    titleContentContainer.appendChild(title);
    titleContentContainer.appendChild(title2);
  } else {
    titleContentContainer.appendChild(title2);
    titleContentContainer.appendChild(title);
  }

  titleContentContainer.appendChild(subtitle);
  cardDiv.appendChild(titleContentContainer);

  const contentContainer = document.createElement('div');
  contentContainer.classList.add('card-content-container');

  // Create and append img element
  const img = createImageElement(card_info);
  contentContainer.appendChild(img);

  cardDiv.append(contentContainer);

  logMessage(
    LOG_LEVELS.INFO,
    'Card element created and event listener attached',
    { cardId: card_info.reference_card.id }
  );

  return cardDiv;
}

function isMobileDevice() {
  let check = false;
  // Patterns to match against user agent for common mobile devices
  (function (a) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(a)
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
