const LOG_LEVELS = {
  DEBUG: 1,
  INFO: 2,
  ERROR: 3,
};

// Set the current log level
let currentLogLevel = LOG_LEVELS.DEBUG;

// Helper function to log messages
function logMessage(level, message, data = {}) {
  if (level >= currentLogLevel) {
    const formattedMessage = `[${new Date().toISOString()}] ${message} ${JSON.stringify(data)}`;
    switch (level) {
      case LOG_LEVELS.DEBUG:
        console.debug(formattedMessage);
        break;
      case LOG_LEVELS.INFO:
        console.info(formattedMessage);
        break;
      case LOG_LEVELS.ERROR:
        console.error(formattedMessage);
        break;
      default:
        console.log(formattedMessage);
    }
  }
}

export { LOG_LEVELS, logMessage };
