import { TwoImagePanelWithForm } from '../../ui_components/popups/two_image_panels.js';
import { AcceptButton, RejectButton } from '../../ui_components/buttons.js';
import { editCard, checkChecklist } from '../../data/update_operations.js';

import stateManager from '../../data/state_manager.js';
import { LOG_LEVELS, logMessage } from '../../logging.js';
import { ImageModel } from '../../ui_models/two_image_container_model.js';
import { RescanPopupController } from './popup_menu_controllers.js';
import { extractIdFromUrl } from '../../data/update_operations.js';
import { FixCornersController } from './fix_corners_controller';
import { RescanButton } from '../../ui_components/buttons.js';
import { PopupLabel } from '../../ui_components/labels.js';
import {
  selectValueTransformer,
  center_fields,
  left_column_fields,
  right_column_fields_nopulse,
  parallel_select,
  release_set_select,
  responseToFormFieldMapping,
} from './form_constants.js';

class EditFormPopupController {
  constructor(card_info, headers) {
    this.parentImageModel = new ImageModel();
    this.leftImageModel = new ImageModel();
    this.rightImageModel = new ImageModel();
    this.closeButton = new RejectButton("Don't Save");
    this.acceptButton = new AcceptButton('Save');
    this.card_info = card_info;
    this.headers = headers;

    this.updateFront = this.updateFront.bind(this);
    this.updateBack = this.updateBack.bind(this);
  }

  async showEditCardPopup(main_popup) {
    const popup = new TwoImagePanelWithForm(
      'edit',
      center_fields,
      left_column_fields,
      right_column_fields_nopulse
    );

    const helpContent = {
      imageSrc: 'images/back_help.jpg',
      description:
        'This is an example of the typical locations of data values on the card.',
    };

    // Add the help link to the panel
    popup.addHelpLink(helpContent);

    popup.addFixCornerButtons();
    popup.addRescanButtons();
    this.parentImageModel.setHeaders(this.headers);
    this.leftImageModel.setHeaders(this.headers);
    this.rightImageModel.setHeaders(this.headers);
    const local_url1 = await this.leftImageModel.fetchImage(
      this.card_info.user_card.front_image_url_large
    );
    const local_url2 = await this.rightImageModel.fetchImage(
      this.card_info.user_card.back_image_url_large
    );

    this.original_front_url = this.card_info.user_card.front_image_url_large;
    this.original_back_url = this.card_info.user_card.back_image_url_large;

    popup.setLeftImage(local_url1);
    popup.setRightImage(local_url2);

    logMessage(LOG_LEVELS.DEBUG, 'Showing edit card popup');

    if (
      this.card_info.reference_card['numbered_out_of'] > 0 &&
      this.card_info.user_card.number != null
    ) {
      this.card_info.reference_card['number'] =
        this.card_info.user_card.number +
        '/' +
        this.card_info.reference_card['numbered_out_of'];
    }

    let form_card = this.card_info.reference_card;
    form_card.condition = this.card_info.user_card.condition;
    popup.updateFormFields(
      form_card,
      responseToFormFieldMapping,
      selectValueTransformer
    );

    popup.addButtonToLeft(this.closeButton, () => this.handleClose(popup));
    popup.addButtonToRight(this.acceptButton, () =>
      this.handleSave(popup, this.headers)
    );

    popup.bindCloseButton(() => this.handleClose(popup));
    popup.bindFormInput(() => {
      this.enableSaveButton()});

    const front_image_url_parent =
      this.card_info.user_card.front_image_url_parent;
    const back_image_url_parent =
      this.card_info.user_card.back_image_url_parent;
    const front_coordinates = this.card_info.user_card.front_coordinates
      ? JSON.parse(this.card_info.user_card.front_coordinates)
      : null;
    const back_coordinates = this.card_info.user_card.back_coordinates
      ? JSON.parse(this.card_info.user_card.back_coordinates)
      : null;
    const front_rotation = this.card_info.user_card.front_rotation
      ? JSON.parse(this.card_info.user_card.front_rotation)
      : 0;
    const back_rotation = this.card_info.user_card.back_rotation
      ? JSON.parse(this.card_info.user_card.back_rotation)
      : 0;

    this.original_front_coordinates = front_coordinates;
    this.original_back_coordinates = back_coordinates;

    if (front_coordinates) {
      stateManager.updateState({ currentFrontCoords: front_coordinates });
    }
    if (back_coordinates) {
      stateManager.updateState({ currentBackCoords: back_coordinates });
    }
    stateManager.updateState({
      backRotation: back_rotation,
      frontRotation: front_rotation,
    });

    popup.bindLeftFixCorners(() => this.launchFrontFixCorners(this.headers));
    popup.bindRightFixCorners(() => this.launchBackFixCorners(this.headers));
    popup.bindLeftRescan(() => this.launchFrontRescan(this.headers));
    popup.bindRightRescan(() => this.launchBackRescan(this.headers));

    this.disableSaveButton();

    if (!front_image_url_parent || !front_coordinates) {
      popup.disableLeftFixCorners();
    }

    if (!back_image_url_parent || !back_coordinates) {
      popup.disableRightFixCorners();
    }

    this.setupMatchContainer(popup);

    this.popup = popup;
    this.main_popup = main_popup;

    await this.initChecklistLabel(this.headers, this.card_info.reference_card);

    logMessage(LOG_LEVELS.INFO, 'Edit card popup shown');
  }

  setupMatchContainer(popup) {
    if (this.matchContainer) {
      this.matchContainer.remove();
    }

    this.matchContainer = popup.createElementWithAttributes('div', {
      className: 'match-container',
      id: 'edit_match_container',
    });
    popup.insertElement(this.matchContainer, 'beforeButtonContainer');
  }

  addMatchToUI(reference_card) {
    const refresh = new RescanButton('');
    const matchLabel = new PopupLabel(
      'Checklist Match',
      'match-label',
      'fas fa-check'
    );
    this.matchContainer.append(matchLabel.instance(), refresh.instance());
    refresh
      .instance()
      .addEventListener('click', () =>
        this.handleRefresh(this.headers, reference_card)
      );
  }

  addNoMatchToUI(reference_card, message) {
    const refresh = new RescanButton('');
    const matchLabel = new PopupLabel(
      message,
      'no-match-label',
      'fas fa-times'
    );
    this.matchContainer.append(matchLabel.instance(), refresh.instance());
    refresh
      .instance()
      .addEventListener('click', () =>
        this.handleRefresh(this.headers, reference_card)
      );
  }

  updateParallelOptions(releaseSetSelect, parallelSelect, data) {
    const selectedSet = releaseSetSelect.value;
    // If no release set is selected, there may be no additional valid parallels.
    const options = selectedSet ? (data[selectedSet] || []) : [];
  
    // Determine the card sequence for filtering options
    let cardSequence = document.querySelector('[name="number"]').value;
    cardSequence = cardSequence.split('/').pop().trim();
    cardSequence = cardSequence === '' ? 0 : parseInt(cardSequence, 10);
  
    // Clear existing options in the parallel select
    parallelSelect.innerHTML = '';
  
    // Always add an empty option so the user can clear the parallel field.
    const emptyOption = document.createElement('option');
    emptyOption.value = "";
    emptyOption.textContent = "";
    parallelSelect.appendChild(emptyOption);
  
    // Only populate additional parallel options if a release set is selected.
    if (selectedSet) {
      options.forEach((option) => {
        let [parallel, parallelSequence] = option;
        if (parallelSequence == null || parallelSequence === '') {
          parallelSequence = 0;
        }
        // Only include options that match the current card sequence.
        if (cardSequence === parallelSequence) {
          // Only add non-empty parallel options, since the empty option is already present.
          if (parallel !== "") {
            const optionElement = document.createElement('option');
            optionElement.value = parallel;
            optionElement.textContent = parallel;
            parallelSelect.appendChild(optionElement);
          }
        }
      });
    }
  }
  
  
  populateReleaseSetOptions(releaseSetSelect, data) {
    // Always add an empty option so the user can clear it.
    const emptyOption = document.createElement('option');
    emptyOption.value = "";
    emptyOption.textContent = ""; // or "Clear" / "None" if you prefer a label
    releaseSetSelect.appendChild(emptyOption);
  
    // Add each non-empty release set option from the backend
    Object.keys(data).forEach((setName) => {
      if (setName !== "") {
        const optionElement = document.createElement('option');
        optionElement.value = setName;
        optionElement.textContent = setName;
        releaseSetSelect.appendChild(optionElement);
      }
    });
  }
  
  

  setupReleaseSetAndParallel(
    sets_and_parallels,
    current_set,
    current_parallel
  ) {
    const releaseSetField = document.querySelector('[name="release_set"]');
    const parallelField = document.querySelector('[name="parallel"]');

    const releaseSetFieldContainer = releaseSetField
      ? releaseSetField.parentElement
      : null;
    const parallelFieldContainer = parallelField
      ? parallelField.parentElement
      : null;

    // Check if containers exist before proceeding
    if (releaseSetFieldContainer && parallelFieldContainer) {
      // Remove existing elements
      releaseSetFieldContainer.innerHTML = '';
      parallelFieldContainer.innerHTML = '';

      // Create new field elements
      const releaseSetFieldElement = this.popup.createFormField(
        release_set_select,
        release_set_select.name
      );
      const parallelFieldElement = this.popup.createFormField(
        parallel_select,
        parallel_select.name
      );

      // Replace existing containers with new elements
      releaseSetFieldContainer.replaceWith(releaseSetFieldElement);
      parallelFieldContainer.replaceWith(parallelFieldElement);

      // Update references to the newly created select elements
      const releaseSetSelect = document.querySelector(
        'select[name="release_set"]'
      );
      const parallelSelect = document.querySelector('select[name="parallel"]');

      // Populate and update options
      this.populateReleaseSetOptions(releaseSetSelect, sets_and_parallels);
      this.updateParallelOptions(
        releaseSetSelect,
        parallelSelect,
        sets_and_parallels
      );

      // Add event listener for dynamic updates
      releaseSetSelect.addEventListener('change', () => {
        this.updateParallelOptions(
          releaseSetSelect,
          parallelSelect,
          sets_and_parallels
        );
      });

      // Set initial values
      this.setSelectedValue(releaseSetSelect, current_set);
      this.updateParallelOptions(
        releaseSetSelect,
        parallelSelect,
        sets_and_parallels
      );
      this.setSelectedValue(parallelSelect, current_parallel);
    } else {
      console.warn('Release set or parallel field container not found.');
    }
  }

  setSelectedValue(selectElement, value) {
    if (!selectElement || !value) return;

    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].value === value) {
        selectElement.selectedIndex = i;
        return;
      }
    }

    // Optionally handle the case where the value is not found
    console.warn(`Value "${value}" not found in select box.`);
  }

  async initChecklistLabel(headers, reference_card) {
    try {
      const data = this.popup.getFormData();
      const payload = {
        card_data: data,
      };
  
      // Await the response from checkChecklist
      const resp = await checkChecklist(headers, payload);
  
      if (resp.checklist_match) {
        // Remove the old label and refresh button
        stateManager.updateState({
          checklistMatch: true,
          sets_and_parallels: resp.sets_and_parallels,
        });
  
        this.setupMatchContainer(this.popup);
        this.setupReleaseSetAndParallel(
          resp.sets_and_parallels,
          reference_card['release_set'],
          reference_card['parallel']
        );
        this.addMatchToUI(reference_card);
  
        const releaseSetSelect = document.querySelector(
          'select[name="release_set"]'
        );
        const selectedSet = releaseSetSelect.value;
  
        if (
          reference_card['release_set'] != selectedSet ||
          reference_card.checklist_ids == null
        ) {
          this.enableSaveButton();
        }
      } else {
        // Remove the old label and refresh button
        stateManager.updateState({
          checklistMatch: false,
          sets_and_parallels: null,
        });
  
        this.setupMatchContainer(this.popup);
  
        if (resp.not_enough_info) {
          this.addNoMatchToUI(
            reference_card,
            'Not enough info to determine checklist match'
          );
        }
        if (resp.checklist_supported) {
          this.addNoMatchToUI(reference_card, 'No checklist match');
        } else {
          this.addNoMatchToUI(
            reference_card,
            `No Checklist available for ${reference_card['year']} ${reference_card['manufacturer']} ${reference_card['program']}`
          );
        }
      }
    } catch (error) {
      console.error('Error during checklist check:', error);
      // Handle the error, maybe display an error message to the user
    }
  }
  

  async handleRefresh(headers, reference_card) {
    this.popup.resetErrors();
    if (!this.popup.isValidInput()) {
      return;
    }
  
    try {
      const data = this.popup.getFormData();
      const payload = { card_data: data };
  
      // Await the response from checkChecklist
      const resp = await checkChecklist(headers, payload);
  
      if (resp.checklist_match) {
        // Remove the old label and refresh button
        stateManager.updateState({
          checklistMatch: true,
          sets_and_parallels: resp.sets_and_parallels,
        });
        this.setupMatchContainer(this.popup);
  
        // Use the current form values instead of the original reference card values.
        const current_set = data.release_set || "";
        const current_parallel = data.parallel || "";
        this.setupReleaseSetAndParallel(
          resp.sets_and_parallels,
          current_set,
          current_parallel
        );
        this.addMatchToUI(reference_card);
      }

      else {
        this.setupMatchContainer(this.popup);
        this.addNoMatchToUI(reference_card, 'No checklist match');
      }
    } catch (error) {
      console.error('Error during checklist check:', error);
      // Optionally handle the error (display an error message, etc.)
    }
  }
  

  async updateFront(new_card_info) {
    const new_url = new_card_info.card_front.large_image_url;

    const local_url = await this.leftImageModel.fetchImage(new_url);
    this.card_info.user_card.front_image_url_large =
      new_card_info.card_front.large_image_url;
    this.card_info.user_card.front_image_url_medium =
      new_card_info.card_front.medium_image_url;

    this.enableSaveButton();
    this.popup.setLeftImage(local_url);
  }

  async updateBack(new_card_info) {
    const new_url = new_card_info.card_front.large_image_url;

    const local_url = await this.rightImageModel.fetchImage(new_url);
    this.card_info.user_card.back_image_url_large =
      new_card_info.card_front.large_image_url;
    this.card_info.user_card.back_image_url_medium =
      new_card_info.card_front.medium_image_url;

    this.enableSaveButton();
    this.popup.setRightImage(local_url);
  }

  async replaceFront(newLocalUrl, new_card_info) {
    this.card_info = new_card_info;
    const front_coordinates = JSON.parse(
      this.card_info.user_card.front_coordinates
    );
    stateManager.updateState({ currentFrontCoords: front_coordinates });

    this.leftImageModel.updateLocalUrl(newLocalUrl);
    this.popup.setLeftImage(newLocalUrl);

    this.enableSaveButton();
    this.popup.enableLeftFixCorners();
  }

  async replaceBack(newLocalUrl, new_card_info) {
    this.card_info = new_card_info;
    const back_coordinates = JSON.parse(
      this.card_info.user_card.back_coordinates
    );
    stateManager.updateState({ currentBackCoords: back_coordinates });

    this.rightImageModel.updateLocalUrl(newLocalUrl);
    this.popup.setRightImage(newLocalUrl);

    this.enableSaveButton();
    this.popup.enableRightFixCorners();
  }

  launchFrontRescan(headers) {
    const rescan_controller = new RescanPopupController(
      'front',
      this.leftImageModel.getLocalUrl()
    );
    rescan_controller.showRescanPopup(this, this.card_info, headers);
  }

  launchBackRescan(headers) {
    const rescan_controller = new RescanPopupController(
      'back',
      this.rightImageModel.getLocalUrl()
    );
    rescan_controller.showRescanPopup(this, this.card_info, headers);
  }

  async launchFrontFixCorners(headers) {
    const parent_url = this.card_info.user_card.front_image_url_parent;

    const parent_id = extractIdFromUrl(parent_url);
    const parent_image = await this.parentImageModel.fetchImage(parent_url);

    const corner_popup = new FixCornersController(
      'front',
      parent_image,
      parent_id,
      false
    );
    corner_popup.bindCompletionCallback(this.updateFront);
    corner_popup.createAndShowPopup(headers);
  }

  async launchBackFixCorners(headers) {
    const parent_url = this.card_info.user_card.back_image_url_parent;

    const parent_id = extractIdFromUrl(parent_url);
    const parent_image = await this.parentImageModel.fetchImage(parent_url);

    const corner_popup = new FixCornersController(
      'back',
      parent_image,
      parent_id,
      false
    );
    corner_popup.bindCompletionCallback(this.updateBack);

    corner_popup.createAndShowPopup(headers);
  }

  handleClose(popup) {
    this.card_info.user_card.back_image_url_large = this.original_back_url;
    this.card_info.user_card.front_image_url_large = this.original_front_url;

    if (this.original_front_coordinates) {
      stateManager.updateState({
        currentFrontCoords: this.original_front_coordinates,
      });
    }
    if (this.original_back_coordinates) {
      stateManager.updateState({
        currentBackCoords: this.original_back_coordinates,
      });
    }

    popup.remove();
  }

  async handleSave(popup, headers) {
    popup.resetErrors();
    if (popup.isValidInput()) {
      logMessage(LOG_LEVELS.INFO, 'Edit Card popup accept button clicked.');
      const data = popup.getFormData();
      const { currentFrontCoords, currentBackCoords } = stateManager.getState();

      data.user_card_id = this.card_info.user_card.user_card_id;
      data.front_parent_image_id = extractIdFromUrl(
        this.card_info.user_card.front_image_url_parent
      );
      data.back_parent_image_id = extractIdFromUrl(
        this.card_info.user_card.back_image_url_parent
      );
      data.front_large_image_id = extractIdFromUrl(
        this.card_info.user_card.front_image_url_large
      );
      data.back_large_image_id = extractIdFromUrl(
        this.card_info.user_card.back_image_url_large
      );
      data.front_medium_image_id = extractIdFromUrl(
        this.card_info.user_card.front_image_url_medium
      );
      data.back_medium_image_id = extractIdFromUrl(
        this.card_info.user_card.back_image_url_medium
      );
      data.front_coordinates = currentFrontCoords;
      data.back_coordinates = currentBackCoords;

      this.original_back_url = this.card_info.user_card.back_image_url_large;
      this.original_front_url = this.card_info.user_card.front_image_url_large;

      this.original_front_coordinates = currentFrontCoords;
      this.original_back_coordinates = currentBackCoords;

      this.main_popup.updateFrontImage(
        this.leftImageModel.getLocalUrl(),
        this.card_info
      );
      this.main_popup.updateBackImage(
        this.rightImageModel.getLocalUrl(),
        this.card_info
      );

      const decision = await editCard(data, headers);

      if (decision) {
        // This is a bad (but clever at the time lol) hack. data is a totally different data structure from reference_card.
        // It just currently happens to have all the data (and more). And since card_info is passed in at the constructor of
        // the card display popup, I need to write over it here so the edits show back up if the user clicks on edit again
        // prior to closing out the card display.
        this.card_info.reference_card = data;
        console.log('Closing edit popup.');
        popup.remove();
        stateManager.updateState({ reinitialize: true });
      }
    }
  }

  enableSaveButton() {
    this.acceptButton.enable();
  }

  disableSaveButton() {
    this.acceptButton.disable();
  }
}

export { EditFormPopupController };
