import { PopupBase } from './base_popup.js';
import { LOG_LEVELS, logMessage } from '../../logging';

class ImageCarouselPopup extends PopupBase {
  constructor() {
    super('card-image-container-content');
    this.initArrows();
    this.popupImage = this.createElementWithAttributes('img', {
      className: 'carousel-image',
      id: 'popupImage',
      style: 'display:block;',
    });
    this.dots = this.createElementWithAttributes('span', {
      className: 'carousel-indicators',
    });
    this.popup = this.createPopup();
  }

  initArrows() {
    this.leftArrow = this.setupArrow('left', '&#10094;');
    this.rightArrow = this.setupArrow('right', '&#10095;');
  }

  setupArrow(direction, symbol) {
    return this.createElementWithAttributes('button', {
      className: `carousel-arrow ${direction}-arrow`,
      innerHTML: symbol,
    });
  }

  createPopup() {
    logMessage(LOG_LEVELS.DEBUG, 'Creating popup', {});
    const popup = this.basePopup();
    this.assembleCarousel();
    logMessage(LOG_LEVELS.INFO, 'Popup created and displayed', {});
    return popup;
  }

  assembleCarousel() {
    const imageCarousel = this.createElementWithAttributes('div', {
      className: 'carousel',
      id: 'imageCarousel',
    });

    imageCarousel.append(this.popupImage);

    const imageContainer = this.createElementWithAttributes('div', {
      className: 'single-image-container',
    });
    const dotsContainer = this.createElementWithAttributes('div', {
      className: 'carousel-indicators-container',
    });
    dotsContainer.append(this.dots);

    imageContainer.append(imageCarousel);
    imageContainer.append(dotsContainer);
    this.content.append(imageContainer);

    this.popup.insertBefore(this.leftArrow, this.content);
    this.popup.appendChild(this.rightArrow);
  }

  addDots(numDots) {
    for (let i = 0; i < numDots; i++) {
      const dot = document.createElement('span');
      this.dots.append(dot);
    }
  }

  hideDots() {
    this.dots.style.display = 'none';
  }

  setActiveDot(index) {
    Array.from(this.dots.children).forEach((dot) =>
      dot.classList.remove('active')
    );
    this.dots.children[index].classList.add('active');
  }

  appendMenu(menu) {
    this.popup.append(menu);
  }

  hideArrows() {
    this.toggleArrowVisibility(false);
  }

  showArrows() {
    this.toggleArrowVisibility(true);
  }

  toggleArrowVisibility(visible) {
    const display = visible ? 'block' : 'none';
    this.leftArrow.style.display = display;
    this.rightArrow.style.display = display;
  }

  enableArrow(arrow, enable) {
    arrow.disabled = !enable;
    arrow.style.opacity = enable ? '1' : '0.25';
  }

  disableLeftArrow() {
    this.enableArrow(this.leftArrow, false);
  }

  enableLeftArrow() {
    this.enableArrow(this.leftArrow, true);
  }

  disableRightArrow() {
    this.enableArrow(this.rightArrow, false);
  }

  enableRightArrow() {
    this.enableArrow(this.rightArrow, true);
  }

  updateImage(imageUrl) {
    this.popupImage.src = imageUrl;
  }

  // Bind event handlers for the navigation buttons
  bindNextButton(handler) {
    this.rightArrow.addEventListener('click', handler);
  }

  bindPrevButton(handler) {
    this.leftArrow.addEventListener('click', handler);
  }
}

export { ImageCarouselPopup };
