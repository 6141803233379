import { LOG_LEVELS, logMessage } from '../logging';

const loadingMessages = [
  'Loading...',
  'Verifying card details...',
  'Loading card database...',
  'Detecting card signatures...',
];

let currentMessageIndex = 0;
let messageUpdateInterval;

function updateLoadingMessage() {
  const loadingMessageElement = document.querySelector('.loading-text');
  if (loadingMessageElement) {
    // Ensure the element exists
    loadingMessageElement.textContent = loadingMessages[currentMessageIndex];
    if (currentMessageIndex < loadingMessages.length - 1) {
      currentMessageIndex++;
    } else {
      clearInterval(messageUpdateInterval); // Stop updating after the last message
    }
  }
}

function showOverlay() {
  logMessage(LOG_LEVELS.INFO, 'Showing overlay');
  const overlayElement = document.getElementById('loadingOverlay');
  if (overlayElement) {
    overlayElement.style.display = 'flex'; // Now using flex display
    updateLoadingMessage(); // Update immediately to show the first message
    messageUpdateInterval = setInterval(updateLoadingMessage, 6000); // Then update every second
  } else {
    logMessage(LOG_LEVELS.WARN, 'Loading overlay element not found');
  }
}

function hideOverlay() {
  logMessage(LOG_LEVELS.INFO, 'Hiding overlay');
  const overlayElement = document.getElementById('loadingOverlay');
  if (overlayElement) {
    overlayElement.style.display = 'none';
    currentMessageIndex = 0;
    clearInterval(messageUpdateInterval); // Stop updating the message
  } else {
    logMessage(LOG_LEVELS.WARN, 'Loading overlay element not found');
  }
}

export { showOverlay, hideOverlay };
