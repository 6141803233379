// VouchModal.js
import config from '../config';

export function initVouchModal() {
  // === DOM Elements ===
  const modal = document.getElementById('vouchModal');
  const closeBtn = document.getElementById('closeVouchModal');
  const form = document.getElementById('vouchForm');
  const buyerIdentifierInput = document.getElementById('buyerIdentifier');
  const buyerIdentifierErrorMsg = document.getElementById(
    'buyerIdentifierErrorMsg'
  );
  const trackingNumberInput = document.getElementById('trackingNumber');
  const trackingNumberErrorMsg = document.getElementById(
    'trackingNumberErrorMsg'
  );

  // === Helper: Get Authentication Token ===
  const getToken = () => window.userToken;

  // === Helper: Email Validation ===
  function isEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  // === Modal Open/Close Functions ===
  function open() {
    modal.style.display = 'flex';
  }

  function close() {
    modal.style.display = 'none';
  }

  function setupCloseOnOutsideClick() {
    window.addEventListener('click', (event) => {
      if (event.target === modal) {
        close();
      }
    });
  }

  // === Username Existence Check (for non-email identifiers) ===
  async function checkUsernameExists(username) {
    const token = getToken();
    if (!token) return false;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await fetch(`${config.detectionUrl}/check-username`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username }),
      });
      const result = await response.json();
      return result.exists;
    } catch (error) {
      console.error('Error checking username existence:', error);
      return false;
    }
  }

  // === Handle Buyer Identifier Blur ===
  async function handleBuyerIdentifierBlur() {
    const identifier = buyerIdentifierInput.value.trim();
    if (!identifier) {
      buyerIdentifierErrorMsg.textContent = 'Buyer identifier is required.';
      return;
    }

    if (isEmail(identifier)) {
      buyerIdentifierErrorMsg.textContent = '';
    } else {
      const exists = await checkUsernameExists(identifier);
      buyerIdentifierErrorMsg.textContent = exists
        ? ''
        : 'Username does not exist.';
    }
  }

  // === Tracking Number Validation ===
  function handleTrackingNumberBlur() {
    let trackingNumber = trackingNumberInput.value.replace(/\s+/g, '');
    trackingNumberInput.value = trackingNumber; // Remove spaces from input field
    
    const trackingRegex = /^[A-Za-z0-9\-]{10,}$/;

    if (!trackingNumber) {
      trackingNumberErrorMsg.textContent = 'Tracking number is required.';
      return;
    }

    trackingNumberErrorMsg.textContent = trackingRegex.test(trackingNumber)
      ? ''
      : 'Tracking number is not formatted correctly.';
  }

  // === Form Submission ===
  async function handleFormSubmit(e) {
    e.preventDefault();

    const buyerIdentifier = buyerIdentifierInput.value.trim();
    const trackingNumber = trackingNumberInput.value.replace(/\s+/g, ''); // Remove spaces before submission

    const token = getToken();
    if (!token) {
      alert('User is not authenticated.');
      return;
    }

    if (
      buyerIdentifierErrorMsg.textContent ||
      trackingNumberErrorMsg.textContent
    ) {
      alert('Please correct the errors before submitting.');
      return;
    }

    const identifierType = isEmail(buyerIdentifier) ? 'email' : 'username';

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const payload = {
      buyerIdentifier,
      identifierType,
      trackingNumber,
    };

    try {
      const response = await fetch(`${config.detectionUrl}/initiate-vouch`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      if (result.success) {
        alert('Vouch request submitted successfully.');
        close();
        form.reset();
      } else {
        alert('Error: ' + (result.error || 'Unable to submit vouch request.'));
      }
    } catch (error) {
      console.error('Error submitting vouch request:', error);
      alert('An error occurred while submitting your vouch request.');
    }
  }

  // === Setup Event Listeners ===
  function setupEventListeners() {
    closeBtn.addEventListener('click', close);
    setupCloseOnOutsideClick();
    buyerIdentifierInput.addEventListener('blur', handleBuyerIdentifierBlur);
    trackingNumberInput.addEventListener('blur', handleTrackingNumberBlur);
    form.addEventListener('submit', handleFormSubmit);
  }

  setupEventListeners();

  return { open };
}
