import { PopupBase } from './base_popup.js';
import { LOG_LEVELS, logMessage } from '../../logging.js';
import stateManager from '../../data/state_manager.js';
import { PopupButton, RescanButton, FixCornersButton } from '../buttons.js';

class SingleImagePanelBase extends PopupBase {
  constructor() {
    super();
    this.popupImage = this.createElementWithAttributes('img', {
      className: 'popup-image',
      id: 'popupImage',
    });
    this.buttonContainer = this.createElementWithAttributes('div', {
      className: 'button-container',
    });
    this.leftButtonContainer = this.createElementWithAttributes('div', {
      className: 'split-button-container',
    });
    this.rightButtonContainer = this.createElementWithAttributes('div', {
      className: 'split-button-container',
    });
    this.imageContainer = this.createElementWithAttributes('div', {
      className: 'image-container-overflow',
    });

    this.popup = this.createPopup();
    this.single_button = false;
  }

  createPopup() {
    const popup = this.basePopup();

    this.imageContainer.append(this.popupImage);

    // Create the panel
    this.panel = this.createElementWithAttributes('div', {
      className: 'popup-panel',
    });
    this.panel.append(this.imageContainer);

    this.buttonContainer.append(this.leftButtonContainer);
    if (!this.single_button) {
      this.buttonContainer.append(this.rightButtonContainer);
    } else {
      this.leftButtonContainer.style.width = '100%';
    }

    this.panel.append(this.buttonContainer);

    this.popup.append(this.panel);

    return popup;
  }

  setImage(localUrl) {
    this.popupImage.src = localUrl;
  }

  addButtonToLeft(button, clickHandler) {
    if (!(button instanceof PopupButton)) {
      throw new TypeError('Argument must be an instance of PopupButton');
    }

    this.leftButtonContainer.append(button.instance());
    button.instance().addEventListener('click', clickHandler);
  }

  addButtonToRight(button, clickHandler) {
    if (!(button instanceof PopupButton)) {
      throw new TypeError('Argument must be an instance of PopupButton');
    }

    this.rightButtonContainer.append(button.instance());
    button.instance().addEventListener('click', clickHandler);
  }
}

class SingleImagePanelWithCornerOverlay extends SingleImagePanelBase {
  constructor(coordinates, rotation) {
    super();
    this.topLeftCorner = this.createElementWithAttributes('div', {
      className: 'corner',
      id: 'corner-top-left',
      innerHTML: '',
    });
    this.topRightCorner = this.createElementWithAttributes('div', {
      className: 'corner',
      id: 'corner-top-right',
    });
    this.bottomLeftCorner = this.createElementWithAttributes('div', {
      className: 'corner',
      id: 'corner-bottom-left',
    });
    this.bottomRightCorner = this.createElementWithAttributes('div', {
      className: 'corner',
      id: 'corner-bottom-right',
    });
    this.overlay = this.createElementWithAttributes('svg', {
      className: 'corner-overlay',
      id: 'corner-overlay',
    });

    this.overlay = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    this.overlay.setAttribute('class', 'corner-overlay');

    this.coordinates = coordinates;
    this.rotation = rotation;
    this.rotationDegrees = 0;

    this.imageContainer.append(this.topLeftCorner);
    this.imageContainer.append(this.topRightCorner);
    this.imageContainer.append(this.bottomLeftCorner);
    this.imageContainer.append(this.bottomRightCorner);
    this.imageContainer.append(this.overlay);

    this.currentDraggedCorner = null;
    this.offsetX = 0;
    this.offsetY = 0;
    this.cornerPositions = {};

    // Bind the methods to ensure 'this' refers to the class instance
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.setCorners = this.setCorners.bind(this);
    this.adjustContainerSize = this.adjustContainerSize.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.disableScrolling = this.disableScrolling.bind(this);
    this.enableScrolling = this.enableScrolling.bind(this);

    this.setupListeners();
  }

  getDefaultCorners() {
    const boxWidth = this.popupImage.naturalWidth / 2;
    const boxHeight = this.popupImage.naturalHeight / 2;

    const topLeftX = (this.popupImage.naturalWidth - boxWidth) / 2;
    const topLeftY = (this.popupImage.naturalHeight - boxHeight) / 2;
    const topRightX = topLeftX + boxWidth;
    const bottomLeftY = topLeftY + boxHeight;

    return [
      [topLeftX, topLeftY], // Top-left corner
      [topRightX, topLeftY], // Top-right corner
      [topLeftX, bottomLeftY], // Bottom-left corner
      [topRightX, bottomLeftY], // Bottom-right corner
    ];
  }

  setupListeners() {
    this.topLeftCorner.addEventListener('mousedown', this.onMouseDown);
    this.topRightCorner.addEventListener('mousedown', this.onMouseDown);
    this.bottomLeftCorner.addEventListener('mousedown', this.onMouseDown);
    this.bottomRightCorner.addEventListener('mousedown', this.onMouseDown);
    this.topLeftCorner.addEventListener('touchstart', this.onTouchStart);
    this.topRightCorner.addEventListener('touchstart', this.onTouchStart);
    this.bottomLeftCorner.addEventListener('touchstart', this.onTouchStart);
    this.bottomRightCorner.addEventListener('touchstart', this.onTouchStart);

    window.addEventListener('resize', this.handleResize);
    this.imageContainer.addEventListener('scroll', this.handleScroll);
    this.popupImage.addEventListener('contextmenu', this.preventDefault);

    this.popupImage.onload = this.setCorners;
  }

  handleResize() {
    this.adjustContainerSize();
    this.updateCornerPositions(true);
    this.updateOverlay(true);
  }

  handleScroll() {
    this.updateOverlay();
  }

  getContainerOffset() {
    let containerOffsetX, containerOffsetY;

    if (this.rotation % 180 === 0) {
      containerOffsetX =
        (this.imageContainer.clientWidth - this.popupImage.clientWidth) / 2;
      containerOffsetY = 0;
    } else {
      containerOffsetX =
        (this.imageContainer.clientWidth - this.popupImage.clientHeight) / 2;
      containerOffsetY =
        (this.imageContainer.clientHeight - this.popupImage.clientWidth) / 2;
    }

    return [containerOffsetX, containerOffsetY];
  }

  setCorners() {
    if (!this.coordinates) {
      this.coordinates = this.getDefaultCorners();
    }

    this.rotateImage();
    this.adjustContainerSize();

    const [topLeft, topRight, bottomLeft, bottomRight] = this.coordinates;

    const cornerOffset = this.topLeftCorner.clientWidth / 2;
    const [containerOffsetX, containerOffsetY] = this.getContainerOffset();

    const scaleX = this.scaleX();
    const scaleY = this.scaleY();

    const topLeftX = topLeft[0] * scaleX + containerOffsetX;
    const topLeftY = topLeft[1] * scaleY + containerOffsetY;
    const topRightX = topRight[0] * scaleX + containerOffsetX;
    const topRightY = topRight[1] * scaleY + containerOffsetY;
    const bottomLeftX = bottomLeft[0] * scaleX + containerOffsetX;
    const bottomLeftY = bottomLeft[1] * scaleY + containerOffsetY;
    const bottomRightX = bottomRight[0] * scaleX + containerOffsetX;
    const bottomRightY = bottomRight[1] * scaleY + containerOffsetY;

    this.topLeftCorner.style.left = `${topLeftX - cornerOffset}px`;
    this.topLeftCorner.style.top = `${topLeftY - cornerOffset}px`;
    this.topRightCorner.style.left = `${topRightX - cornerOffset}px`;
    this.topRightCorner.style.top = `${topRightY - cornerOffset}px`;
    this.bottomLeftCorner.style.left = `${bottomLeftX - cornerOffset}px`;
    this.bottomLeftCorner.style.top = `${bottomLeftY - cornerOffset}px`;
    this.bottomRightCorner.style.left = `${bottomRightX - cornerOffset}px`;
    this.bottomRightCorner.style.top = `${bottomRightY - cornerOffset}px`;

    this.updateOverlay();
    this.storeCornerPositions();
  }

  storeCornerPositions() {
    const cornerOffset = this.topLeftCorner.clientWidth / 2;
    const [containerOffsetX, containerOffsetY] = this.getContainerOffset();

    this.cornerPositions = {
      'corner-top-left': {
        left:
          ((parseFloat(this.topLeftCorner.style.left) +
            cornerOffset -
            containerOffsetX) /
            this.popupImage.clientWidth) *
          100,
        top:
          ((parseFloat(this.topLeftCorner.style.top) +
            cornerOffset -
            containerOffsetY) /
            this.popupImage.clientHeight) *
          100,
      },
      'corner-top-right': {
        left:
          ((parseFloat(this.topRightCorner.style.left) +
            cornerOffset -
            containerOffsetX) /
            this.popupImage.clientWidth) *
          100,
        top:
          ((parseFloat(this.topRightCorner.style.top) +
            cornerOffset -
            containerOffsetY) /
            this.popupImage.clientHeight) *
          100,
      },
      'corner-bottom-left': {
        left:
          ((parseFloat(this.bottomLeftCorner.style.left) +
            cornerOffset -
            containerOffsetX) /
            this.popupImage.clientWidth) *
          100,
        top:
          ((parseFloat(this.bottomLeftCorner.style.top) +
            cornerOffset -
            containerOffsetY) /
            this.popupImage.clientHeight) *
          100,
      },
      'corner-bottom-right': {
        left:
          ((parseFloat(this.bottomRightCorner.style.left) +
            cornerOffset -
            containerOffsetX) /
            this.popupImage.clientWidth) *
          100,
        top:
          ((parseFloat(this.bottomRightCorner.style.top) +
            cornerOffset -
            containerOffsetY) /
            this.popupImage.clientHeight) *
          100,
      },
    };
  }

  updateCornerPositions(reset = false) {
    const cornerOffset = this.topLeftCorner.clientWidth / 2;
    const [containerOffsetX, containerOffsetY] = this.getContainerOffset();

    let scrollX, scrollY;

    if (reset) {
      scrollX = 0;
      scrollY = 0;
    } else {
      scrollX = this.imageContainer.scrollLeft;
      scrollY = this.imageContainer.scrollTop;
    }

    for (let cornerId in this.cornerPositions) {
      const position = this.cornerPositions[cornerId];
      const cornerElement = document.getElementById(cornerId);
      cornerElement.style.left = `${(position.left / 100) * this.popupImage.clientWidth - cornerOffset + containerOffsetX - scrollX}px`;
      cornerElement.style.top = `${(position.top / 100) * this.popupImage.clientHeight - cornerOffset + containerOffsetY - scrollY}px`;
    }
  }

  getCorners() {
    const cornerOffset = this.topLeftCorner.clientWidth / 2;
    const [containerOffsetX, containerOffsetY] = this.getContainerOffset();

    const scaleX = this.scaleX();
    const scaleY = this.scaleY();

    const topLeftX =
      parseFloat(this.topLeftCorner.style.left) +
      cornerOffset -
      containerOffsetX;
    const topLeftY =
      parseFloat(this.topLeftCorner.style.top) +
      cornerOffset -
      containerOffsetY;
    const topRightX =
      parseFloat(this.topRightCorner.style.left) +
      cornerOffset -
      containerOffsetX;
    const topRightY =
      parseFloat(this.topRightCorner.style.top) +
      cornerOffset -
      containerOffsetY;
    const bottomLeftX =
      parseFloat(this.bottomLeftCorner.style.left) +
      cornerOffset -
      containerOffsetX;
    const bottomLeftY =
      parseFloat(this.bottomLeftCorner.style.top) +
      cornerOffset -
      containerOffsetY;
    const bottomRightX =
      parseFloat(this.bottomRightCorner.style.left) +
      cornerOffset -
      containerOffsetX;
    const bottomRightY =
      parseFloat(this.bottomRightCorner.style.top) +
      cornerOffset -
      containerOffsetY;

    const topLeftCoords = [topLeftX / scaleX, topLeftY / scaleY];
    const topRightCords = [topRightX / scaleX, topRightY / scaleY];
    const bottomLeftCoords = [bottomLeftX / scaleX, bottomLeftY / scaleY];
    const bottomRightCoords = [bottomRightX / scaleX, bottomRightY / scaleY];

    return [topLeftCoords, topRightCords, bottomLeftCoords, bottomRightCoords];
  }

  updateOverlay(reset = false) {
    const cornerOffset = this.topLeftCorner.clientWidth / 2;

    let scrollX, scrollY;

    if (reset) {
      scrollX = 0;
      scrollY = 0;
    } else {
      scrollX = this.imageContainer.scrollLeft;
      scrollY = this.imageContainer.scrollTop;
    }

    const topLeftX = parseFloat(this.topLeftCorner.style.left) + cornerOffset;
    const topLeftY = parseFloat(this.topLeftCorner.style.top) + cornerOffset;
    const topRightX = parseFloat(this.topRightCorner.style.left) + cornerOffset;
    const topRightY = parseFloat(this.topRightCorner.style.top) + cornerOffset;
    const bottomLeftX =
      parseFloat(this.bottomLeftCorner.style.left) + cornerOffset;
    const bottomLeftY =
      parseFloat(this.bottomLeftCorner.style.top) + cornerOffset;
    const bottomRightX =
      parseFloat(this.bottomRightCorner.style.left) + cornerOffset;
    const bottomRightY =
      parseFloat(this.bottomRightCorner.style.top) + cornerOffset;

    const points = `${topLeftX},${topLeftY} ${topRightX},${topRightY} ${bottomRightX},${bottomRightY} ${bottomLeftX},${bottomLeftY}`;
    this.overlay.setAttribute(
      'width',
      this.imageContainer.clientWidth - cornerOffset + scrollX
    );
    this.overlay.setAttribute(
      'height',
      this.imageContainer.clientHeight - cornerOffset + scrollY
    );
    this.overlay.setAttribute(
      'viewBox',
      `0 0 ${this.imageContainer.clientWidth - cornerOffset + scrollX} ${this.imageContainer.clientHeight - cornerOffset + scrollY}`
    );
    this.overlay.innerHTML = `<polygon points="${points}" style="fill: rgba(0, 0, 255, 0.3); stroke: blue; stroke-width: 1;" />`;
  }

  rotateImage() {
    if (this.rotation != 0) {
      this.popupImage.style.transform = `rotate(${this.rotation}deg)`;
    }
  }

  adjustContainerSize() {
    if (this.rotation != 0) {
      const radians = this.rotation * (Math.PI / 180);
      const width = this.popupImage.naturalWidth;
      const height = this.popupImage.naturalHeight;

      // Calculate the new container size
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));
      const newWidth = width * cos + height * sin;
      const newHeight = width * sin + height * cos;

      // Calculate the maximum width based on the viewport width
      const maxContainerWidth = this.panel.clientWidth;
      const maxContainerHeight = this.panel.clientHeight;

      // Adjust the image size if it exceeds the viewport width
      let scaleFactor = 1;
      if (newWidth > maxContainerWidth || newHeight > maxContainerHeight) {
        scaleFactor = Math.min(
          maxContainerWidth / newWidth,
          maxContainerHeight / newHeight
        );
      }

      const scaledNewWidth = newWidth * scaleFactor;
      const scaledNewHeight = newHeight * scaleFactor;

      this.popupImage.style.width = `${scaledNewHeight}px`;
      this.popupImage.style.height = `${scaledNewWidth}px`;
    } else {
      const width = this.popupImage.naturalWidth;
      const height = this.popupImage.naturalHeight;

      // Calculate the maximum width based on the viewport width
      const maxContainerWidth = this.panel.clientWidth;
      const maxContainerHeight = this.panel.clientHeight;

      // Adjust the image size if it exceeds the viewport width
      let scaleFactor = 1;
      if (width > maxContainerWidth || height > maxContainerHeight) {
        scaleFactor = Math.min(
          maxContainerWidth / width,
          maxContainerHeight / height
        );
      }

      const scaledNewWidth = width * scaleFactor;
      const scaledNewHeight = height * scaleFactor;

      this.popupImage.style.width = `${scaledNewWidth}px`;
      this.popupImage.style.height = `${scaledNewHeight}px`;
    }
  }

  scaleX() {
    return this.popupImage.clientWidth / this.popupImage.naturalWidth;
  }

  scaleY() {
    return this.popupImage.clientHeight / this.popupImage.naturalHeight;
  }

  disableScrolling() {
    window.addEventListener('wheel', this.preventDefault, { passive: false });
    window.addEventListener('touchmove', this.preventDefault, {
      passive: false,
    });
    window.addEventListener('scroll', this.preventDefault, { passive: false });
  }

  enableScrolling() {
    window.removeEventListener('wheel', this.preventDefault, {
      passive: false,
    });
    window.removeEventListener('touchmove', this.preventDefault, {
      passive: false,
    });
    window.removeEventListener('scroll', this.preventDefault, {
      passive: false,
    });
  }

  onMouseDown(event) {
    this.disableScrolling();
    this.startDragging(event.target, event.clientX, event.clientY);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  onTouchStart(event) {
    event.preventDefault();
    this.disableScrolling();
    const touch = event.touches[0];
    this.startDragging(event.target, touch.clientX, touch.clientY);
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
  }

  startDragging(target, clientX, clientY) {
    this.currentDraggedCorner = target;
    const containerRect = this.imageContainer.getBoundingClientRect();
    this.offsetX = clientX;
    this.offsetY = clientY;
    this.startX = clientX - containerRect.left + this.imageContainer.scrollLeft;
    this.startY = clientY - containerRect.top + this.imageContainer.scrollTop;
  }

  onMouseMove(event) {
    this.dragCorner(event.clientX, event.clientY);
  }

  onTouchMove(event) {
    // event.preventDefault();
    const touch = event.touches[0];
    this.dragCorner(touch.clientX, touch.clientY);
  }

  dragCorner(clientX, clientY) {
    if (this.currentDraggedCorner) {
      const newLeft =
        this.startX +
        (clientX - this.offsetX - this.currentDraggedCorner.clientWidth / 2);
      const newTop =
        this.startY +
        (clientY - this.offsetY - this.currentDraggedCorner.clientHeight / 2);
      this.currentDraggedCorner.style.left = `${newLeft}px`;
      this.currentDraggedCorner.style.top = `${newTop}px`;

      this.updateOverlay();
    }
  }

  onMouseUp() {
    this.enableScrolling();
    this.stopDragging();
  }

  onTouchEnd() {
    this.enableScrolling();
    this.stopDragging();
  }

  stopDragging() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
    this.currentDraggedCorner = null;
    this.storeCornerPositions();
  }

  preventDefault(event) {
    event.preventDefault();
  }
}

class TwoImagePanelBase extends PopupBase {
  constructor(id_prefix, lower_container_style, single_button = false) {
    super(id_prefix);
    this.popupImage1 = this.createElementWithAttributes('img', {
      className: 'popup-image',
      id: id_prefix + '_popupImage1',
    });
    this.popupImage2 = this.createElementWithAttributes('img', {
      className: 'popup-image',
      id: id_prefix + '_popupImage2',
    });
    this.lowerContainer = this.createElementWithAttributes('div', {
      className: lower_container_style,
      id: id_prefix + '_form_container',
    });
    this.buttonContainer = this.createElementWithAttributes('div', {
      className: 'button-container',
      id: id_prefix + '_button_container',
    });
    this.leftButtonContainer = this.createElementWithAttributes('div', {
      className: 'split-button-container',
      id: id_prefix + '_left_button_container',
    });
    this.rightButtonContainer = this.createElementWithAttributes('div', {
      className: 'split-button-container',
      id: id_prefix + '_right_button_container',
    });
    this.single_button = single_button;

    this.leftFigure = this.createElementWithAttributes('figure', {
      className: 'figure',
      id: id_prefix + '_left_figure',
    });
    this.rightFigure = this.createElementWithAttributes('figure', {
      className: 'figure',
      id: id_prefix + '_right_figure',
    });

    this.id_prefix = id_prefix;
    this.popup = this.createPopup();
  }

  createPopup() {
    const popup = this.basePopup();

    // Create image container and append images
    const imageContainer = this.createElementWithAttributes('div', {
      className: 'image-container',
      id: this.id_prefix + '_image_container',
    });

    this.leftFigure.append(this.popupImage1);
    this.rightFigure.append(this.popupImage2);

    imageContainer.append(this.leftFigure, this.rightFigure);

    // Create the panel
    this.panel = this.createElementWithAttributes('div', {
      className: 'popup-panel',
      id: this.id_prefix + '_popup_panel',
    });
    this.panel.append(imageContainer);
    this.panel.append(this.lowerContainer);

    // Append left button container, label, and right button container
    this.buttonContainer.append(this.leftButtonContainer);
    if (!this.single_button) {
      this.buttonContainer.append(this.rightButtonContainer);
    } else {
      this.leftButtonContainer.style.width = '100%';
    }

    this.panel.append(this.buttonContainer);
    this.popup.append(this.panel);

    return popup;
  }

  // Set Left Image and return a Promise that resolves when loaded
  setLeftImage(localUrl) {
    this.popupImage1.src = localUrl;
  }

  setRightImage(localUrl) {
    this.popupImage2.src = localUrl;
  }

  setLeftCaption(caption) {
    const leftFigureCaption = this.createElementWithAttributes('figcaption', {
      innerHTML: caption,
    });
    this.leftFigure.append(leftFigureCaption);
  }

  setRightCaption(caption) {
    const rightFigureCaption = this.createElementWithAttributes('figcaption', {
      innerHTML: caption,
    });
    this.rightFigure.append(rightFigureCaption);
  }

  bindNextButton(handler) {
    this.rightArrow.addEventListener('click', handler);
  }

  addButtonToLeft(button, clickHandler) {
    if (!(button instanceof PopupButton)) {
      throw new TypeError('Argument must be an instance of PopupButton');
    }

    this.leftButtonContainer.append(button.instance());
    button.instance().addEventListener('click', clickHandler);
  }

  addButtonToRight(button, clickHandler) {
    if (!(button instanceof PopupButton)) {
      throw new TypeError('Argument must be an instance of PopupButton');
    }

    this.rightButtonContainer.append(button.instance());
    button.instance().addEventListener('click', clickHandler);
  }

  // New method to insert a container or element at a specific position
  insertElement(element, position = 'end') {
    const targetContainer = this.popup.querySelector('.popup-panel');

    switch (position) {
      case 'beforeImages':
        targetContainer.insertBefore(element, targetContainer.firstChild);
        break;
      case 'beforeLowerContainer':
        targetContainer.insertBefore(element, this.lowerContainer);
        break;
      case 'beforeButtonContainer':
        targetContainer.insertBefore(element, this.buttonContainer);
        break;
      default: // 'end'
        targetContainer.append(element);
        break;
    }
  }
}

class TwoImagePanelEbay extends TwoImagePanelBase {
  constructor(id_prefix, defaultTab, single_button = false) {
    super(id_prefix, 'ebay-items-container', single_button);
    this.tabsInitialized = false;
    this.createTabs(this.lowerContainer, defaultTab);
  }

  populateMonitoredListings(items) {
    const monitoredContainer = document.getElementById('MonitoredListings');
    if (monitoredContainer) {
      this.clearContainer(monitoredContainer);
      this.populateEbayItemsList(monitoredContainer, items);
    }
  }

  populateCompletedListings(items) {
    const completedContainer = document.getElementById('PricedListings');
    if (completedContainer) {
      this.clearContainer(completedContainer);
      this.populateEbayItemsList(completedContainer, items);
    }
  }

  clearContainer(container) {
    container.innerHTML = ''; // Clear previous content
  }

  populateEbayItemsList(container, items) {
    logMessage(LOG_LEVELS.DEBUG, 'Populating eBay items list', {
      itemsCount: items.length,
    });
    if (items.length === 0) {
      // Display a "No transactions found" message
      const noDataMsg = document.createElement('div');
      noDataMsg.className = 'no-data-message';
      noDataMsg.textContent = 'No transactions found';
      container.appendChild(noDataMsg);
    } else {
      items.forEach((item) => {
        const itemElement = document.createElement('div');
        itemElement.className = 'ebay-item';
        itemElement.innerHTML = `
            <a href="${item.url}" target="_blank" rel="noopener noreferrer">${item.title}</a>
          `;
        container.appendChild(itemElement);
      });
    }

    logMessage(LOG_LEVELS.INFO, 'eBay items list populated');
  }

  createTabs(itemsListElement, defaultTab) {
    if (this.tabsInitialized) return; // Prevent duplicate tab creation

    logMessage(LOG_LEVELS.DEBUG, 'Creating tabs', { defaultTab });
    // Create the tab links container
    const tabContainer = document.createElement('div');
    tabContainer.className = 'tab';

    // Create Price Listings tab
    const pricedTab = this.createTabButton(
      'Completed Transactions',
      'PricedListings'
    );
    // const monitoredTab = this.createTabButton('Monitored Listings', 'MonitoredListings');

    // Append tab buttons to the tab container
    tabContainer.appendChild(pricedTab);
    // tabContainer.appendChild(monitoredTab);

    if (itemsListElement?.parentNode) {
      itemsListElement.parentNode.insertBefore(tabContainer, itemsListElement);
    }

    // Create the tab content containers
    // const monitoredContent = this.createElementWithAttributes('div', { id: 'MonitoredListings', className: 'tabcontent' });
    const pricedContent = this.createElementWithAttributes('div', {
      id: 'PricedListings',
      className: 'tabcontent',
    });

    // Append tab content containers inside the items_list element
    // itemsListElement.appendChild(monitoredContent);
    itemsListElement.appendChild(pricedContent);

    // Open the default tab if specified
    // if (defaultTab === 'matched') {
    //   monitoredTab.click();
    // } else if (defaultTab === 'priced') {
    //   pricedTab.click();
    // } else {
    //   monitoredTab.click();
    // }

    defaultTab === 'priced';
    pricedTab.click();

    this.tabsInitialized = true;
    logMessage(LOG_LEVELS.INFO, 'Tabs created', { defaultTab });
  }

  createTabButton(text, tabName) {
    const button = document.createElement('button');
    button.className = 'tablinks';
    button.textContent = text;
    button.onclick = (event) => this.openTab(event, tabName);
    return button;
  }

  openTab(evt, tabName) {
    // Get all elements with the specific "tabcontent" class within this component only
    const tabContainer = evt.currentTarget.parentNode.parentNode; // Scoped container
    const tabContents = tabContainer.getElementsByClassName('tabcontent');
    const tabLinks = tabContainer.getElementsByClassName('tablinks');

    for (let content of tabContents) {
      content.style.display = 'none';
    }

    for (let link of tabLinks) {
      link.className = link.className.replace(' active', '');
    }

    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' active';
  }
}

class TwoImagePanelWithForm extends TwoImagePanelBase {
  constructor(
    id_prefix,
    center_fields,
    left_column_fields,
    right_column_fields,
    single_button = false
  ) {
    super(id_prefix, 'ebay-items-container', single_button);

    // Bind event handlers
    this.handleLeftMouseEnter = this.handleLeftMouseEnter.bind(this);
    this.handleLeftTouchStart = this.handleLeftTouchStart.bind(this);
    this.handleRightMouseEnter = this.handleRightMouseEnter.bind(this);
    this.handleRightTouchStart = this.handleRightTouchStart.bind(this);
    this.form = this.createElementWithAttributes('form', {
      className: 'popup-form',
      id: 'popupForm',
      action: '/submit',
      method: 'post',
    });
    this.addFormToPopup(center_fields, left_column_fields, right_column_fields);
  }

  addFixCornerButtons() {
    this.fixCornersLeft = new FixCornersButton(' Move Corners');
    this.fixCornersRight = new FixCornersButton(' Move Corners');

    this.leftFigure.append(this.fixCornersLeft.instance());
    this.rightFigure.append(this.fixCornersRight.instance());
  }

  addHelpLink(helpContent) {
    // Create the help link container
    const helpLinkContainer = this.createElementWithAttributes('div', {
      className: 'help-link-container',
    });

    // Create the help link
    const helpLink = this.createElementWithAttributes('a', {
      href: '#',
      className: 'help-link',
      textContent: 'Need Help? View Card Guide',
    });

    // Add an event listener to display the help content
    helpLink.addEventListener('click', (event) => {
      event.preventDefault(); // Prevent default link behavior
      this.displayHelpContent(helpContent);
    });

    // Append the help link to the container
    helpLinkContainer.appendChild(helpLink);

    // Insert the help link container at the top of the form panel
    this.panel.insertBefore(helpLinkContainer, this.panel.firstChild);
  }

  // Method to display the help content
  displayHelpContent(helpContent) {
    // Check if the modal already exists
    let helpModal = document.getElementById('helpModal');
    if (!helpModal) {
      // Create the modal container
      helpModal = this.createElementWithAttributes('div', {
        id: 'helpModal',
        className: 'help-modal',
      });

      // Create the modal content container
      const helpModalContent = this.createElementWithAttributes('div', {
        className: 'help-modal-content',
      });

      // Create the close button
      const closeModal = this.createElementWithAttributes('span', {
        className: 'close-modal',
        textContent: '×',
      });

      closeModal.addEventListener('click', () => {
        helpModal.style.display = 'none';
      });

      // Add the close button and help content to the modal content
      helpModalContent.appendChild(closeModal);

      // Help content can be an image or description
      const helpImage = this.createElementWithAttributes('img', {
        src: helpContent.imageSrc,
        alt: 'Card Guide',
        className: 'card-guide-image',
      });

      const helpDescription = this.createElementWithAttributes('p', {
        className: 'help-description',
        textContent: helpContent.description || '',
      });

      helpModalContent.appendChild(helpImage);
      helpModalContent.appendChild(helpDescription);

      // Append the modal content to the modal container
      helpModal.appendChild(helpModalContent);

      // Append the modal to the document body
      document.body.appendChild(helpModal);
    }

    // Show the modal
    helpModal.style.display = 'flex';
  }

  addRescanButtons() {
    this.rescanLeft = new RescanButton(' Rescan');
    this.rescanRight = new RescanButton(' Rescan');

    this.leftFigure.append(this.rescanLeft.instance());
    this.rightFigure.append(this.rescanRight.instance());
  }

  clearForm() {
    this.form.remove();
    this.form = null;
    this.form = this.createElementWithAttributes('form', {
      className: 'popup-form',
      id: 'popupForm',
      action: '/submit',
      method: 'post',
    });
  }

  removeFixCornerButtons() {
    if (this.fixCornersLeft) {
      this.fixCornersLeft.instance().remove();
    }

    if (this.fixCornersRight) {
      this.fixCornersRight.instance().remove();
    }

    this.fixCornersLeft = null;
    this.fixCornersRight = null;
  }

  addFormToPopup(
    center_fields,
    left_column_fields = [],
    right_column_fields = []
  ) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating popup', {});

    const columnClass =
      right_column_fields && right_column_fields.length > 0
        ? 'form-columns'
        : 'single-column-form-columns'; // Create the form columns container

    const formColumns = this.createElementWithAttributes('div', {
      className: columnClass,
    });

    // Create the center fields container
    const centerFieldsContainer = this.createElementWithAttributes('div', {
      className: 'center-fields',
    });

    // Create and append center fields to the center fields container
    center_fields.forEach((field) => {
      const fieldElement = this.createFormField(field, field.name, true);
      centerFieldsContainer.appendChild(fieldElement);
    });

    // Add center fields container to the form
    this.form.appendChild(centerFieldsContainer);

    // Add form columns to the form
    this.form.appendChild(formColumns);

    // Create the form fields

    if (left_column_fields) {
      const column1 = this.createFormColumn(left_column_fields);
      formColumns.append(column1);
    }

    if (right_column_fields) {
      const column2 = this.createFormColumn(right_column_fields);
      formColumns.append(column2);
    }

    // Append columns to form

    const allFields = [
      ...left_column_fields,
      ...right_column_fields,
      ...center_fields,
    ];
    this.attachFormValidation(this.form, allFields);

    this.lowerContainer.append(this.form);
  }

  addSingleColumnFormToPopup(fields) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating popup', {});
    // Create the form columns container
    const formColumns = this.createElementWithAttributes('div', {
      className: 'form-columns',
    });

    // Create the center fields container
    const fieldsContainer = this.createElementWithAttributes('div', {
      className: 'form-columns',
    });

    // Add center fields container to the form
    this.form.appendChild(fieldsContainer);

    // Add form columns to the form
    this.form.appendChild(formColumns);

    // Create the form fields
    const column1 = this.createFormColumn(fields);

    // Append columns to form
    formColumns.append(column1);

    this.attachFormValidation(this.form, fields);

    this.lowerContainer.append(this.form);
  }

  createFormColumn(fields) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating form column', {
      fieldsCount: fields.length,
    });
    const column = this.createElementWithAttributes('div', {
      className: 'form-column',
    });
    fields.forEach((field) => {
      const row = this.createFormField(field, field.name);
      column.appendChild(row);
    });
    return column;
  }

  // Function to show tooltip
  showTooltip(event, tooltipText) {
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.textContent = tooltipText;
    document.body.appendChild(tooltip);

    // Position the tooltip near the info icon
    const iconRect = event.target.getBoundingClientRect();
    tooltip.style.left = `${iconRect.left + iconRect.width / 2 - tooltip.offsetWidth / 2}px`;
    tooltip.style.top = `${iconRect.top + window.scrollY - tooltip.offsetHeight - 10}px`; // Adjust 10px above the icon

    // Remove the tooltip on mouseout
    event.target.addEventListener('mouseleave', () => {
      tooltip.remove();
    });

    // Handler to detect taps outside the tooltip
    const outsideTapHandler = (e) => {
      if (!tooltip.contains(e.target) && e.target !== event.target) {
        removeTooltip();
      }
    };

    // Add event listener for touchstart on the document to detect taps outside the tooltip
    document.addEventListener('touchstart', outsideTapHandler, {
      passive: true,
    });
  }

  createFormField(field, fieldName, isTitleField = false) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating form field', { field, fieldName });

    let row;

    let inputName = fieldName;

    if (inputName == 'Box Set') {
      inputName = 'Program';
    }
    if (field.groupName) {
      row = this.createElementWithAttributes('div', {
        className: 'form-row',
        'data-group': field.groupName,
      });
    } else {
      row = this.createElementWithAttributes('div', { className: 'form-row' });
    }

    let labelText;
    if (field.required) {
      labelText = fieldName + ' *';
    } else {
      labelText = fieldName;
    }
    const label = this.createElementWithAttributes('label', {
      textContent: labelText,
    });
    let input;

    // Basic HTML5 validations options
    const validationAttributes = {};
    if (field.required) validationAttributes.required = true;
    if (field.minlength) validationAttributes.minLength = field.minlength;
    if (field.maxlength) validationAttributes.maxLength = field.maxlength;
    if (field.pattern) validationAttributes.pattern = field.pattern;

    switch (field.type) {
      case 'text':
        if (field.rows && field.rows > 1) {
          // Create a <textarea> for multi-line text input
          input = this.createElementWithAttributes('textarea', {
            name: inputName.toLowerCase().replace(/\s+/g, '_'),
            rows: field.rows,
            ...validationAttributes,
          });
        } else {
          // Create a single-line <input>
          input = this.createElementWithAttributes('input', {
            type: 'text', // Ensure the type is 'text'
            name: inputName.toLowerCase().replace(/\s+/g, '_'),
            ...validationAttributes,
          });

          if (field.width) {
            input.style.width = field.width;
          }

          if (field.defaultValue) {
            input.value = field.defaultValue;
          }
        }
        break;
      case 'radio':
        const radioWrapper = this.createElementWithAttributes('div', {
          className: 'form-radio-wrapper',
        });
        field.options.forEach((option) => {
          const optionId =
            fieldName.toLowerCase().replace(/\s+/g, '_') +
            '_' +
            option.toLowerCase();
          const radioOption = this.createElementWithAttributes('input', {
            type: field.type,
            name: fieldName.toLowerCase().replace(/\s+/g, '_'),
            className: 'form-radio',
            value: option.toLowerCase(),
            id: optionId,
            ...validationAttributes,
          });
          const labelOption = this.createElementWithAttributes('label', {
            textContent: option,
            htmlFor: optionId,
          });

          // Attach dynamic event handlers if specified
          if (field.eventHandlers && typeof field.eventHandlers === 'object') {
            Object.entries(field.eventHandlers).forEach(
              ([eventType, handler]) => {
                if (typeof handler === 'function') {
                  radioOption.addEventListener(eventType, handler);
                } else {
                  console.warn(
                    `Handler for event "${eventType}" is not a function on field "${fieldName}".`
                  );
                }
              }
            );
          }

          radioWrapper.append(labelOption, radioOption);
        });
        input = radioWrapper;
        break;
      case 'checkbox':
        if (
          field.options &&
          Array.isArray(field.options) &&
          field.options.length > 0
        ) {
          // Multiple checkboxes
          const checkboxWrapper = this.createElementWithAttributes('div', {
            className: 'form-radio-wrapper',
          });
          field.options.forEach((option) => {
            const optionId = `${fieldName.toLowerCase().replace(/\s+/g, '_')}_${option.toLowerCase()}`;
            const checkboxOption = this.createElementWithAttributes('input', {
              type: 'checkbox',
              name: fieldName.toLowerCase().replace(/\s+/g, '_'),
              className: 'form-checkbox',
              value: option.toLowerCase(),
              id: optionId,
              ...validationAttributes,
            });
            const labelOption = this.createElementWithAttributes('label', {
              textContent: option,
              htmlFor: optionId,
            });

            // Attach dynamic event handlers if specified
            if (
              field.eventHandlers &&
              typeof field.eventHandlers === 'object'
            ) {
              Object.entries(field.eventHandlers).forEach(
                ([eventType, handler]) => {
                  if (typeof handler === 'function') {
                    checkboxOption.addEventListener(eventType, handler);
                  } else {
                    console.warn(
                      `Handler for event "${eventType}" is not a function on field "${fieldName}".`
                    );
                  }
                }
              );
            }

            checkboxWrapper.append(labelOption, checkboxOption);
          });
          input = checkboxWrapper;
        } else {
          // Single checkbox
          const checkboxWrapper = this.createElementWithAttributes('div', {
            className: 'form-radio-wrapper',
          });

          const checkboxOption = this.createElementWithAttributes('input', {
            type: 'checkbox',
            name: fieldName.toLowerCase().replace(/\s+/g, '_'),
            className: 'form-checkbox',
            ...validationAttributes,
          });

          checkboxWrapper.append(checkboxOption);

          // Attach dynamic event handlers if specified
          if (field.eventHandlers && typeof field.eventHandlers === 'object') {
            Object.entries(field.eventHandlers).forEach(
              ([eventType, handler]) => {
                if (typeof handler === 'function') {
                  checkboxOption.addEventListener(eventType, handler);
                } else {
                  console.warn(
                    `Handler for event "${eventType}" is not a function on field "${fieldName}".`
                  );
                }
              }
            );
          }

          input = checkboxWrapper;
        }
        break;
      case 'select':
        let className = 'form-select';

        if (isTitleField) {
          className = 'title-fields';
        }
        input = this.createElementWithAttributes('select', {
          className: className,
          name: fieldName.toLowerCase().replace(/\s+/g, '_'),
          ...validationAttributes,
        });
        if (field.options && field.options.length) {
          field.options.forEach((option) => {
            const optionElement = this.createElementWithAttributes('option', {
              value: option,
              textContent: option,
            });
            input.appendChild(optionElement);
          });
        }
        break;
    }

    // Adjust the createFormField method to apply pulsing directly to inputs for "Color" and "Parallel"
    if (field.shouldPulse) {
      input.classList.add('input-pulse'); // This assumes `input` is your input element variable
      label.classList.add('input-pulse'); // This assumes `input` is your input element variable

      // Remove the pulsing effect after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        input.classList.remove('input-pulse');
      }, 6000);

      setTimeout(() => {
        label.classList.remove('input-pulse');
      }, 6000);
    }

    if (field.tooltipText) {
      const infoIcon = this.createElementWithAttributes('span', {
        className: 'info-icon',
        textContent: ' ?',
      });
      label.appendChild(infoIcon);

      // const tooltipText = "We do not detect color or parallel atuomatically. Please fill this field in if your card has a color or parallel.";
      const tooltipText = field.tooltipText;
      infoIcon.addEventListener('mouseenter', (event) =>
        this.showTooltip(event, tooltipText)
      );
      infoIcon.addEventListener('touchstart', (event) =>
        this.showTooltip(event, tooltipText)
      );
    }

    if (field.attachOnChange) {
      input.addEventListener('input', (event) => {
        logMessage(LOG_LEVELS.INFO, 'Form field modified.');
        stateManager.updateState({ userModified: true });
      });
    }

    if (input.type == 'text') {
      input.addEventListener('blur', (event) => {
        if (input.value) {
          // Extract the first letter, capitalize it, and concatenate with the rest of the string
          input.value =
            input.value.charAt(0).toUpperCase() + input.value.slice(1);
        }
      });
    }

    // Custom Validation Logic (if any)
    if (field.customValidation) {
      input.addEventListener('input', (event) => {
        field.customValidation(input);
      });
    }

    if (field.readonly) {
      input.readOnly = true;
    }

    if (isTitleField) {
      row.append(input);
    } else {
      row.append(label, input);
    }

    return row;
  }

  attachFormValidation(form, allFields) {
    // Create a dedicated area for displaying form errors
    const errorContainer = this.createElementWithAttributes('div', {
      className: 'form-errors',
    });
    form.insertBefore(errorContainer, form.firstChild); // Insert at the top of the form

    // Attach validation logic to each field
    this.attachFormErrors(allFields, errorContainer, form);
  }

  resetErrors() {
    this.errors = [];
    const errorContainers = document.getElementsByClassName('form-errors');
    Array.from(errorContainers).forEach((container) => {
      container.innerHTML = '';
      container.style.display = 'none';
    }); // Clear previous errors
  }

  attachFormErrors(fields, errorContainer, form) {
    fields.forEach((field) => {
      let inputName = field.name;

      if (inputName == 'Box Set') {
        inputName = 'Program';
      }
      const fieldName = inputName.toLowerCase().replace(/\s+/g, '_');
      const input = form.querySelector(`[name="${fieldName}"]`);

      if (field.validationMessage) {
        input.addEventListener('invalid', (event) => {
          event.preventDefault(); // Prevent the default browser message
          event.stopPropagation();

          const validity = event.target.validity;
          const messageKeys = Object.keys(field.validationMessage).find(
            (key) => validity[key]
          );

          if (messageKeys) {
            this.errors.push(
              `${field.name}: ${field.validationMessage[messageKeys]}`
            );
          } else {
            if (validity.customError) {
              this.errors.push(event.target.validationMessage);
            } else {
              this.errors.push(field.validationMessage);
            }
          }

          // Display errors if any exist
          if (this.errors.length > 0) {
            errorContainer.innerHTML = this.errors.join('<br>'); // Join messages with line breaks
            errorContainer.style.display = 'block'; // Make the error container visible
          }
        });
      }
    });
  }
  updateFormFields(cardData, fieldMapping = null, valueTransformer = null) {
    logMessage(LOG_LEVELS.DEBUG, 'Updating form fields', { cardData });
    this.resetFormFields();

    Object.entries(cardData).forEach(([key, value]) => {
      // Use fieldMapping to get the form field name, defaulting to key if not mapped
      const fieldName = (fieldMapping && fieldMapping[key]) || key;
      const formFieldKey = fieldName.toLowerCase().replace(/\s+/g, '_');
      const input = this.form.querySelector(`[name="${formFieldKey}"]`);

      if (input) {
        this.updateInput(input, value, valueTransformer);
      }
    });

    logMessage(LOG_LEVELS.INFO, 'Form fields updated', {
      updatedFieldsCount: Object.keys(cardData).length,
    });
  }

  resetFormFields() {
    logMessage(LOG_LEVELS.DEBUG, 'Resetting form fields', {});

    // Reset select inputs
    this.form.querySelectorAll('select[name="patch"').forEach((select) => {
      select.value = 'None';
    });

    this.form.querySelectorAll('select[name="sport"').forEach((select) => {
      select.value = 'Pick Sport';
    });

    // Default all radio buttons to 'no'
    this.form.querySelectorAll('.form-radio').forEach((radioGroup) => {
      const noOption = this.form.querySelector(
        `input[name="${radioGroup.name}"][value="no"]`
      );
      if (noOption) noOption.checked = true;
    });

    // Reset text input fields
    this.form.querySelectorAll('input[type="text"]').forEach((input) => {
      input.value = '';
    });

    logMessage(LOG_LEVELS.INFO, 'Form fields reset', {});
  }

  updateInput(input, value, valueTransformer) {
    logMessage(LOG_LEVELS.DEBUG, 'Updating input field', {
      inputName: input.name,
      value,
    });

    const transformedValue = valueTransformer ? valueTransformer(value) : value;

    if (input.tagName === 'INPUT' && input.type === 'text') {
      input.value = transformedValue;
    } else if (input.tagName === 'SELECT') {
      for (let option of input.options) {
        if (option.value === transformedValue) {
          option.selected = true;
          break;
        }
      }
    } else if (input.tagName === 'INPUT' && input.className === 'form-radio') {
      const yesNoValue = transformedValue ? 'yes' : 'no';
      const radioInput = input.form.querySelector(
        `input[name="${input.name}"][value="${yesNoValue}"]`
      );
      if (radioInput) radioInput.checked = true;
    }

    logMessage(LOG_LEVELS.INFO, 'Input field updated', {
      inputName: input.name,
    });
  }

  outputValueMapper(name) {
    if (name == 'sport') {
      return 'card_type';
    }

    return name;
  }

  getFormData() {
    logMessage(LOG_LEVELS.DEBUG, 'Getting form data', {});
    let formDataObject = {};

    // Collect data from text inputs, select elements, and radio buttons
    const inputs = this.form.querySelectorAll('input, select, textarea');

    inputs.forEach((input) => {
      let value = null;
      const name = input.name.toLowerCase().replace(/\s+/g, '_');

      if (!name) return; // Skip inputs without names

      // Initialize the property in formDataObject if it doesn't exist
      if (!formDataObject.hasOwnProperty(name)) {
        // Determine if it's a checkbox to initialize appropriately
        if (input.type === 'checkbox') {
          // Check if multiple checkboxes share the same name
          const isMultiple =
            this.form.querySelectorAll(`input[name="${name}"][type="checkbox"]`)
              .length > 1;
          formDataObject[name] = isMultiple ? [] : false;
        } else {
          formDataObject[name] = null;
        }
      }

      if (input.tagName === 'SELECT') {
        if (input.selectedIndex !== -1) {
          value = input.options[input.selectedIndex].value;
        }
      } else if (input.type === 'radio') {
        if (input.checked) {
          value = input.value === 'yes' ? true : false; // Adjust based on your true/false representation
        } else {
          return; // Skip unchecked radios
        }
      } else if (input.type === 'text' || input.type === 'textarea') {
        value = input.value;
      } else if (input.type === 'checkbox') {
        if (
          this.form.querySelectorAll(`input[name="${name}"][type="checkbox"]`)
            .length > 1
        ) {
          // Multiple checkboxes with the same name
          if (input.checked) {
            formDataObject[name].push(input.value);
          }
        } else {
          // Single checkbox
          formDataObject[name] = input.checked;
        }
      }

      // Only assign if value is not null (which means it was set above)
      if (value !== null) {
        formDataObject[this.outputValueMapper(name)] = value;
      }
    });

    logMessage(LOG_LEVELS.INFO, 'Form data retrieved', { formDataObject });
    return formDataObject;
  }

  isValidInput() {
    this.form.querySelectorAll('select[name="sport"]').forEach((select) => {
      if (select.value === 'Pick Sport') {
        select.setCustomValidity('Please select a sport.');
      } else {
        select.setCustomValidity('');
      }
    });

    const listingTypeCheckboxes = this.form.querySelectorAll(
      'input[name="listing_options"]'
    );

    if (listingTypeCheckboxes.length > 0) {
      // Remove required attribute to prevent default validation
      listingTypeCheckboxes.forEach((checkbox) => (checkbox.required = false));

      // Get an array of checked checkboxes
      const checkedCheckboxes = Array.from(listingTypeCheckboxes).filter(
        (checkbox) => checkbox.checked
      );
      const checkedValues = checkedCheckboxes.map((checkbox) => checkbox.value);

      const isAnyListingTypeChecked = checkedCheckboxes.length > 0;
      const isAllListingTypeChecked =
        checkedCheckboxes.length === listingTypeCheckboxes.length;

      // Check if only "Allow Offers" is checked
      const isOnlyAllowOffersChecked =
        checkedValues.length === 1 && checkedValues.includes('allow offers');

      if (!isAnyListingTypeChecked) {
        // No options selected
        listingTypeCheckboxes[0].setCustomValidity(
          'At least one listing option must be selected.'
        );
      } else if (isOnlyAllowOffersChecked) {
        // Only "Allow Offers" is checked
        listingTypeCheckboxes[0].setCustomValidity(
          "You cannot select 'Allow Offers' alone. Please select another listing option."
        );
      } else if (isAllListingTypeChecked) {
        // All options are checked or both "Allow Offers" and "Buy It Now" are checked
        listingTypeCheckboxes[0].setCustomValidity(
          "When submitting a listing to auction, you may choose only one of 'Allow Offers' or 'Buy It Now'."
        );
      } else {
        // Valid selection
        listingTypeCheckboxes.forEach(
          (checkbox) => (checkbox.required = false)
        );
        listingTypeCheckboxes[0].setCustomValidity('');
      }
    }

    const is_valid = this.form.checkValidity();

    if (!is_valid) {
      const invalidFields = this.form.querySelectorAll(':invalid');
      invalidFields.forEach((field) => {
        // For example, log the names of invalid fields
        logMessage(LOG_LEVELS.ERROR, `Invalid field ${field.name}`);
        // Add logic here to highlight fields or show specific error messages
      });
    }
    return is_valid;
  }

  bindFormInput(handler) {
    this.form.addEventListener('input', handler);
  }

  bindLeftFixCorners(handler) {
    this.fixCornersLeft.instance().addEventListener('click', handler);
  }

  bindRightFixCorners(handler) {
    this.fixCornersRight.instance().addEventListener('click', handler);
  }

  bindLeftRescan(handler) {
    this.rescanLeft.instance().addEventListener('click', handler);
  }

  bindRightRescan(handler) {
    this.rescanRight.instance().addEventListener('click', handler);
  }

  handleLeftMouseEnter(event) {
    const tooltipText = 'Please rescan front image to enable corner movement.';
    this.fixCornersLeft.showTooltip(event, tooltipText);
  }

  handleLeftTouchStart(event) {
    const tooltipText = 'Please rescan front image to enable corner movement.';
    this.fixCornersLeft.showTooltip(event, tooltipText);
  }

  handleRightMouseEnter(event) {
    const tooltipText = 'Please rescan back image to enable corner movement.';
    this.fixCornersRight.showTooltip(event, tooltipText);
  }

  handleRightTouchStart(event) {
    const tooltipText = 'Please rescan back image to enable corner movement.';
    this.fixCornersRight.showTooltip(event, tooltipText);
  }

  disableLeftFixCorners() {
    this.fixCornersLeft.disable();
    this.fixCornersLeft
      .instance()
      .addEventListener('mouseenter', this.handleLeftMouseEnter);
    this.fixCornersLeft
      .instance()
      .addEventListener('touchstart', this.handleLeftTouchStart);
  }

  disableRightFixCorners() {
    this.fixCornersRight.disable();
    this.fixCornersRight
      .instance()
      .addEventListener('mouseenter', this.handleRightMouseEnter);
    this.fixCornersRight
      .instance()
      .addEventListener('touchstart', this.handleRightTouchStart);
  }

  enableLeftFixCorners() {
    this.fixCornersLeft.enable();
    this.fixCornersLeft
      .instance()
      .removeEventListener('mouseenter', this.handleLeftMouseEnter);
    this.fixCornersLeft
      .instance()
      .removeEventListener('touchstart', this.handleLeftTouchStart);
  }

  enableRightFixCorners() {
    this.fixCornersRight.enable();
    this.fixCornersRight
      .instance()
      .removeEventListener('mouseenter', this.handleRightMouseEnter);
    this.fixCornersRight
      .instance()
      .removeEventListener('touchstart', this.handleRightTouchStart);
  }
}

export {
  TwoImagePanelBase,
  TwoImagePanelEbay,
  TwoImagePanelWithForm,
  SingleImagePanelWithCornerOverlay,
};
