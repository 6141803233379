import { LOG_LEVELS, logMessage } from '../logging.js';

class PopupButton {
  constructor(buttonClass, buttonId, buttonText) {
    this.button = this.createButton(buttonClass, buttonId, buttonText);
  }

  // Method to create an element with specified attributes
  createElementWithAttributes(tagName, attributes) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating element', { tagName, attributes });
    const element = document.createElement(tagName);
    for (let key in attributes) {
      element[key] = attributes[key];
    }
    return element;
  }

  // Create a button with specified attributes
  createButton(buttonClass, buttonId, buttonText) {
    return this.createElementWithAttributes('button', {
      className: buttonClass,
      id: buttonId,
      innerHTML: buttonText,
    });
  }

  // Method to get the button instance
  instance() {
    return this.button;
  }

  enable() {
    this.button.disabled = false;
    this.button.style = 'cursor: pointer;';
  }

  disable() {
    this.button.disabled = true;
    this.button.style = 'cursor: not-allowed;';
  }

  // Function to show tooltip
  showTooltip(event, tooltipText) {
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.textContent = tooltipText;
    document.body.appendChild(tooltip);

    // Position the tooltip near the info icon
    const iconRect = event.target.getBoundingClientRect();
    tooltip.style.left = `${iconRect.left + iconRect.width / 2 - tooltip.offsetWidth / 2}px`;
    tooltip.style.top = `${iconRect.top + window.scrollY - tooltip.offsetHeight - 10}px`; // Adjust 10px above the icon

    // Remove the tooltip on mouseout
    event.target.addEventListener('mouseleave', () => {
      tooltip.remove();
    });

    // Handler to detect taps outside the tooltip
    const outsideTapHandler = (e) => {
      if (!tooltip.contains(e.target) && e.target !== event.target) {
        removeTooltip();
      }
    };

    // Add event listener for touchstart on the document to detect taps outside the tooltip
    document.addEventListener('touchstart', outsideTapHandler, {
      passive: true,
    });
  }

  updateText(newText) {
    this.button.innerHTML = newText;
  }
}

// RejectButton class extending PopupButton
class RejectButton extends PopupButton {
  constructor(buttonText) {
    super('reject-button', 'prevButton', buttonText); // Passes specific values to the base class constructor
  }
}

// AcceptButton class extending PopupButton
class AcceptButton extends PopupButton {
  constructor(buttonText) {
    super('accept-button', 'nextButton', buttonText); // Passes specific values to the base class constructor
  }
}

// AcceptButton class extending PopupButton
class RescanButton extends PopupButton {
  constructor(buttonText) {
    super('fas fa-sync subtle-button', 'rescanButton', buttonText); // Passes specific values to the base class constructor
  }
}

class FixCornersButton extends PopupButton {
  constructor(buttonText) {
    super('fas fa-wrench subtle-button', 'rescanButton', buttonText); // Passes specific values to the base class constructor
  }
}

// Export the class instead of individual functions
export {
  PopupButton,
  RejectButton,
  AcceptButton,
  RescanButton,
  FixCornersButton,
};
