import { sendAuthenticatedRequest } from '../../auth.js';
import { PopupButton } from '../buttons.js';
import stateManager from '../../data/state_manager.js';
import { LOG_LEVELS, logMessage } from '../../logging';

class PopupBase {
  constructor(id_prefix) {
    this.closeButton = this.createElementWithAttributes('span', {
      className: 'close-button-light',
      id: id_prefix + '_closeButton',
      innerHTML: '&times;',
    });
    this.popup = this.createElementWithAttributes('div', {
      className: 'image-popup',
      id: id_prefix + '_imagePopup',
    });
    this.onclose = null; // Add an onclose property for the event handler
    this.observeRemoval(); // Attach the MutationObserver
  }

  createElementWithAttributes(tagName, attributes) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating element', { tagName, attributes });
    const element = document.createElement(tagName);

    for (let key in attributes) {
      if (key.startsWith('data-')) {
        // Use setAttribute for data-* attributes
        element.setAttribute(key, attributes[key]);
      } else {
        // Directly assign the other attributes (like className)
        element[key] = attributes[key];
      }
    }

    return element;
  }

  showImage(imageUrl, imageElementId, headers) {
    logMessage(LOG_LEVELS.DEBUG, 'Fetching and showing image', {
      imageUrl,
      imageElementId,
    });

    sendAuthenticatedRequest(imageUrl, {
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Specify that we're expecting a blob response
    })
      .then((result) => {
        if (!result.ok) {
          throw new Error(`HTTP error! status: ${result.status}`);
        }
        // Assuming result.blob contains the blob directly
        const localUrl = URL.createObjectURL(result.blob);
        const imageElement = document.getElementById(imageElementId);
        if (imageElement) {
          imageElement.src = localUrl;
        }
      })
      .catch((error) =>
        logMessage(
          LOG_LEVELS.ERROR,
          'Error fetching the image:',
          error.toString()
        )
      );
  }

  basePopup() {
    logMessage(LOG_LEVELS.DEBUG, 'Creating rescan popup', {});

    // Assemble the popup
    this.popup.append(this.closeButton);

    // Add the popup to the body
    document.body.appendChild(this.popup);

    logMessage(LOG_LEVELS.INFO, 'Popup created and displayed', {});
    return this.popup;
  }

  bindCloseButton(handler) {
    this.closeButton.addEventListener('click', handler);
  }

  bindOnClose(handler) {
    this.onclose = handler;
  }

  remove() {
    this.popup.remove();
  }

  loadIntoBody() {
    document.body.appendChild(this.popup);
  }

  observeRemoval() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.removedNodes.length > 0) {
          mutation.removedNodes.forEach((node) => {
            if (node === this.popup && typeof this.onclose === 'function') {
              this.onclose(); // Trigger the onclose event
            }
          });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
}

class PopupWithDropzone extends PopupBase {
  constructor(id_prefix, dropzoneId) {
    super(id_prefix);
    this.dropzoneId = dropzoneId;
    this.buttonContainer = this.createElementWithAttributes('div', {
      className: 'button-container',
      id: id_prefix + '_button_container',
    });
    this.dropzoneContainer = this.createElementWithAttributes('div', {
      className: 'rescan-container',
      id: id_prefix + '_rescan_container',
    });
    this.dropzone = this.createElementWithAttributes('div', {
      className: 'dropzone',
      id: this.dropzoneId,
    });

    this.id_prefix = id_prefix;
    this.popup = this.createPopup();
  }

  createPopup() {
    logMessage(LOG_LEVELS.DEBUG, 'Creating popup', {});
    const popup = this.basePopup();

    const panel = this.createElementWithAttributes('div', {
      className: 'popup-panel',
      id: this.id_prefix + '_popup_panel',
    });
    this.dropzoneContainer.append(this.dropzone);
    panel.append(this.dropzoneContainer);
    panel.append(this.buttonContainer);

    this.popup.append(panel);

    return popup;
  }

  addButton(button, clickHandler) {
    if (!(button instanceof PopupButton)) {
      throw new TypeError('Argument must be an instance of PopupButton');
    }

    this.buttonContainer.append(button.instance());
    button.instance().addEventListener('click', clickHandler);
  }
}

class PopupWithCardCounter extends PopupBase {
  constructor(id_prefix, headers, submit_callback) {
    super(id_prefix);
    this.counterValue = 1;
    this.submit_callback = submit_callback;
    this.headers = headers;
    this.id_prefix = id_prefix;
    this.createCounterPopup();
  }

  createCounterPopup() {
    const popup = this.basePopup();

    logMessage(LOG_LEVELS.DEBUG, 'Creating counter popup', {});

    const panel = this.createElementWithAttributes('div', {
      className: 'popup-panel',
      id: this.id_prefix + '_popup_panel',
    });
    const counterContainer = this.createElementWithAttributes('div', {
      className: 'counter-container',
      id: this.id_prefix + '_counter_container',
    });
    const header = this.createElementWithAttributes('div', {
      className: 'popup-header',
      innerHTML: 'How many cards are you scanning?',
      id: this.id_prefix + '_popup_header',
    });

    this.decrementButton = this.createElementWithAttributes('button', {
      className: 'counter-button',
      innerHTML: '-',
      id: this.id_prefix + '_decrement_button',
    });
    this.incrementButton = this.createElementWithAttributes('button', {
      className: 'counter-button',
      innerHTML: '+',
      id: this.id_prefix + '_increment_button',
    });
    this.cardCounter = this.createElementWithAttributes('input', {
      className: 'counter',
      value: '0',
      readOnly: true,
      id: this.id_prefix + '_counter_input',
    });

    // Tooltip for increment button
    this.incrementTooltip = this.createElementWithAttributes('span', {
      className: 'counter-tooltip',
      innerHTML: 'Cannot scan any more cards from 1 image.',
      id: this.id_prefix + '_increment_tooltip',
    });

    // Add tooltip to the button
    this.incrementButton.appendChild(this.incrementTooltip);

    counterContainer.append(
      this.decrementButton,
      this.cardCounter,
      this.incrementButton
    );

    const submitButton = this.createElementWithAttributes('button', {
      className: 'accept-button',
      innerHTML: 'Submit',
      id: this.id_prefix + '_submit_button',
    });

    panel.append(header, counterContainer, submitButton);
    this.popup.append(panel);

    this.bindCounterButtons();
    submitButton.addEventListener('click', this.handleSubmit.bind(this));

    return popup;
  }

  bindCounterButtons() {
    this.decrementButton.addEventListener(
      'click',
      this.decrementCounter.bind(this)
    );
    this.incrementButton.addEventListener(
      'click',
      this.incrementCounter.bind(this)
    );
    this.updateCounter();
  }

  incrementCounter() {
    const { max_cards } = stateManager.getState();

    if (this.counterValue < max_cards) {
      this.counterValue++;
      this.updateCounter();
    } else {
      this.showTooltip(this.incrementTooltip);
    }
  }

  decrementCounter() {
    if (this.counterValue > 0) {
      this.counterValue--;
      this.updateCounter();
    }
  }

  updateCounter() {
    this.cardCounter.value = this.counterValue;
    this.decrementButton.disabled = this.counterValue <= 1;
  }

  showTooltip(tooltip) {
    tooltip.style.visibility = 'visible';
    setTimeout(() => {
      tooltip.style.visibility = 'hidden';
    }, 2000);
  }

  handleSubmit() {
    // Handle the submit action
    console.log(`Number of cards: ${this.counterValue}`);
    stateManager.updateState({ multipleErrorUnprocessed: this.counterValue });
    this.submit_callback(this.counterValue, this.headers);
    // You can add additional logic to handle the submit action
    this.remove();
  }
}

export { PopupBase, PopupWithDropzone, PopupWithCardCounter };
