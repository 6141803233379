import { LOG_LEVELS, logMessage } from '../logging.js';
import stateManager from '../data/state_manager.js';
import { sendAuthenticatedRequest } from '../auth.js';
import config from '../../config/index.js';

class DropzoneManager {
  constructor(dropzone_id, url, message, headers) {
    this.dropzoneInstance = this.createDropzone(
      dropzone_id,
      url,
      message,
      headers
    );
    this.setupDropzoneUI();
    this.headers = headers;
  }

  instance() {
    return this.dropzoneInstance;
  }

  setupDropzoneUI() {
    const dropzoneContainer = this.dropzoneInstance.element.parentNode;
    let buttonGroup = dropzoneContainer.querySelector('.button-group');
    if (!buttonGroup) {
      buttonGroup = document.createElement('div');
      buttonGroup.className = 'button-group';
      dropzoneContainer.appendChild(buttonGroup);

      this.rotateLeftButton = this.createElementWithAttributes('button', {
        innerHTML: '<i class="fas fa-undo rotate-button-icon"></i>Rotate Left',
        className: 'rotate-button',
      });
      this.rotateRightButton = this.createElementWithAttributes('button', {
        innerHTML: '<i class="fas fa-redo rotate-button-icon"></i>Rotate Right',
        className: 'rotate-button',
      });

      this.rotateLeftButton.disabled = true;
      this.rotateRightButton.disabled = true;

      buttonGroup.appendChild(this.rotateLeftButton);
      buttonGroup.appendChild(this.rotateRightButton);

      this.attachEventListeners();
    }

    this.dropzoneInstance.on('error', (file, response) => {
      let errorMessage;

      if (!response) {
        // No response from the server
        errorMessage =
          'The server did not respond. Please check your internet connection or try again later.';
      } else if (typeof response === 'object' && response.errorCode) {
        // Handle known error codes from the backend
        switch (response.errorCode) {
          case 'missing_token':
            errorMessage =
              'Authentication token is missing. Please log in and try again.';
            break;
          case 'invalid_token':
            errorMessage = 'Invalid authentication token. Please log in again.';
            break;
          case 'token_expired':
            errorMessage =
              'Your authentication token has expired. Please refresh the page and try again. If the error persists, please log out and log back on.';
            break;
          case 'server_error':
            errorMessage = 'A server error occurred. Please try again later.';
            break;
          default:
            errorMessage =
              'An unknown error occurred while uploading the file. Please try again.';
        }
      } else if (typeof response === 'object' && response.error) {
        // Use the error message from the server
        errorMessage = response.error;
      } else if (typeof response === 'string') {
        // Handle errorMessage as a simple string
        errorMessage = response;
      } else {
        // Fallback if response structure is unexpected
        errorMessage =
          'An unknown error occurred without a proper error message. Please try again.';
      }

      // Display the error message to the user
      alert(errorMessage); // Replace with a non-blocking notification system if preferred

      // Log the error for debugging
      logMessage(
        LOG_LEVELS.ERROR,
        'Error loading Dropzone file:',
        response || errorMessage
      );

      // Update application state
      stateManager.updateState({ stopProcessing: true });

      // Optional: Remove the file preview to keep UI clean
      this.dropzoneInstance.removeFile(file);
    });
  }

  createDropzone(id, url, defaultMessage, headers) {
    const options = {
      url: url,
      headers: headers,
      paramName: 'image',
      maxFilesize: 10,
      maxFiles: 1,
      acceptedFiles: 'image/*',
      dictDefaultMessage: defaultMessage,
      clickable: true,
      addRemoveLinks: false,
      thumbnailWidth: null,
      thumbnailHeight: 1800,
      thumbnailMethod: 'contain',
      autoProcessQueue: false,
    };

    return new Dropzone(id, options);
  }

  attachEventListeners() {
    this.dropzoneInstance.on('thumbnail', (file) => this.handleThumbnail(file));
    this.dropzoneInstance.on('removedfile', (file) =>
      this.handleRemovedFile(file)
    );
  }

  handleThumbnail(file) {
    this.file = file;
    const { dropzoneFiles } = stateManager.getState();
    stateManager.updateState({ dropzoneFiles: dropzoneFiles + 1 });

    this.warmup();
    this.hideProgressElement(file);
    const deleteButton = this.createDeleteButton();
    this.setupButtonPositions(deleteButton);
    this.setupRotationButtons(file.previewElement, deleteButton);
    this.appendDeleteButton(file.previewElement, deleteButton);
    this.attachDeleteEvent(deleteButton, file);
    this.updatePreviewDimensions(file.previewElement);
  }

  hideProgressElement(file) {
    const progressElement = file.previewElement.querySelector('.dz-progress');
    if (progressElement) {
      progressElement.style.display = 'none';
    }
  }

  updatePreviewDimensions(previewElement) {
    previewElement.style.display = 'block';

    const dzImage = previewElement.querySelector('.dz-image');

    const setDimensions = (width, height) => {
      if (height / width >= 4 / 3) {
        previewElement.style.height = '100%';
        previewElement.style.width = '';
        dzImage.style.width = '';
      } else {
        previewElement.style.height = 'auto';
        previewElement.style.width = '';
      }
    };

    const img = previewElement.querySelector('img');
    if (img.complete) {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      setDimensions(width, height);
    } else {
      img.addEventListener('load', () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        setDimensions(width, height);
      });
    }
  }

  createDeleteButton() {
    return this.createElementWithAttributes('button', {
      innerHTML: '<i class="fas fa-times"></i>',
      className: 'delete-button dz-remove',
    });
  }

  setupButtonPositions(button) {
    button.style.left = '0px';
    button.style.top = '0px';
  }

  setupRotationButtons(previewElement, deleteButton) {
    this.rotateLeftButton.disabled = false;
    this.rotateRightButton.disabled = false;

    this.rotateLeftButton.addEventListener('click', () => {
      this.rotateImage(previewElement, -90);
      this.rotateButtonLeft(deleteButton);
    });
    this.rotateRightButton.addEventListener('click', () => {
      this.rotateImage(previewElement, 90);
      this.rotateButtonRight(deleteButton);
    });
  }

  appendDeleteButton(previewElement, deleteButton) {
    previewElement.appendChild(deleteButton);
  }

  attachDeleteEvent(deleteButton, file) {
    deleteButton.addEventListener('click', () => {
      this.dropzoneInstance.removeFile(file);
    });
  }

  async processFiles(skipCornerDetection) {
    const files = this.dropzoneInstance.getAcceptedFiles();
    for (const file of files) {
      const formData = new FormData();
      formData.append(this.dropzoneInstance.options.paramName, file);

      const rotationDegrees = file.previewElement.getAttribute('data-rotation');
      formData.append('rotationDegrees', rotationDegrees);

      // Add skipCornerDetection to the form data
      formData.append('skipCornerDetection', String(skipCornerDetection));

      try {
        const response = await sendAuthenticatedRequest(
          this.dropzoneInstance.options.url,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (response.ok) {
          this.dropzoneInstance.emit('success', file, response.body);
        } else {
          this.dropzoneInstance.emit('error', file, response.body);
        }
      } catch (error) {
        this.dropzoneInstance.emit('error', file, error.message);
      }
    }
  }

  async warmup() {
    try {
      await sendAuthenticatedRequest(`${config.detectionUrl}/warmup`, {
        method: 'POST',
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  handleRemovedFile(file) {
    file.previewElement.style.height = null;
    const { dropzoneFiles } = stateManager.getState();
    const newDropZoneFiles = dropzoneFiles - 1;

    this.rotateLeftButton.disabled = true;
    this.rotateRightButton.disabled = true;
    this.file = null;

    stateManager.updateState({ dropzoneFiles: newDropZoneFiles });
  }

  rotateButtonLeft(button) {
    if (button.style.left && button.style.top) {
      button.style.right = '0px';
      button.style.left = null;
    } else if (button.style.right && button.style.top) {
      button.style.bottom = '0px';
      button.style.top = null;
    } else if (button.style.bottom && button.style.right) {
      button.style.left = '0px';
      button.style.right = null;
    } else if (button.style.bottom && button.style.left) {
      button.style.top = '0px';
      button.style.bottom = null;
    }
  }

  rotateButtonRight(button) {
    if (button.style.left && button.style.top) {
      button.style.bottom = '0px';
      button.style.top = null;
    } else if (button.style.bottom && button.style.left) {
      button.style.right = '0px';
      button.style.left = null;
    } else if (button.style.bottom && button.style.right) {
      button.style.top = '0px';
      button.style.bottom = null;
    } else if (button.style.right && button.style.top) {
      button.style.left = '0px';
      button.style.right = null;
    }
  }

  createElementWithAttributes(tagName, attributes) {
    const element = document.createElement(tagName);
    for (let key in attributes) {
      element[key] = attributes[key];
    }
    return element;
  }

  removeAllFiles() {
    this.dropzoneInstance.removeAllFiles(true);
  }

  rotateImage(imgElement, degrees) {
    const currentDegrees = parseInt(
      imgElement.getAttribute('data-rotation') || '0'
    );
    const newRotation = currentDegrees + degrees;
    imgElement.style.transform = `rotate(${newRotation}deg)`;
    imgElement.setAttribute('data-rotation', newRotation);
  }

  getRotation() {
    const previewElement = this.file.previewElement;

    const currentDegrees = parseInt(
      previewElement.getAttribute('data-rotation') || '0'
    );
    return currentDegrees;
  }
}

export { DropzoneManager };
