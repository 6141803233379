import { TwoImagePanelWithForm } from '../../ui_components/popups/two_image_panels.js';
import { AcceptButton, RejectButton } from '../../ui_components/buttons.js';
import { LOG_LEVELS, logMessage } from '../../logging.js';
import { ImageModel } from '../../ui_models/two_image_container_model.js';

import { reddit_post_fields } from './form_constants.js';
import config from '../../../config/index.js';
import { fetchCardData } from '../../data/update_operations.js';

class RedditFormPopupController {
  constructor(card_info, headers) {
    this.leftImageModel = new ImageModel();
    this.rightImageModel = new ImageModel();
    this.closeButton = new RejectButton('Cancel');
    this.acceptButton = new AcceptButton('Review');
    this.card_info = card_info;
    this.headers = headers;
  }

  async showListingPopup(main_popup) {
    const popup = new TwoImagePanelWithForm([], reddit_post_fields);
    this.leftImageModel.setHeaders(this.headers);
    this.rightImageModel.setHeaders(this.headers);
    const local_url1 = await this.leftImageModel.fetchImage(
      this.card_info.user_card.front_image_url_large
    );
    const local_url2 = await this.rightImageModel.fetchImage(
      this.card_info.user_card.back_image_url_large
    );

    popup.setLeftImage(local_url1);
    popup.setRightImage(local_url2);

    logMessage(LOG_LEVELS.DEBUG, 'Showing reddit card popup');

    popup.addButtonToLeft(this.closeButton, () => this.handleClose(popup));
    popup.addButtonToRight(this.acceptButton, () =>
      this.handleSave(popup, this.headers)
    );

    popup.bindCloseButton(() => this.handleClose(popup));

    this.popup = popup;
    this.main_popup = main_popup;

    logMessage(LOG_LEVELS.INFO, 'Reddit card popup shown');
  }

  handleClose(popup) {
    popup.remove();
  }

  async handleSave(popup, headers) {
    popup.resetErrors();
    if (popup.isValidInput()) {
      const newHeaders = { ...headers, 'Content-Type': 'application/json' };

      const data = this.popup.getFormData();
      const requestData = {
        user_card_id: this.card_info.user_card.user_card_id,
        ...data,
      };

      try {
        const response = await fetchCardData(
          `${config.detectionUrl}/verify-reddit-post`,
          'POST',
          newHeaders,
          requestData
        );

        const responseData = response.body;

        if (!response.ok || responseData.error) {
          const errorMessage =
            responseData.error || 'Failed to verify the post.';

          // Log the error
          logMessage(
            LOG_LEVELS.ERROR,
            `Error during verification: ${errorMessage}`
          );
          alert(`Error during verification: ${errorMessage}`);
          return;
        }

        // Handle success response
        if (responseData.success) {
          this.displayRedditPostAndConfirm(responseData.post_details).then(
            async (confirmed) => {
              if (confirmed) {
                const postRequestData = {
                  user_card_id: this.card_info.user_card.user_card_id,
                  photo_urls: responseData.post_details.photo_urls,
                  ...data,
                };

                await this.confirmPostOnBackend(postRequestData, newHeaders);
                // Add your Reddit posting logic here
              } else {
                // User canceled
                console.log('User canceled the post.');
              }
            }
          );
        } else {
          // Unexpected response
          throw new Error('Unexpected response from the server.');
        }
      } catch (error) {
        logMessage(
          LOG_LEVELS.ERROR,
          'Error during post verification: ' + error.message
        );

        // Display a generic error message to the user
        // this.displayError('An unexpected error occurred during post verification.');
      }

      logMessage(LOG_LEVELS.INFO, 'Reddit post popup accept button clicked.');
    }
  }

  async displayRedditPostAndConfirm(postDetails) {
    // Reference to modal elements
    const modal = document.getElementById('redditConfirmationModal');
    const cancelButton = document.getElementById('redditCancelButton');
    const confirmButton = document.getElementById('redditConfirmButton'); // Corrected ID
    const postDetailsDiv = document.getElementById('postDetails');

    // Populate post details
    postDetailsDiv.innerHTML = ''; // Clear previous content

    const title = postDetails.title || 'No Title Provided';
    const subreddit = postDetails.subreddit || 'No Subreddit Provided';
    const comment = postDetails.comment;
    const images = postDetails.photo_urls || [];

    let postHtml = `
            <div class="post-grid">
                <div class="grid-label">Subreddit:</div>
                <div class="grid-value">${subreddit}</div>
                <div class="grid-label">Title:</div>
                <div class="grid-value">${title}</div>
        `;

    if (comment) {
      postHtml += `
             <div class="grid-label">Comment:</div>
             <div class="grid-value">${comment}</div>
            `;
    }

    postHtml += `</div>`;

    if (images.length > 0) {
      postHtml += `
                <div class="post-section images-section">
                    <h3>Images</h3>
                    <div class="post-image-container">
            `;
      images.forEach((imageUrl) => {
        postHtml += `<img src="${imageUrl}" alt="Post Image" class="post-image">`;
      });
      postHtml += `
                    </div>
                </div>
            `;
    } else {
      postHtml += `
                <div class="post-section">
                    <p><strong>Images:</strong> No images attached</p>
                </div>
            `;
    }

    if (comment) {
      postHtml += `<p style="font-style: italic; color: gray;">Note: The comment is posted under YOUR username</p>`;
    }

    postDetailsDiv.innerHTML = postHtml;

    // Show the modal
    modal.style.display = 'flex';
    modal.style.zIndex = 999999999;

    // Create a Promise that resolves when the user makes a choice
    return new Promise((resolve) => {
      // Cancel button handler
      cancelButton.onclick = () => {
        modal.style.display = 'none';
        resolve(false);
      };

      // Confirm button handler
      confirmButton.onclick = () => {
        modal.style.display = 'none';
        resolve(true);
      };

      // Click outside the modal to close
      window.onclick = (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
          resolve(false);
        }
      };
    });
  }

  async confirmPostOnBackend(requestData, headers) {
    try {
      const response = await fetchCardData(
        `${config.detectionUrl}/reddit-post`,
        'POST',
        headers,
        requestData
      );

      const responseData = response.body;

      if (!response.ok || responseData.error) {
        const errorMessage = responseData.error || 'Failed to post the item.';

        // Log the error
        logMessage(
          LOG_LEVELS.ERROR,
          `Error during item listing: ${errorMessage}`
        );

        // Display the error to the user
        alert(errorMessage);
        return;
      }

      // Handle success response
      else if (responseData.success) {
        logMessage(LOG_LEVELS.INFO, 'Item successfully posted.');
        alert('Card Successfully posted.');

        this.popup.remove();
      } else {
        // Unexpected response
        throw new Error('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error during item listing:', error);
      logMessage(
        LOG_LEVELS.ERROR,
        'Error during item listing: ' + error.message
      );

      // Display a generic error message to the user
      this.displayError('An unexpected error occurred during item listing.');
    }
  }
}

export { RedditFormPopupController };
