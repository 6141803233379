import { LOG_LEVELS, logMessage } from '../logging.js';

class PopupLabel {
  constructor(text, style, icon = null) {
    this.label = this.createLabel(text, style, icon);
  }

  // Method to create an element with specified attributes
  createElementWithAttributes(tagName, attributes) {
    logMessage(LOG_LEVELS.DEBUG, 'Creating element', { tagName, attributes });
    const element = document.createElement(tagName);
    for (let key in attributes) {
      element[key] = attributes[key];
    }
    return element;
  }

  // Create a button with specified attributes
  createLabel(text, className, icon) {
    // Create the label element
    const label = this.createElementWithAttributes('label', {
      className: className,
    });

    if (icon) {
      // Create the Font Awesome checkmark icon
      const checkmarkIcon = this.createElementWithAttributes('i', {
        className: icon,
      });

      label.appendChild(checkmarkIcon);
    }

    label.appendChild(document.createTextNode(` ${text}`)); // Space between icon and text

    return label;
  }

  // Method to get the label instance
  instance() {
    return this.label;
  }
}

export { PopupLabel };
