import { sendAuthenticatedRequest } from '../auth.js';
import { LOG_LEVELS, logMessage } from '../logging.js';

class ImageCarouselModel {
  constructor(is_public) {
    // this.headers = headers;
    this.images = [];
    this.currentIndex = 0;
    this.is_public = is_public;
  }

  setHeaders(headers) {
    this.headers = headers;
  }

  addImage(imageUrl) {
    this.images.push({ url: imageUrl, localUrl: null });
  }

  async fetchImage(index) {
    if (index < 0 || index >= this.images.length) {
      throw new Error('Invalid index for image');
    }

    const imageUrl = this.images[index].url;

    // Fetch the image securely using the provided headers
    try {
      const response = await sendAuthenticatedRequest(imageUrl, {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob', // Expect a blob response
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Create a local URL for the fetched blob and cache it
      const localUrl = URL.createObjectURL(response.blob);
      this.images[index].localUrl = localUrl;
      return localUrl;
    } catch (error) {
      logMessage(LOG_LEVELS.ERROR, 'Error fetching image:', error.toString());
      throw error; // Re-throw to let the controller handle it
    }
  }

  async fetchPublicImage(index) {
    if (index < 0 || index >= this.images.length) {
      throw new Error('Invalid index for image');
    }

    const imageUrl = this.images[index].url;

    logMessage(LOG_LEVELS.DEBUG, 'Fetching and showing image', { imageUrl });

    try {
      const response = await fetch(imageUrl, { method: 'GET' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob(); // Wait for the blob conversion
      const localUrl = URL.createObjectURL(blob);
      this.images[index].localUrl = localUrl; // Update the local URL
      return localUrl;
    } catch (error) {
      logMessage(
        LOG_LEVELS.ERROR,
        'Error fetching the image:',
        error.toString()
      );
      throw error; // Re-throw the error if necessary
    }
  }

  async updateLocalImageAtIndex(index, newUrl) {
    this.images[index].localUrl = newUrl;
  }

  async getImage(index) {
    if (!this.images[index]?.localUrl) {
      if (this.is_public) {
        await this.fetchPublicImage(index);
      } else {
        await this.fetchImage(index);
      }
    }
    return this.images[index].localUrl;
  }

  async getNextImage() {
    // Check if the current index is the last image
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
    }
    return await this.getImage(this.currentIndex);
  }

  async getPrevImage() {
    // Check if the current index is the first image
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
    return await this.getImage(this.currentIndex);
  }

  setCurrentIndex(index) {
    if (index >= 0 && index < this.images.length) {
      this.currentIndex = index;
    }
  }

  async getCurrentImage() {
    return await this.getImage(this.currentIndex);
  }

  getCurrentIndex() {
    return this.currentIndex;
  }

  isIndexAtStart() {
    return this.currentIndex == 0;
  }

  isIndexAtEnd() {
    return this.currentIndex == this.images.length - 1;
  }

  numImages() {
    return this.images.length;
  }
}

export { ImageCarouselModel };
