import { logMessage, LOG_LEVELS } from '../logging';
import { sendAuthenticatedRequest } from '../auth';
import config from '../../config';
import stateManager from '../data/state_manager.js';

async function getUser(headers) {
  // Call sendAuthenticatedRequest and expect a structured result
  const result = await sendAuthenticatedRequest(
    `${config.detectionUrl}/get-user`,
    {
      method: 'GET',
      headers: headers,
    }
  );

  // The result object now directly contains the 'ok' status and parsed 'body'
  if (!result.ok) {
    // Use the result.body directly for the error details
    const error = `HTTP error! status: ${result.status}, message: ${result.body.error}`;
    logMessage(LOG_LEVELS.ERROR, error);
    throw new Error(error);
  }

  // The data is already parsed and available in result.body
  const data = result.body;
  logMessage(LOG_LEVELS.INFO, 'User retrieved successfully');

  return data;
}

async function updateCollectionPrivacy(headers, collectionId, isPublic) {
  const new_headers = { ...headers, 'Content-Type': 'application/json' };

  // Call sendAuthenticatedRequest and expect a structured result
  const result = await sendAuthenticatedRequest(
    `${config.detectionUrl}/collections/${collectionId}/public`,
    {
      method: 'PATCH',
      headers: new_headers,
      body: JSON.stringify({ is_public: isPublic }),
    }
  );

  // The result object now directly contains the 'ok' status and parsed 'body'
  if (!result.ok) {
    // Use the result.body directly for the error details
    const error = `HTTP error! status: ${result.status}, message: ${result.body.error}`;
    logMessage(LOG_LEVELS.ERROR, error);
    throw new Error(error);
  }

  // The data is already parsed and available in result.body
  const data = result.body;
  logMessage(LOG_LEVELS.INFO, 'User retrieved successfully');

  return data;
}

// Function to update the collection name via API
async function updateCollectionName(headers, collectionId, newName) {
  const new_headers = { ...headers, 'Content-Type': 'application/json' };

  const result = await sendAuthenticatedRequest(
    `${config.detectionUrl}/collections/${collectionId}/name`,
    {
      method: 'PATCH', // Using PUT for full update; consider PATCH for partial
      headers: new_headers,
      body: JSON.stringify({ name: newName }),
    }
  );
  // The result object now directly contains the 'ok' status and parsed 'body'
  if (!result.ok) {
    // Use the result.body directly for the error details
    const error = `HTTP error! status: ${result.status}, message: ${result.body.error}`;
    logMessage(LOG_LEVELS.ERROR, error);
    throw new Error(error);
  }

  // The data is already parsed and available in result.body
  const data = result.body;
  logMessage(LOG_LEVELS.INFO, 'User retrieved successfully');

  return data;
}

async function updateOnboardingStatus(headers) {
  const new_headers = { ...headers, 'Content-Type': 'application/json' };

  // Call sendAuthenticatedRequest and expect a structured result
  const result = await sendAuthenticatedRequest(
    `${config.detectionUrl}/update-onboarding-status`,
    {
      method: 'POST',
      headers: new_headers,
      body: JSON.stringify({ has_seen_onboarding: true }),
    }
  );

  // The result object now directly contains the 'ok' status and parsed 'body'
  if (!result.ok) {
    // Use the result.body directly for the error details
    const error = `HTTP error! status: ${result.status}, message: ${result.body.error}`;
    logMessage(LOG_LEVELS.ERROR, error);
    throw new Error(error);
  }

  // The data is already parsed and available in result.body
  const data = result.body;
  logMessage(LOG_LEVELS.INFO, 'User retrieved successfully');

  return data;
}

async function addCard(headers, payload) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for addCard', { payload });

    const response = await fetchCardData(
      `${config.detectionUrl}/add-card`,
      'POST',
      new_headers,
      payload
    );
    const result = await handleConflict(response, new_headers, payload);
    logMessage(LOG_LEVELS.DEBUG, 'Processed conflict');

    const finalDecision = await processUserDecision(result);
    logMessage(
      LOG_LEVELS.INFO,
      finalDecision
        ? 'User accepted the decision.'
        : 'User rejected the decision.'
    );

    return finalDecision;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error in addCard function:',
      error.toString()
    );
    alert('An error occurred adding a card! Please try again later!');
    stateManager.updateState({ stopProcessing: true });
    return false; // Return false to indicate failure
  }
}

async function checkChecklist(headers, payload) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for checkChecklist', {
      payload,
    });

    const response = await fetchCardData(
      `${config.detectionUrl}/check-checklist`,
      'POST',
      new_headers,
      payload
    );

    if (!response.ok) {
      throw new Error(`checkChecklist failed with status: ${response.status}`);
    }

    return response.body;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error in checkChecklist function:',
      error.toString()
    );
    alert('An error occurred with refreshing! Please try again later!');
    return false; // Return false to indicate failure
  }
}

async function processReplaceCard(headers, payload) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for process-replace', {
      payload,
    });

    const response = await fetchCardData(
      `${config.detectionUrl}/process-replace`,
      'POST',
      new_headers,
      payload
    );

    if (!response.ok) {
      throw new Error(`Replace image failed with status: ${response.status}`);
    }

    return response.body;
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error:', error.toString());
    alert(error.toString());
    stateManager.updateState({ stopProcessing: true });
    return null; // Return false to indicate failure
  }
}

async function updateCardCoordinates(headers, payload) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for update-coordinates', {
      payload,
    });

    const response = await fetchCardData(
      `${config.detectionUrl}/update-coordinates`,
      'POST',
      new_headers,
      payload
    );

    if (!response.ok) {
      throw new Error(
        `Update coordinations failed with status: ${response.status}`
      );
    }

    return response.body;
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error:', error.toString());
    alert(error.toString());
    stateManager.updateState({ stopProcessing: true });

    return false; // Return false to indicate failure
  }
}

async function sendErrorAnalytics(headers, payload) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for sendErrorAnalytics', {
      payload,
    });

    const response = await sendAuthenticatedRequest(
      `${config.detectionUrl}/send-card-error`,
      {
        method: 'POST',
        headers: new_headers,
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error(`Delete failed with status: ${response.status}`);
    }

    return true;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error in addCard function:',
      error.toString()
    );
    alert('An error occurred with sending analytics! Please try again later!');
    stateManager.updateState({ stopProcessing: true });
    return false; // Return false to indicate failure
  }
}

async function handleConflict(response, headers, payload) {
  if (response.status === 409) {
    const userDecision = confirm(
      "You've already added this card to your collection. Do you want to add 1 to the number you own?"
    );
    if (userDecision) {
      try {
        const { currentCardFront, currentCardBack } = stateManager.getState();
        deleteFrontAndBackImages(currentCardFront, currentCardBack, headers);
        logMessage(LOG_LEVELS.DEBUG, 'Image deleted successfully');
      } catch (error) {
        logMessage(
          LOG_LEVELS.ERROR,
          'Failed to delete image:',
          error.toString()
        );
      }
      // Proceed with adding the card, regardless of the image deletion result
      payload.user_decision = true;
      const res = await fetchCardData(
        `${config.detectionUrl}/add-card`,
        'POST',
        headers,
        payload
      );
      return { response: res, userDecision: true };
    } else {
      return { response, userDecision: false };
    }
  }
  return { response, userDecision: true };
}

function handleResponse(result) {
  if (!result.ok) {
    throw new Error(`HTTP error! Status: ${result.status}`);
  }
  // Since result.body already contains the parsed JSON, return it directly
  return result.body;
}

async function editCard(data, headers) {
  try {
    // Enhanced logging with more context
    logMessage(LOG_LEVELS.DEBUG, 'Preparing payload for editCard', { data });

    const payload = { card_data: data };
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(LOG_LEVELS.DEBUG, 'Sending POST request to edit card');

    // Use the result object from sendAuthenticatedRequest
    const result = await sendAuthenticatedRequest(
      `${config.detectionUrl}/edit-card`,
      {
        method: 'POST',
        headers: new_headers,
        body: JSON.stringify(payload),
      }
    );

    // Assuming handleEditConflict and processUserDecision are updated to work with the new result format
    const conflictResult = await handleEditConflict(
      result,
      new_headers,
      payload
    );
    const finalDecision = await processUserDecision(conflictResult);

    logMessage(
      LOG_LEVELS.INFO,
      finalDecision
        ? 'User accepted the decision.'
        : 'User rejected the decision.'
    );

    return finalDecision;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error in editCard function:',
      error.toString()
    );
    alert('An error occurred! Please try again later!');
    return false; // Return false to indicate failure
  }
}

async function handleEditConflict(response, headers, payload) {
  if (response.status === 409) {
    const userDecision = confirm(
      'We cannot find any matches in our system for this card. Are you sure you want to make these changes?'
    );
    if (userDecision) {
      payload.user_decision = true;
      const res = await fetchCardData(
        `${config.detectionUrl}/edit-card`,
        'POST',
        headers,
        payload
      );
      return { response: res, userDecision: true };
    } else {
      return { response, userDecision: false };
    }
  }
  return { response, userDecision: true };
}

async function processUserDecision(result) {
  if (result.userDecision) {
    await handleResponse(result.response);
  }
  return result.userDecision;
}

async function deleteCard(card_info, headers) {
  const isConfirmed = window.confirm(
    'Are you sure you want to delete this card from your collection?'
  );

  if (!isConfirmed) {
    logMessage(LOG_LEVELS.INFO, 'User cancelled the delete operation.');
    return false;
  }

  const payload = { user_card_id: card_info.user_card.user_card_id };
  const new_headers = { ...headers, 'Content-Type': 'application/json' };

  try {
    logMessage(LOG_LEVELS.DEBUG, 'Attempting to delete card', {
      cardId: card_info.user_card.user_card_id,
    });

    const response = await sendAuthenticatedRequest(
      `${config.detectionUrl}/delete-card`,
      {
        method: 'DELETE',
        headers: new_headers,
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error(`Delete failed with status: ${response.status}`);
    }

    deleteImageGivenCard(card_info, headers); // Assuming deleteImageGivenCard is asynchronous
    logMessage(LOG_LEVELS.INFO, 'Delete successful!');
    return true;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error during card deletion:',
      error.toString()
    );
    alert('An error occurred. Unable to delete card!');
    return false;
  }
}

async function deleteImageGivenCard(card_info, headers) {
  // Extract image IDs using extractIdFromUrl if needed
  const front_large_image_id = card_info.user_card.front_image_url_large
    ? extractIdFromUrl(card_info.user_card.front_image_url_large)
    : null;
  const back_large_image_id = card_info.user_card.back_image_url_large
    ? extractIdFromUrl(card_info.user_card.back_image_url_large)
    : null;
  const front_medium_image_id = card_info.user_card.front_image_url_medium
    ? extractIdFromUrl(card_info.user_card.front_image_url_medium)
    : null;
  const back_medium_image_id = card_info.user_card.back_image_url_medium
    ? extractIdFromUrl(card_info.user_card.back_image_url_medium)
    : null;

  // Utilize the deleteImage function for both front and back images, large and medium
  await deleteImage(front_large_image_id, front_medium_image_id, headers);
  await deleteImage(back_large_image_id, back_medium_image_id, headers);
}

function extractIdFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const id = pathname.split('/').pop().split('.')[0];
    return id;
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Failed to extract ID from URL',
      error.toString()
    );
    return null; // Return null if URL parsing fails
  }
}

async function fetchCardData(url, method, headers, payload) {
  try {
    const options = {
      method,
      headers,
    };

    // Only include body for non-GET requests
    if (method !== 'GET' && payload) {
      options.body = JSON.stringify(payload);
    }

    const response = await sendAuthenticatedRequest(url, options);
    logMessage(LOG_LEVELS.DEBUG, 'Response Status:', response.status);

    return response; // Return the fetch response for further processing
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Fetch card data failed', error.toString());
    throw error; // Rethrow to handle the error in the calling context
  }
}

async function deleteFrontAndBackImages(cardFront, cardBack, headers) {
  try {
    await deleteImage(
      cardFront.large_image_id,
      cardFront.medium_image_id,
      headers
    );
    await deleteImage(
      cardBack.large_image_id,
      cardBack.medium_image_id,
      headers
    );
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Failed to delete front and back images',
      error.toString()
    );
  }
}

async function deleteImage(large_image_id, medium_image_id, headers) {
  const payload = {
    large_image_id,
    medium_image_id,
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value != null)
  );

  if (Object.keys(filteredPayload).length > 0) {
    await deleteImageHelper(filteredPayload, headers);
  } else {
    logMessage(LOG_LEVELS.INFO, 'No image IDs provided for deletion.');
  }
}

async function deleteImageHelper(payload, headers) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };
    const response = await sendAuthenticatedRequest(
      `${config.detectionUrl}/delete-image`,
      {
        method: 'DELETE',
        headers: new_headers,
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      const responseBody = await response.text();
      throw new Error(
        `Delete failed with status: ${response.status}, Body: ${responseBody}`
      );
    }

    logMessage(LOG_LEVELS.INFO, 'Delete successful!');
  } catch (error) {
    logMessage(
      LOG_LEVELS.ERROR,
      'Error during image deletion:',
      error.toString()
    );
    throw error; // Rethrow to allow for external handling
  }
}

async function getSubscriptionLevel(headers) {
  // Call sendAuthenticatedRequest and expect a structured result
  const result = await sendAuthenticatedRequest(
    `${config.detectionUrl}/get-subscription-level`,
    {
      method: 'GET',
      headers,
    }
  );

  // The result object now directly contains the 'ok' status and parsed 'body'
  if (!result.ok) {
    // Use the result.body directly for the error details
    const error = `HTTP error! status: ${result.status}, message: ${result.body.error}`;
    logMessage(LOG_LEVELS.ERROR, error);
    throw new Error(error);
  }

  // The data is already parsed and available in result.body
  const data = result.body;
  logMessage(LOG_LEVELS.INFO, 'Subscription level found.', data);

  return data;
}

async function initiateCornerDownload(
  headers,
  payload,
  no_subscription_handler,
  confirm = false
) {
  try {
    const new_headers = { ...headers, 'Content-Type': 'application/json' };

    logMessage(
      LOG_LEVELS.DEBUG,
      'Preparing payload for initiate-corner-download',
      { payload }
    );

    let response;

    if (confirm) {
      response = await fetchCardData(
        `${config.detectionUrl}/initiate-free-corner-download`,
        'POST',
        new_headers,
        payload
      );
    } else {
      response = await fetchCardData(
        `${config.detectionUrl}/initiate-corner-download`,
        'POST',
        new_headers,
        payload
      );
    }

    if (!response.ok) {
      if (response.status === 403 && response.body.error_code == -2) {
        no_subscription_handler();
      } else if (response.status != 409) {
        throw new Error(
          `Initiate corner download failed with status: ${response.status}`
        );
      }
    }

    return response;
  } catch (error) {
    logMessage(LOG_LEVELS.ERROR, 'Error:', error.toString());
    alert(error.toString());
    stateManager.updateState({ stopProcessing: true });

    return false; // Return false to indicate failure
  }
}

export {
  getUser,
  updateCollectionPrivacy,
  updateCollectionName,
  updateOnboardingStatus,
  editCard,
  checkChecklist,
  deleteCard,
  addCard,
  deleteFrontAndBackImages,
  sendErrorAnalytics,
  extractIdFromUrl,
  processReplaceCard,
  updateCardCoordinates,
  getSubscriptionLevel,
  initiateCornerDownload,
  fetchCardData,
};
